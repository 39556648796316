import { Grid2 as Grid, Stack, Typography } from "@mui/material";

import { detailRoute } from "@/routes/detail/$id";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { Group } from "@/types/Layout";
import { CustomField } from "./CustomFields";
import { currentIntl } from "@/contexts/IntlContext";

const ContractOtherDetails_ContractFragment = graphql(`
    fragment ContractOtherDetails_ContractFragment on Contract {
        customFields {
            ...CustomField_CustomFieldFragment
            ... on BaseContractCustomField {
                id
                name
            }
        }
    }
`);

interface ContractOtherDetailsProps {
    contract: FragmentType<typeof ContractOtherDetails_ContractFragment>;
    editMode: boolean;
}

function fallbackLayoutForContract(contract: FragmentType<typeof ContractOtherDetails_ContractFragment>) {
    const frag = getFragmentData(ContractOtherDetails_ContractFragment, contract);
    return {
        id: "tmp",
        groups: [
            {
                name: currentIntl().formatMessage({ defaultMessage: "Other details" }),
                items: (frag.customFields ?? []).map((field, index) => ({
                    refId: field.id,
                    name: field.name,
                    visible: true,
                    order: index,
                })),
                visible: true,
                order: 1,
            },
        ],
    };
}

interface LayoutGroupProps {
    group: Group;
    contract: FragmentType<typeof ContractOtherDetails_ContractFragment>;
    editMode: boolean;
}

// Renders a single group of custom fields. Expects the full contract, and will filter out
// custom fields that are not visible within the group
const LayoutGroup: React.FC<LayoutGroupProps> = (props) => {
    const customFields = getFragmentData(ContractOtherDetails_ContractFragment, props.contract).customFields ?? [];
    const matchingFields = customFields
        .filter((cf) => props.group.items.some((item) => item.refId === cf.id && item.visible))
        .sort((a, b) => {
            // sort by group.order
            const aOrder = props.group.items.find((item) => item.refId === a.id)?.order ?? 0;
            const bOrder = props.group.items.find((item) => item.refId === b.id)?.order ?? 0;
            return aOrder - bOrder;
        });

    return (
        <Stack key={props.group.id} gap={2}>
            <Typography variant="textLg" fontWeight="500">
                {props.group.name}
            </Typography>
            <Grid container rowGap={3} columnSpacing={3} alignItems="stretch">
                {matchingFields.map((cf) => (
                    <CustomField key={cf.id} customField={cf} edit={props.editMode} />
                ))}
            </Grid>
        </Stack>
    );
};

export const ContractOtherDetails: React.FC<ContractOtherDetailsProps> = (props) => {
    let { layout } = detailRoute.useLoaderData();
    if (!layout) {
        layout = fallbackLayoutForContract(props.contract);
    }

    const visibleGroups = layout.groups.filter((g) => g.visible);

    return visibleGroups.map((group) => <LayoutGroup key={group.id} group={group} {...props} />);
};
