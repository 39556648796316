import { FormattedMessage } from "react-intl";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { RenderActivity } from "./RenderActivity";

const TitleActivity_TitleContractActivity = graphql(`
    fragment TitleActivity_TitleContractActivity on TitleContractActivity {
        title
        prevTitle
    }
`);

interface TitleActivityProps {
    activity: FragmentType<typeof TitleActivity_TitleContractActivity>;
}

export const TitleActivity: React.FC<TitleActivityProps> = (props) => {
    const activity = getFragmentData(TitleActivity_TitleContractActivity, props.activity);
    return (
        <RenderActivity
            title={<FormattedMessage defaultMessage="Title" />}
            prev={activity.prevTitle}
            value={activity.title}
        />
    );
};
