import { Box, Button, Drawer, IconButton, Stack, styled, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";

import { ChevronLeft, ChevronRight, FileArrowDown, X } from "@ignite-analytics/icons";

import { FormattedMessage } from "react-intl";
import { Document } from "../../pages/detail/components/ContractSidebar/DocumentsCard/components/ContractDocuments/hooks";
import { DocumentViewer } from "./DocumentViewer";

interface DocumentViewerSideDrawerProps {
    onClose: () => void;
    doc: Document | undefined | null;
}

const DrawerWidth = 1200;

const StyledDrawer = styled(Drawer)({
    width: DrawerWidth,
    flexShrink: 0,
});

const DocumentViewerSideDrawer: React.FC<DocumentViewerSideDrawerProps> = ({ onClose, doc }) => {
    const open = !!doc;
    const [width, setWidth] = useState<string | number>(DrawerWidth);
    if (!doc) {
        return null;
    }

    const handleDownloadFileFromUrl = (url: string | null | undefined) => {
        if (!url) {
            return;
        }
        const a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.rel = "noopener noreferrer";
        a.click();
    };

    return (
        <StyledDrawer
            anchor="right"
            open={open}
            onClose={onClose}
            variant="temporary"
            sx={{
                "& .MuiDrawer-paper": {
                    width,
                },
            }}
        >
            <Stack sx={{ height: "100%", p: 2 }} spacing={2}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Tooltip
                            title={
                                width === DrawerWidth ? (
                                    <FormattedMessage defaultMessage="Expand to full width" />
                                ) : (
                                    <FormattedMessage defaultMessage="Collapse" />
                                )
                            }
                        >
                            <IconButton onClick={() => setWidth(width === DrawerWidth ? "100vw" : DrawerWidth)}>
                                {width === DrawerWidth ? <ChevronLeft /> : <ChevronRight />}
                            </IconButton>
                        </Tooltip>
                        <Typography variant="textMd" fontWeight={500} noWrap>
                            {doc.filename}
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <Button
                            onClick={() => handleDownloadFileFromUrl(doc.url)}
                            disabled={!doc.url}
                            size="large"
                            color="secondary"
                            startIcon={<FileArrowDown />}
                        >
                            <FormattedMessage defaultMessage="Download file" />
                        </Button>
                        <IconButton onClick={onClose} size="large">
                            <X />
                        </IconButton>
                    </Stack>
                </Box>

                <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
                    <DocumentViewer document={doc} />
                </Box>
            </Stack>
        </StyledDrawer>
    );
};

export default DocumentViewerSideDrawer;
