import { Divider, Stack } from "@mui/material";
import { GridToolbarProps } from "@mui/x-data-grid-pro";
import { SearchField } from "@/pages/list/components/SearchField";

import { Filters } from "@/components/TableFilter/Filters";
import { TableFilter, TableQuickFilter } from "@/components/TableFilter/types";
import { GridToolbarTotalItemsContainer } from "@/components/ContractsTablePro/components/Toolbar/GridToolbarTotalItemsContainer";

export interface TableToolbarProps extends GridToolbarProps {
    searchTerm?: string;
    onSearch: (value: string) => void;
    visibleRowCount: number;
    totalNumberOfRows: number;
    filters: TableFilter[];
    appliedFilters: TableFilter[];
    onApplyFilters: (filters: TableFilter[]) => void;
    quickFilters: TableQuickFilter[];
}

export const TableToolbar: React.FC<TableToolbarProps> = ({
    visibleRowCount,
    totalNumberOfRows,
    searchTerm,
    onSearch,
    filters,
    appliedFilters,
    onApplyFilters,
    quickFilters,
}) => {
    return (
        <Stack direction="row" spacing={1} alignItems="center" p={2} flexWrap="wrap" rowGap={1}>
            <Stack direction="row" spacing={2} alignItems="center" pr={1}>
                <GridToolbarTotalItemsContainer visibleRowCount={visibleRowCount} rowCount={totalNumberOfRows} />
                <Divider orientation="vertical" flexItem />
            </Stack>
            <SearchField initialValue={searchTerm} onSearch={onSearch} />
            <Filters
                filters={filters}
                quickFilters={quickFilters}
                appliedFilters={appliedFilters}
                onApplyFilters={onApplyFilters}
            />
        </Stack>
    );
};
