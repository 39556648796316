import { getLocale } from "@ignite-analytics/locale";
import { LicenseInfo } from "@mui/x-license-pro";
import { RouterProvider } from "@tanstack/react-router";
import React, { useEffect } from "react";

import FatalErrorFallback from "./components/FatalErrorFallback";
import { ContextProviders } from "./contexts";
import { useUser } from "./contexts/useUser";
import { UserProvider } from "./contexts/useUser/provider";
import { env } from "./env";
import { getRouter } from "./routes/routes";

LicenseInfo.setLicenseKey(env.REACT_APP_MUI_TOKEN);

const totalOverviewPath = "/spend/total-overview/contracts";

interface AppProps {
    locale?: string;
    theme?: "ignite-riddle" | "bigbang" | "ignite-ui";
    router: ReturnType<typeof getRouter>;
}
const App: React.FC<AppProps> = ({ locale = getLocale(), theme = "ignite-riddle", router }) => {
    const basepath = window.location.pathname.startsWith(totalOverviewPath) ? totalOverviewPath : "contracts";

    React.useEffect(() => {
        if (window.location.pathname.startsWith(totalOverviewPath)) {
            return;
        }

        const data = { source: "ignite-breadcrumbs", payload: [{ text: "Contracts" }] };
        window.postMessage(data, window.location.origin);
    }, []);
    useEffect(() => {
        const onRouteChange = (event: MessageEvent) => {
            if (
                event.data.type === "route-change" &&
                ["breadcrumbs", "globalSearchBar", "sidebar"].includes(event.data.context)
            ) {
                router.navigate({ to: event.data.path, from: event.data.from });
            }
        };
        window.addEventListener("message", onRouteChange);
        return () => {
            window.removeEventListener("message", onRouteChange);
        };
    }, [router]);

    const user = useUser();
    if (!user) {
        return null;
    }

    return (
        <ContextProviders locale={locale} theme={theme}>
            <RouterProvider
                context={{ user }}
                router={router}
                basepath={basepath}
                defaultErrorComponent={FatalErrorFallback}
            />
        </ContextProviders>
    );
};

const AppWrapper = (props: AppProps) => {
    return (
        <UserProvider>
            <App {...props} />
        </UserProvider>
    );
};

export default AppWrapper;
