import { Divider, Grid2 as Grid, Paper, Stack } from "@mui/material";

import { useFeatureToggle } from "@ignite-analytics/feature-toggle";

import { track } from "@ignite-analytics/track";
import dayjs from "dayjs";
import { useEffect } from "react";

import { ContractBasicDetails } from "./components/ContractBasicDetails";
import { ContractOtherDetails } from "./components/ContractOtherDetails";

import { ContractSidebar } from "./components/ContractSidebar";
import { SpendDevelopmentCard } from "./components/SpendDevelopmentCard";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { DocumentSection } from "./components/DocumentSection";

const ContractDetailPage_ContractFragment = graphql(`
    fragment ContractDetailPage_ContractFragment on Contract {
        ...ContractBasicDetails_ContractFragment
        ...ContractOtherDetails_ContractFragment
        ...ContractSidebar_ContractFragment
        ...DocumentSection_ContractFragment
        endDate
    }
`);

interface ContractDetailPageProps {
    contract: FragmentType<typeof ContractDetailPage_ContractFragment>;
    editMode: boolean;
    canEdit: boolean;
    refetch: () => void;
}

export const ContractDetailPage = (props: ContractDetailPageProps) => {
    const spendDevelopmentFeatureToggle = useFeatureToggle("contracts-spend-development");
    const documentTagFeatureToggle = useFeatureToggle("contracts-document-tags");

    const contract = getFragmentData(ContractDetailPage_ContractFragment, props.contract);

    useEffect(
        function trackContractDetailView() {
            const expiresInMonths = contract.endDate ? dayjs(contract.endDate).diff(dayjs(), "month") : undefined;
            track("Contract Details: Contract page viewed", {
                endDate: contract.endDate,
                isViewer: !props.canEdit,
                expiresInMonths,
            });
        },
        [contract.endDate, props.canEdit]
    );

    return (
        <Stack px={3} gap={3} alignItems="stretch" justifyContent="center" mx="auto" width="100%">
            <Grid container direction="row" spacing={3}>
                <Grid size={8}>
                    <Stack spacing={2}>
                        <Stack gap={3} p={2.5} component={Paper} elevation={1}>
                            <ContractBasicDetails editMode={props.editMode} contract={contract} />

                            <Divider />

                            <ContractOtherDetails contract={contract} editMode={props.editMode} />
                        </Stack>

                        {/* Only show this here if document preview is on */}
                        {documentTagFeatureToggle && <DocumentSection canEdit={props.canEdit} contract={contract} />}
                        {spendDevelopmentFeatureToggle && <SpendDevelopmentCard />}
                    </Stack>
                </Grid>
                <Grid size={4}>
                    <ContractSidebar
                        onContractChange={() => {
                            props.refetch();
                        }}
                        contract={contract}
                        canEdit={props.canEdit}
                        editMode={props.editMode}
                    />
                </Grid>
            </Grid>
        </Stack>
    );
};
