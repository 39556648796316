import { getLocale } from "@ignite-analytics/locale";
import { DateInterval } from "@/generated/graphql";
import { SpendDevelopmentPayload } from "./SpendDevelopmentChart";

const getMonthWithOptionalYear = (monthString: string, yearString?: string, variant: "short" | "long" = "short") => {
    const language = getLocale();
    const date = new Date(`${monthString} 01 ${yearString ?? "2000"}`);
    return date.toLocaleDateString(language, {
        month: variant,
        year: yearString ? "numeric" : undefined,
    });
};

export const getShortLabel = (label: string, interval: DateInterval): string => {
    const date = new Date(label);
    switch (interval) {
        case "MONTH":
            return getMonthWithOptionalYear(label, undefined, "short");
        case "YEAR":
            return date.getFullYear().toString();
        case "QUARTER":
            return label;
        default:
            return getMonthWithOptionalYear(label, undefined, "short");
    }
};

export function getFullLabel(payload: SpendDevelopmentPayload, interval: DateInterval): string {
    const date = new Date(payload.label);
    switch (interval) {
        case "MONTH":
            return getMonthWithOptionalYear(payload.label, payload.year, "long");
        case "YEAR":
            return date.getFullYear().toString();
        case "QUARTER":
            return payload.label;
        default:
            return getMonthWithOptionalYear(payload.label, payload.year, "long");
    }
}
