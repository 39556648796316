import { useMutation } from "@apollo/client";
import { Check, DotsVertical, Pen, Trash } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useUserOrThrow } from "@/contexts/useUser";
import { useErrorHandler } from "@/errorHandling/ErrorHandlerContext";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { detailRoute } from "@/routes/detail/$id";
import { ActivityLog_ContractActivities } from "../..";
import { RiddleMenu } from "@/components/RiddleMenu";

const CommentActivity_CommentContractActivity = graphql(`
    fragment CommentActivity_CommentContractActivity on CommentContractActivity {
        id
        comment
        meta {
            createdBy {
                ... on RoleUser {
                    id
                }
            }
        }
    }
`);

export const DeleteCommentMutation = graphql(`
    mutation DeleteComment($input: DeleteCommentRequest!) {
        deleteComment(input: $input) {
            id
        }
    }
`);

export const UpdateCommentMutation = graphql(`
    mutation UpdateComment($input: UpdateCommentRequest!) {
        updateComment(input: $input) {
            id
        }
    }
`);

interface CommentActivityProps {
    activity: FragmentType<typeof CommentActivity_CommentContractActivity>;
}

export const CommentActivity: React.FC<CommentActivityProps> = (props) => {
    const { userId } = useUserOrThrow();
    const { id: contractId } = detailRoute.useParams();

    const activity = getFragmentData(CommentActivity_CommentContractActivity, props.activity);
    const [updateComment] = useMutation(UpdateCommentMutation, {
        refetchQueries: [
            {
                query: ActivityLog_ContractActivities,
                variables: {
                    input: {
                        contractId,
                    },
                },
            },
        ],
    });
    const [deleteComment] = useMutation(DeleteCommentMutation, {
        refetchQueries: [
            {
                query: ActivityLog_ContractActivities,
                variables: {
                    input: {
                        contractId,
                    },
                },
            },
        ],
    });

    const [editedComment, setEditedComment] = useState(activity.comment);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isEditing, setIsEditing] = useState(false);
    const { formatMessage } = useIntl();
    const { handleError } = useErrorHandler();

    const handleClose = () => {
        setAnchorEl(null);
    };

    let actorId: string | undefined;
    if (activity.meta.createdBy.__typename === "RoleUser") {
        actorId = activity.meta.createdBy.id;
    }

    const handleDelete = () => {
        deleteComment({
            variables: {
                input: {
                    commentID: activity.id,
                    contractID: contractId,
                },
            },
            onError: handleError,
        });
        track("Contracts: Delete comment");
    };

    const handleSubmitEdit = () => {
        updateComment({
            variables: {
                input: {
                    commentID: activity.id,
                    contractID: contractId,
                    comment: editedComment,
                },
            },
            onError: handleError,
            onCompleted: () => {
                setIsEditing(false);
            },
        });
        track("Contracts: Edit comment");
    };

    return (
        <Stack direction="row" alignItems="center" gap={1} height="100%">
            <Stack direction="row">
                <Typography variant="textSm" color="textTextHelper">
                    <FormattedMessage defaultMessage="Commented" />
                </Typography>
                &nbsp;
            </Stack>
            {!isEditing ? activity.comment : null}
            {isEditing ? (
                <TextField
                    value={editedComment}
                    onChange={(e) => setEditedComment(e.target.value)}
                    slotProps={{
                        input: {
                            endAdornment: (
                                <IconButton onClick={handleSubmitEdit}>
                                    <Check />
                                </IconButton>
                            ),
                        },
                    }}
                />
            ) : null}

            {userId === actorId && (
                <IconButton
                    onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                    }}
                    sx={{ ml: "auto" }}
                    size="xsmall"
                    color="ghostGray"
                >
                    <DotsVertical />
                </IconButton>
            )}
            <RiddleMenu
                anchorEl={anchorEl}
                onClose={() => handleClose()}
                entries={[
                    {
                        label: formatMessage({ defaultMessage: "Edit comment" }),
                        icon: { pos: "start", component: <Pen /> },
                        onClick: () => {
                            setIsEditing(true);
                            setAnchorEl(null);
                        },
                    },
                    {
                        label: formatMessage({ defaultMessage: "Delete comment" }),
                        danger: true,
                        icon: { pos: "start", component: <Trash /> },
                        onClick: handleDelete,
                    },
                ]}
            />
        </Stack>
    );
};
