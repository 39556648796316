import { X } from "@ignite-analytics/icons";
import { IconButton } from "@mui/material";

interface CloseButtonProps {
    onClose: () => void;
}

export const CloseButton: React.FC<CloseButtonProps> = (props) => {
    return (
        <IconButton
            onClick={props.onClose}
            sx={{
                color: (theme) => theme.palette.tokens?.icon.iconTertiary,
                position: "absolute",
                top: 0,
                right: 0,
            }}
        >
            <X color="inherit" />
        </IconButton>
    );
};
