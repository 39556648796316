import { DotsVertical, Pen, Trash } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { LoadingButton } from "@mui/lab";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Skeleton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { User, useUsers } from "@/entities/users";
import { RiddleMenu } from "../RiddleMenu";
import { getDisplayName, getInitials } from "./helpers";

type Props = {
    userId: string;
    subsection?: string;
};
export const UserRow = ({ userId, subsection }: Props) => {
    const { loading, users } = useUsers([userId]);
    if (loading || !users.map((user) => user.id).includes(userId)) {
        return (
            <Stack direction="row" gap={2}>
                <Skeleton variant="circular" width={40} height={40} />
                <Stack>
                    <Typography variant="textSm" fontWeight={500}>
                        <Skeleton width={200} />
                    </Typography>
                    <Typography variant="textSm" fontWeight={400} color="GrayText">
                        <Skeleton width={150} />
                    </Typography>
                </Stack>
            </Stack>
        );
    }
    const user = users.find((u) => u.id === userId) as User;
    const email = user.email;
    const name = getDisplayName(user);
    const initials = getInitials(user);

    return (
        <Stack direction="row" gap={2}>
            <Avatar src={user.profilePicture} sx={{ width: 32, height: 32 }}>
                <Typography variant="textXs">{initials}</Typography>
            </Avatar>
            <Stack>
                <Typography variant="textSm" fontWeight={500}>
                    {name}
                </Typography>
                <Typography variant="textSm" fontWeight={400} color="GrayText">
                    {subsection ?? email}
                </Typography>
            </Stack>
        </Stack>
    );
};

type EditContactPersonDialogProps = {
    open: boolean;
    onClose: () => void;
    initial?: { firstName?: string; lastName?: string; email?: string; phone?: string };
    onEdit: (data: { firstName: string; lastName: string; email: string; phone?: string }) => Promise<void>;
};

export const EditContactPersonDialog = ({ open, onClose, initial, onEdit }: EditContactPersonDialogProps) => {
    const [loading, setLoading] = React.useState(false);
    const { formatMessage } = useIntl();
    const [value, setValue] = useState<{ firstName: string; lastName: string; email: string; phone?: string }>({
        firstName: initial?.firstName ?? "",
        lastName: initial?.lastName ?? "",
        email: initial?.email ?? "",
        phone: initial?.phone ?? "",
    });
    function handleClose() {
        onClose();
        setTimeout(() => {
            setValue({
                firstName: initial?.firstName ?? "",
                lastName: initial?.lastName ?? "",
                email: initial?.email ?? "",
            });
        }, 200);
    }

    return (
        <Dialog
            open={open}
            onClose={() => {
                handleClose();
            }}
            component="form"
            onSubmit={(e) => {
                if (initial) {
                    track("Contracts: Edit supplier contact");
                } else {
                    track("Contracts: Create supplier contact");
                }
                e.preventDefault();
                setLoading(true);
                onEdit(value).finally(() => {
                    handleClose();
                    setLoading(false);
                });
            }}
        >
            <DialogContent>
                <Stack gap={3} width="485px">
                    <Typography variant="textXl" fontWeight={500}>
                        {initial ? (
                            <FormattedMessage defaultMessage="Edit supplier contact" />
                        ) : (
                            <FormattedMessage defaultMessage="Create supplier contact" />
                        )}
                    </Typography>

                    <Stack direction="row" gap={2}>
                        <TextField
                            label={formatMessage({ defaultMessage: "First name" })}
                            placeholder={formatMessage({ defaultMessage: "First name" })}
                            required
                            variant="outlined"
                            value={value.firstName}
                            onChange={(e) => {
                                setValue({ ...value, firstName: e.target.value });
                            }}
                        />

                        <TextField
                            label={formatMessage({ defaultMessage: "Last name" })}
                            value={value.lastName}
                            placeholder={formatMessage({ defaultMessage: "Last name" })}
                            required
                            variant="outlined"
                            onChange={(e) => {
                                setValue({ ...value, lastName: e.target.value });
                            }}
                        />
                    </Stack>
                    <TextField
                        label={formatMessage({ defaultMessage: "Email" })}
                        placeholder={formatMessage({ defaultMessage: "Email" })}
                        required
                        variant="outlined"
                        type="email"
                        value={value.email}
                        onChange={(e) => {
                            setValue({ ...value, email: e.target.value });
                        }}
                    />
                    <TextField
                        label={formatMessage({ defaultMessage: "Phone number" })}
                        placeholder={formatMessage({ defaultMessage: "Phone number" })}
                        type="tel"
                        variant="outlined"
                        value={value.phone}
                        onChange={(e) => {
                            setValue({ ...value, phone: e.target.value });
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <LoadingButton type="submit" loading={loading} variant="contained">
                    <FormattedMessage defaultMessage="Save" />
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

type ContactPersonProps = {
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    edit: boolean;
    onRemove: () => Promise<void>;
    onEdit: (values: { firstName: string; lastName: string; email: string; phone?: string }) => Promise<void>;
    profilePicture?: string;
};

export const ContactPerson = ({
    profilePicture,
    firstName,
    lastName,
    email,
    phone,
    edit,
    onRemove,
    onEdit,
}: ContactPersonProps) => {
    const { formatMessage } = useIntl();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [updates, setUpdates] = React.useState({ firstName, lastName, email, phone });
    const forgedUser = { firstName, lastName, email, profilePicture, id: "", roles: [] };
    const initials = getInitials(forgedUser);
    const displayName = getDisplayName(forgedUser);

    const [editDialog, setEditDialog] = React.useState(false);
    function handleClose() {
        setAnchorEl(null);
        setUpdates({ firstName, lastName, email, phone });
    }

    return (
        <Stack direction="row" gap={2} alignItems="center">
            <Avatar src={profilePicture}>{initials}</Avatar>
            <Stack>
                <Typography variant="textSm" fontWeight={500}>
                    {displayName}
                </Typography>
                <Stack direction="row" alignItems="center" gap={1}>
                    <Typography variant="textSm" fontWeight={400} color="GrayText">
                        {email}
                    </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" gap={1}>
                    <Typography variant="textSm" fontWeight={400} color="GrayText">
                        {phone ?? formatMessage({ defaultMessage: "Phone number not available" })}
                    </Typography>
                </Stack>
            </Stack>
            {edit && (
                <IconButton
                    onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                    }}
                    sx={{ ml: "auto" }}
                    size="xsmall"
                    color="ghostGray"
                >
                    <DotsVertical />
                </IconButton>
            )}
            <RiddleMenu
                sx={{ width: 240 }}
                anchorEl={anchorEl}
                onClose={() => handleClose()}
                entries={[
                    {
                        label: formatMessage({ defaultMessage: "Edit contact" }),
                        icon: { pos: "start", component: <Pen /> },
                        onClick: async () => {
                            setEditDialog(true);
                            handleClose();
                        },
                        divider: true,
                    },
                    {
                        label: formatMessage({ defaultMessage: "Delete contact" }),
                        danger: true,
                        icon: { pos: "start", component: <Trash /> },
                        onClick: async () => {
                            onRemove();
                            handleClose();
                        },
                    },
                ]}
            />
            <EditContactPersonDialog
                open={editDialog}
                onClose={() => {
                    setEditDialog(false);
                }}
                initial={updates}
                onEdit={(data) => onEdit(data)}
            />
        </Stack>
    );
};
