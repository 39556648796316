import { FormattedMessage } from "react-intl";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { AccessChip } from "@/pages/detail/components/ContractSidebar/AccessCard/AccessChip";
import { RenderActivity } from "./RenderActivity";

const AccessLevelActivity_AccessLevelContractActivity = graphql(`
    fragment AccessLevelActivity_AccessLevelContractActivity on AccessLevelContractActivity {
        accessLevel
        prevAccessLevel
    }
`);

interface AccessLevelActivityProps {
    activity: FragmentType<typeof AccessLevelActivity_AccessLevelContractActivity>;
}

export const AccessLevelActivity: React.FC<AccessLevelActivityProps> = (props) => {
    const activity = getFragmentData(AccessLevelActivity_AccessLevelContractActivity, props.activity);

    const accessLevel = <AccessChip isPrivate={activity.accessLevel === "RESTRICTED"} />;
    const prevAccessLevel = <AccessChip isPrivate={activity.prevAccessLevel === "RESTRICTED"} />;

    return (
        <RenderActivity
            title={<FormattedMessage defaultMessage="Access Level" />}
            prev={prevAccessLevel}
            value={accessLevel}
        />
    );
};
