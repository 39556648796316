import { Search } from "@ignite-analytics/icons";
import { TextField } from "@mui/material";

interface SearchFieldProps {
    searchTerm: string;
    setSearchTerm: (value: string) => void;
    width?: number | string;
    placeholder: string;
    autoFocus?: boolean;
}

export const SearchField: React.FC<SearchFieldProps> = ({
    searchTerm,
    setSearchTerm,
    width = 300,
    placeholder,
    autoFocus,
}) => {
    return (
        <TextField
            placeholder={placeholder}
            value={searchTerm}
            onChange={(e) => {
                const value = e.target.value;
                setSearchTerm(value);
            }}
            slotProps={{
                input: {
                    startAdornment: <Search fontSize="medium" color="tertiary" />,
                },
            }}
            autoFocus={autoFocus}
            sx={{ width }}
        />
    );
};
