import { X } from "@ignite-analytics/icons";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { useState } from "react";
import { useSavePermissions } from "../hooks/useSavePermissions";
import { AccessLevel, AccessLevels } from "../types";
import { AccessLevelPicker } from "./AccessLevelPicker";
import { EditPublicAccess } from "./EditPublicAccess";
import { EditRestrictedAccess } from "./EditRestrictedAccess";

interface ManageAccessDialogProps {
    isOpen: boolean;
    onClose: () => void;
    refetchContracts: () => void;
    contractIds: string[];
    defaultAccess: AccessLevel;
    customPostSuccessSnackbar?: () => void;
}

export const ManageAccessDialog: React.FC<ManageAccessDialogProps> = ({
    isOpen,
    onClose,
    refetchContracts,
    contractIds,
    defaultAccess,
    customPostSuccessSnackbar,
}) => {
    const [accessLevel, setAccessLevel] = useState<AccessLevel>(defaultAccess);

    const handleAccessChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setAccessLevel((event.target as HTMLInputElement).value as AccessLevel);

    const handleSave = useSavePermissions(onClose, refetchContracts, customPostSuccessSnackbar);

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>
                <FormattedMessage defaultMessage="Manage access" />
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: "0.5em",
                        top: "0.5em",
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <X />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <AccessLevelPicker accessLevel={accessLevel} onChange={handleAccessChange} />

                {accessLevel === AccessLevels.RESTRICTED && (
                    <EditRestrictedAccess
                        contractIds={contractIds}
                        onSave={handleSave}
                        onCancel={onClose}
                        disableSaveButton={defaultAccess !== AccessLevels.PUBLIC}
                    />
                )}
                {accessLevel === AccessLevels.PUBLIC && (
                    <EditPublicAccess
                        contractIds={contractIds}
                        onSave={handleSave}
                        onCancel={onClose}
                        disableSaveButton={defaultAccess === AccessLevels.PUBLIC}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};
