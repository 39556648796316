import { Skeleton } from "@mui/material";
import { UserAvatars } from "@/components/UserAvatars";
import { useUsers } from "@/entities/users";

interface UserCellProps {
    userIds: string[];
}

export const UserCell: React.FC<UserCellProps> = ({ userIds }) => {
    const { loading, users } = useUsers(userIds);

    if (loading) return <Skeleton height="40px" width="300px" animation="wave" />;

    return <UserAvatars users={users} maxAvatars={5} />;
};
