import { FeatureToggleContextProvider } from "@ignite-analytics/feature-toggle";
import { ReactNode } from "react";
import { useUserOrThrow } from "@/contexts/useUser";

interface Props {
    children: ReactNode;
}

export const FeatureContext = ({ children }: Props) => {
    const { email, tenant } = useUserOrThrow();
    return (
        <FeatureToggleContextProvider tenant={tenant} userEmail={email}>
            {children}
        </FeatureToggleContextProvider>
    );
};
