import { FormattedMessage } from "react-intl";
import { UserAvatars } from "@/components/UserAvatars";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { RenderActivity } from "./RenderActivity";

const Activity_Responsible = graphql(`
    fragment Activity_Responsible on RoleUser {
        id
        firstName
        lastName
    }
`);

const ResponsibleActivity_Responsible = graphql(`
    fragment ResponsibleActivity_Responsible on ResponsiblesContractActivity {
        responsibles {
            ...Activity_Responsible
        }
        prevResponsibles {
            ...Activity_Responsible
        }
    }
`);

interface ResponsibleActivityProps {
    activity: FragmentType<typeof ResponsibleActivity_Responsible>;
}

export const ResponsibleActivity: React.FC<ResponsibleActivityProps> = (props) => {
    const activity = getFragmentData(ResponsibleActivity_Responsible, props.activity);
    const responsible = activity.responsibles
        .map((r) => getFragmentData(Activity_Responsible, r))
        .filter((r): r is { id: string; firstName: string; lastName: string } => r !== null);
    const prevResponsible =
        activity.prevResponsibles
            ?.map((r) => getFragmentData(Activity_Responsible, r))
            .filter((r): r is { id: string; firstName: string; lastName: string } => r !== null && r !== undefined) ??
        [];

    return (
        <RenderActivity
            title={<FormattedMessage defaultMessage="Responsible" />}
            value={<UserAvatars users={responsible} />}
            prev={prevResponsible.length > 0 ? <UserAvatars users={prevResponsible} /> : undefined}
        />
    );
};
