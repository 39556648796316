import { Controller, useFormContext } from "react-hook-form";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { GroupSelect } from "@/components/TreePicker";
import { Blank } from "../Blank";
import { ChipList } from "../ChipList";
import { UpdateContractFormParams } from "@/types";

const GroupList_ContractCustomFieldGroupListFragment = graphql(`
    fragment GroupList_ContractCustomFieldGroupListFragment on ContractCustomFieldGroupList {
        id
        name
        type
        externalReferenceId
        groupList {
            id
            name
        }
    }
`);

interface GroupListProps {
    data: FragmentType<typeof GroupList_ContractCustomFieldGroupListFragment>;
    edit?: boolean;
}

export const GroupList: React.FC<GroupListProps> = (props) => {
    const data = getFragmentData(GroupList_ContractCustomFieldGroupListFragment, props.data);
    const { control } = useFormContext<UpdateContractFormParams>();

    if (props.edit) {
        return (
            <Controller
                name={`customfields/${data.id}`}
                control={control}
                defaultValue={{ id: data.id, referenceIds: data.groupList?.map((group) => group.id) }}
                render={({ field }) => (
                    <GroupSelect
                        multiple
                        id={data.externalReferenceId ?? ""}
                        value={field.value?.referenceIds ?? []}
                        onChange={(val) => {
                            field.onChange({ id: data.id, referenceIds: val });
                        }}
                    />
                )}
            />
        );
    }

    if (!data.groupList || data.groupList.length === 0) {
        return <Blank />;
    }
    return <ChipList ids={data.groupList.map((group) => group.id)} />;
};
