import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import generatedIntrospection from "./generated/introspection.json";

const APP_TAG = "contracts";

export function createGraphqlClient(uri: string, tenant: string | null) {
    const authenticationLink = setContext(async () => {
        const headers: { [key: string]: string } = {};
        if (tenant !== null) {
            headers["x-tenant-id"] = tenant;
        }
        return {
            headers,
            fetchOptions: {
                credentials: "include",
            },
        };
    });

    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach((err) => {
                const code = err.extensions?.code as string | undefined;
                if (code === "DOWNSTREAM_SERVICE_ERROR") {
                    return;
                }
                const tags = {
                    app: APP_TAG,
                    code,
                    operation: operation.operationName,
                    path: JSON.stringify(err.path),
                    location: JSON.stringify(err.locations),
                };
                Sentry.captureMessage("reduce graphql-error", { tags });
            });
        }
        if (networkError) {
            Sentry.captureException(networkError, {
                tags: { app: APP_TAG, operation: operation.operationName },
            });
        }
    });

    const httpLink = new HttpLink({ uri, credentials: "include" });
    const apolloClient = new ApolloClient({
        cache: new InMemoryCache({
            // This is needed to generate types for unions
            // https://the-guild.dev/graphql/codegen/plugins/other/fragment-matcher
            possibleTypes: generatedIntrospection.possibleTypes,
            typePolicies: {
                Query: {
                    fields: {
                        getContracts: {
                            keyArgs: ["input", ["sort", "search", "where", "supplierIds", "responsibles"]],
                        },
                    },
                },
                ContractCustomField: {
                    keyFields: false,
                },
            },
        }),
        link: from([errorLink, authenticationLink, httpLink]),
    });

    return apolloClient;
}
