import React, { useEffect, useState } from "react";

import { decodeJwt } from "jose";
import { UserContext } from "./context";
import { TokenClaims, tokenClaimsSchema, User, userSchema } from "./types";

type Props = {
    children?: React.ReactNode;
};

export const UserProvider: React.FC<Props> = ({ children }) => {
    const [user, setUser] = useState<User | undefined>(undefined);

    useEffect(() => {
        const tokenFromLocalStorage: string | null = localStorage.getItem("token");
        if (!tokenFromLocalStorage) {
            return;
        }

        const decodedToken: TokenClaims = tokenClaimsSchema.parse(decodeJwt(tokenFromLocalStorage));
        const user: User = userSchema.parse({
            email: decodedToken.email,
            firstName: decodedToken.firstName,
            lastName: decodedToken.lastName,
            tenant: decodedToken.tenant,
            userId: decodedToken.sub,
        });
        setUser(user);
    }, []);

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
