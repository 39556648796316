import { useMutation, useQuery } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useSnackbar } from "@/contexts/useSnackbar";
import { useErrorHandler } from "@/errorHandling/ErrorHandlerContext";
import { graphql } from "@/generated/gql";
import {
    DeleteCommentMutation,
    UpdateCommentMutation,
} from "@/pages/detail/activityLog/Activites/Activity/CommentActivity";
import { CreateCommentMutation } from "@/pages/detail/activityLog/components/CommentSection";
import { Note } from "./models";

const UseNotes_ContractQuery = graphql(`
    query UseNotes_ContractQuery($id: ID!) {
        getContract(id: $id) {
            id
            comments {
                id
                text
                createdAt
                createdBy {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`);

export function useNotes(contractId: string) {
    const [createComment] = useMutation(CreateCommentMutation, {
        refetchQueries: [UseNotes_ContractQuery],
    });
    const [updateComment] = useMutation(UpdateCommentMutation, {
        refetchQueries: [UseNotes_ContractQuery],
    });
    const [deleteComment] = useMutation(DeleteCommentMutation, {
        refetchQueries: [UseNotes_ContractQuery],
    });

    const { data, loading, refetch } = useQuery(UseNotes_ContractQuery, {
        variables: { id: contractId },
        skip: contractId === "",
    });

    const { formatMessage } = useIntl();
    const { postSnackbar } = useSnackbar();
    const { handleError } = useErrorHandler();

    const notes: Note[] = useMemo(() => {
        return (
            data?.getContract.comments.map((gqlNote) => ({
                id: gqlNote.id,
                text: gqlNote.text,
                createdAt: gqlNote.createdAt,
                creator: {
                    id: gqlNote.createdBy?.id ?? "",
                    firstName: gqlNote.createdBy?.firstName ?? formatMessage({ defaultMessage: "Unknown user" }),
                    lastName: gqlNote.createdBy?.lastName ?? "",
                },
                referenceId: contractId,
            })) ?? []
        );
    }, [data, formatMessage, contractId]);

    return {
        notes,
        add: async (text: string) => {
            track("Contracts: Add comment");
            createComment({
                variables: {
                    input: {
                        comment: text,
                        contractID: contractId,
                    },
                },
                onError: handleError,
                onCompleted: () => {
                    postSnackbar({
                        message: formatMessage({ defaultMessage: "Successfully added note" }),
                        severity: "success",
                    });
                    refetch();
                },
            });
        },
        delete: async (id: string) => {
            track("Contracts: Delete comment");
            deleteComment({
                variables: {
                    input: {
                        commentID: id,
                        contractID: contractId,
                    },
                },
                onError: handleError,
                onCompleted: () => {
                    postSnackbar({
                        message: formatMessage({ defaultMessage: "Successfully deleted note" }),
                        severity: "success",
                    });
                    refetch();
                },
            });
        },
        update: async (id: string, text: string) => {
            track("Contracts: Edit comment");
            updateComment({
                variables: {
                    input: {
                        commentID: id,
                        comment: text,
                        contractID: contractId,
                    },
                },
                onError: handleError,
                onCompleted: () => {
                    postSnackbar({
                        message: formatMessage({ defaultMessage: "Successfully edited note" }),
                        severity: "success",
                    });
                    refetch();
                },
            });
        },
        loading,
        refetch,
    };
}
