import { Stack } from "@mui/material";

interface DocumentViewerProps {
    url: string;
}

export const PdfDocumentViewer = ({ url }: DocumentViewerProps) => {
    return (
        <Stack width="100%" height="100%">
            <embed src={url} type="application/pdf" width="100%" height="100%" />
        </Stack>
    );
};
