import { ApolloError } from "@apollo/client";
import { Filter, generators } from "@ignite-analytics/filters";
import dayjs from "dayjs";
import { ContractListItem, CustomField, Row } from "src/components/ContractsTablePro/contractListItem";
import { toSnakeCase } from "src/helpers/toSnakeCase";
import { makeContractsApiHttp as http } from "src/http";
import { apolloClient } from "@/contexts";
import { captureMessage } from "@/errorHandling/errors";
import { graphql } from "@/generated";
import { GetContractsInput } from "@/generated/graphql";
import { hasValue, replaceNullsWithUndefineds } from "@/helpers/hasValue";
import { ContractFields, Status } from "@/types";
import { Layout } from "@/types/Layout";
import { CONTRACT_STATUS_COLUMN_ID } from "./columns/generatedColumnIds";

type refetchProps = {
    searchTerm?: string;
    status: Status;
    pagination?: { page: number; perPage: number };
    sort?: { sortOrder: "asc" | "desc"; sortBy: string };
    filters?: Filter[];
};

// we can assume that the metadata will not change during the lifecycle of the app
// if it does, just refresh the page and you're good to go again.
let lastContractFields: ContractFields | undefined;

export async function getContractMetadata() {
    if (!lastContractFields) {
        const res = await fetch(`${import.meta.env.REACT_APP_CONTRACTS_BASE_URL}/contracts/metadata`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = (await res.json()) as ContractFields;
        lastContractFields = data;
    }
    return lastContractFields;
}

export async function getLayoutByIdOrDefault(id: "default" | string) {
    const res = await fetch(`${import.meta.env.REACT_APP_CONTRACTS_BASE_URL}/contracts/layouts/${id}/content`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });
    return res.json() as Promise<Layout | undefined>;
}

export function fieldExists(specificOrCustomField: string, metadata: ContractFields) {
    // This is a generated column with data from the end date column
    // so the field does not exist in the metadata
    if (specificOrCustomField === CONTRACT_STATUS_COLUMN_ID) {
        return true;
    }
    if (specificOrCustomField in metadata.specificFields) {
        return true;
    }
    return metadata.customFields.some((f) => f.id === specificOrCustomField);
}

export function translateSpecificFieldToDmsId(s: string, metadata: ContractFields) {
    if (s in metadata.specificFields) {
        const key = s as keyof ContractFields["specificFields"];
        const value = metadata.specificFields[key]?.id;
        if (value) {
            return value;
        }
    }
    return s;
}

export function translateColumnIdToSortId(columnId: string, metadata: ContractFields): string | undefined {
    if (columnId === CONTRACT_STATUS_COLUMN_ID) {
        return CONTRACT_STATUS_COLUMN_ID;
    }

    const specificFieldsMatch = Object.entries(metadata.specificFields).find(([_, field]) => field.id === columnId);
    if (specificFieldsMatch) return specificFieldsMatch?.[0];
    const customFieldsMatch = metadata.customFields.find((field) => field.id === columnId);
    if (customFieldsMatch) return customFieldsMatch.id;
    return undefined;
}

interface ContractsSearchResponse {
    data: ContractListItem[];
    total: number;
}

const mapToRows = (items: ContractListItem[], contractFields: ContractFields): Row[] =>
    items.map((item): Row => {
        const specificFields: CustomField[] = [
            {
                id: contractFields.specificFields.title.id,
                name: "title",
                dataType: "TITLE",
                data: { label: item.title, value: item.id },
            } as const,
            {
                id: contractFields.specificFields.supplier.id,
                name: "Supplier",
                dataType: "SUPPLIER",
                data: item.supplierId,
            } as const,
            {
                id: contractFields.specificFields.description.id,
                name: "description",
                dataType: "TEXT",
                data: item.description,
            } as const,
            {
                id: contractFields.specificFields.startDate.id,
                name: "Start Date",
                dataType: "DATE",
                data: item.startDate,
            } as const,
            {
                id: contractFields.specificFields.endDate.id,
                name: "endDate",
                dataType: "END_DATE",
                data: item.endDate,
            } as const,
            ...(contractFields.specificFields.renewalDate
                ? [
                      {
                          id: contractFields.specificFields.renewalDate.id,
                          name: "renewalDate",
                          dataType: "DATE",
                          data: item.renewalDate,
                      } as const,
                  ]
                : []),
            {
                id: contractFields.specificFields.contractResponsible.id,
                name: "Contract Responsible",
                dataType: "USER_LIST",
                data: item.contractResponsibleIds,
            } as const,
            ...(contractFields.specificFields.totalSpend
                ? [
                      {
                          id: contractFields.specificFields.totalSpend.id,
                          name: "totalSpend",
                          dataType: "SPEND",
                          data: item.totalSpend,
                      } as const,
                  ]
                : []),
        ];

        return {
            id: item.id,
            isPrivate: item.isPrivate,
            responsibleIds: item.contractResponsibleIds.map((r) => r.value).filter(hasValue),
            fields: specificFields.concat(item.customFields),
        };
    });

const statusToFilters = (
    status: Status,
    startDateColumnId: string,
    endDateColumnId: string,
    renewalDateColumnId: string | undefined
): Filter[] => {
    let filter: Filter[];
    switch (status) {
        case "active":
            filter = [
                generators.generateConditionalFilter({
                    valueDescription: "date",
                    children: [
                        generators.generateExistsFilter(`data_column_${startDateColumnId}`, "date", false),
                        generators.generateStaticDateFilter(
                            `data_column_${startDateColumnId}`,
                            "date",
                            null,
                            dayjs().format("YYYY-MM-DD")
                        ),
                    ],
                    operator: "OR",
                }),
                generators.generateConditionalFilter({
                    valueDescription: "date",
                    children: [
                        generators.generateExistsFilter(`data_column_${endDateColumnId}`, "date", false),
                        generators.generateStaticDateFilter(
                            `data_column_${endDateColumnId}`,
                            "date",
                            dayjs().format("YYYY-MM-DD"),
                            null
                        ),
                    ],
                    operator: "OR",
                }),
            ];
            break;
        case "expiring-soon":
            filter = [
                generators.generateStaticDateFilter(
                    `data_column_${endDateColumnId}`,
                    "date",
                    dayjs().format("YYYY-MM-DD"),
                    dayjs().add(180, "days").format("YYYY-MM-DD")
                ),
            ];
            break;
        case "renewing-soon":
            if (!renewalDateColumnId) {
                filter = [];
                break;
            }
            filter = [
                generators.generateStaticDateFilter(
                    `data_column_${renewalDateColumnId}`,
                    "date",
                    dayjs().format("YYYY-MM-DD"),
                    dayjs().add(180, "days").format("YYYY-MM-DD")
                ),
            ];
            break;
        case "expired":
            filter = [
                generators.generateStaticDateFilter(
                    `data_column_${endDateColumnId}`,
                    "date",
                    null,
                    dayjs().format("YYYY-MM-DD")
                ),
            ];
            break;
        case "upcoming":
            filter = [
                generators.generateStaticDateFilter(
                    `data_column_${startDateColumnId}`,
                    "date",
                    dayjs().add(1, "days").format("YYYY-MM-DD"),
                    null
                ),
            ];
            break;
        case "all":
            filter = [];
            break;
    }
    return filter;
};

export async function loadContracts(opts: refetchProps) {
    const contractMetadata = await getContractMetadata();
    const { searchTerm, pagination, status, sort, filters } = opts;
    const pageRange = pagination?.perPage ?? 25;
    const pageIndex = (pagination?.page ?? 0) * pageRange;

    const contractFields = contractMetadata;
    const queryParams = {
        searchTerm,
        status,
        pagination: { pageIndex, pageRange },
        sort:
            sort && fieldExists(sort.sortBy, contractMetadata)
                ? {
                      direction: sort.sortOrder,
                      // we allow passing sortBy=<specificFieldName> in the URL. If we find
                      // such a specific field, we'll replace the sortBy value with the columnId,
                      // so DMS can understand what we're asking for.
                      columnId: translateSpecificFieldToDmsId(sort.sortBy, contractMetadata),
                  }
                : undefined,
        filters: filters ?? [],
    };
    const startDateColumnId = contractFields.specificFields.startDate.id;
    const endDateColumnId = contractFields.specificFields.endDate.id;
    const renewalDateColumnId = contractFields.specificFields.renewalDate?.id;
    const statusFilter =
        queryParams.status && endDateColumnId && startDateColumnId
            ? statusToFilters(queryParams.status, startDateColumnId, endDateColumnId, renewalDateColumnId)
            : undefined;

    const jsonFilter = JSON.stringify(
        toSnakeCase([...(statusFilter ?? []), ...(queryParams.filters ?? [])].filter(Boolean), false)
    );
    const finalParams = {
        ...queryParams,
        filters: jsonFilter,
        sort: queryParams.sort ?? {
            direction: "asc",
            columnId: contractFields.specificFields.title.id,
        },
    };

    const contracts = await http()
        .post<ContractsSearchResponse>("/contracts/search", finalParams)
        .then((response) => {
            return response.data;
        });

    return {
        unmapped: contracts.data,
        contracts: { total: contracts.total, data: mapToRows(contracts.data, contractFields) },
        columns: contractMetadata,
    };
}

const getContracts = graphql(`
    query contracts_list($input: GetContractsInput) {
        getContracts(input: $input) {
            data {
                id
                title
                supplier {
                    id
                    name
                }
                startDate
                endDate
                renewalDate
                responsibles {
                    id
                    firstName
                    lastName
                    email
                }
                totalSpend
            }
        }
    }
`);

export async function contractsFromGraphql(input?: GetContractsInput) {
    const result = apolloClient
        .query({
            query: getContracts,
            variables: { input },
        })
        .then((response) => {
            if (!response.data?.getContracts) {
                return [];
            }
            return response.data.getContracts.data.map((row) => {
                const mapped = replaceNullsWithUndefineds(row);
                mapped.responsibles = mapped.responsibles.filter((r) => r !== null);
                return mapped;
            });
        })
        .catch((e: ApolloError) => {
            // e is apolloerror
            captureMessage("Failed to fetch contracts from graphql", { extra: { apolloError: JSON.stringify(e) } });
            return [];
        });
    return result;
}
