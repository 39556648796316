import { Grid2, Stack, Typography } from "@mui/material";

import { FormattedMessage } from "react-intl";
import { graphql } from "@/generated";
import { FragmentType, getFragmentData } from "@/generated/fragment-masking";
import { Text } from "./Text";
import { Group } from "./Group";
import { GroupList } from "./GroupList";
import { Number } from "./Number";
import { Date } from "./Date";
import { Tag } from "./Tag";
import { TagList } from "./TagList";
import { Boolean } from "./Boolean";
import { MonetaryAmount } from "./MonetaryAmount";
import { TextList } from "./TextList";

const CustomField_CustomFieldFragment = graphql(`
    fragment CustomField_CustomFieldFragment on ContractCustomField {
        ...Text_ContractCustomFieldTextFragment
        ...TextList_ContractCustomFieldTextListFragment
        ...Group_ContractCustomFieldGroupFragment
        ...GroupList_ContractCustomFieldGroupListFragment
        ...Number_ContractCustomFieldNumberFragment
        ...Date_ContractCustomFieldDateFragment
        ...Tag_ContractCustomFieldTagFragment
        ...TagList_ContractCustomFieldTagListFragment
        ...Boolean_ContractCustomFieldBooleanFragment
        ...MonetaryAmount_ContractCustomFieldMonetaryAmountFragment
        ... on BaseContractCustomField {
            id
            name
        }
    }
`);

type CustomFieldProps = {
    customField: FragmentType<typeof CustomField_CustomFieldFragment>;
    edit?: boolean;
};

export const CustomField: React.FC<CustomFieldProps> = (props) => {
    const customField = getFragmentData(CustomField_CustomFieldFragment, props.customField);

    // Do not render anything if we have not implemented it.
    // I'm sure there's a better way to do this...
    const implementedTypes: (typeof customField.__typename)[] = [
        "ContractCustomFieldText",
        "ContractCustomFieldTextList",
        "ContractCustomFieldNumber",
        "ContractCustomFieldGroup",
        "ContractCustomFieldGroupList",
        "ContractCustomFieldTag",
        "ContractCustomFieldTagList",
        "ContractCustomFieldDate",
        "ContractCustomFieldUser",
        "ContractCustomFieldBoolean",
        "ContractCustomFieldMonetaryAmount",
    ];
    if (!implementedTypes.includes(customField.__typename)) {
        return null;
    }

    return (
        <Grid2 size={4}>
            <Stack gap={1} alignItems="stretch" height="100%">
                <Typography variant="textMd" fontWeight={500}>
                    {customField.name}
                </Typography>
                <Stack height="100%" alignItems="center" direction="row">
                    <CustomFieldValue {...props} />
                </Stack>
            </Stack>
        </Grid2>
    );
};

const CustomFieldValue: React.FC<CustomFieldProps> = (props) => {
    const customField = getFragmentData(CustomField_CustomFieldFragment, props.customField);
    switch (customField.__typename) {
        case "ContractCustomFieldText":
            return <Text edit={props.edit} data={customField} />;
        case "ContractCustomFieldTextList":
            return <TextList edit={props.edit} data={customField} />;
        case "ContractCustomFieldNumber":
            return <Number edit={props.edit} data={customField} />;
        case "ContractCustomFieldGroup":
            return <Group edit={props.edit} data={customField} />;
        case "ContractCustomFieldGroupList":
            return <GroupList edit={props.edit} data={customField} />;
        case "ContractCustomFieldTag":
            return <Tag edit={props.edit} data={customField} />;
        case "ContractCustomFieldTagList":
            return <TagList edit={props.edit} data={customField} />;
        case "ContractCustomFieldDate":
            return <Date edit={props.edit} data={customField} />;
        case "ContractCustomFieldBoolean":
            return <Boolean edit={props.edit} data={customField} />;
        case "ContractCustomFieldMonetaryAmount":
            return <MonetaryAmount edit={props.edit} data={customField} />;

        default:
            return (
                <Stack>
                    <Typography variant="body2" color="text.secondary">
                        <FormattedMessage
                            defaultMessage="{name} not implemented"
                            values={{ name: customField.__typename }}
                        />
                    </Typography>
                </Stack>
            );
    }
};
