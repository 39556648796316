import { z } from "zod";

const userSchema = z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
});

export const contractPermissionSchema = z.object({
    user: userSchema,
    roleId: z.enum([
        "global.admin",
        "global.editor",
        "global.viewer",
        "global.contract-viewer",
        "global.contract-editor",
    ]),
    reads: z.array(
        z.object({
            contractId: z.string(),
            isResponsible: z.boolean(),
            grantedReadAccess: z.boolean(),
        })
    ),
    writes: z.array(
        z.object({
            contractId: z.string(),
            isResponsible: z.boolean(),
            grantedReadAccess: z.boolean(),
        })
    ),
});

export type ContractPermissions = z.infer<typeof contractPermissionSchema>;
export type User = z.infer<typeof userSchema>;
