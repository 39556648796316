import {
    ActiveChip,
    ExpiredLastSixMonthsChip,
    ExpiredMoreThanSixMonthsAgoChip,
    ExpiringNextSixMonthsChip,
} from "./chips";

export const getStatusChipContent = (endDate: Date | null) => {
    const timeNow = new Date();

    // If no end date, we consider the contract as active
    if (!endDate) {
        return <ActiveChip />;
    }

    // Expired
    if (endDate < timeNow) {
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(timeNow.getMonth() - 6);
        // Expired last 6 months
        if (endDate > sixMonthsAgo) {
            return <ExpiredLastSixMonthsChip />;
        }
        // Expired more than 6 months ago
        return <ExpiredMoreThanSixMonthsAgoChip />;
    }

    const sixMonthsFromNow = new Date();
    sixMonthsFromNow.setMonth(timeNow.getMonth() + 6);
    // Expiring next 6 months
    if (endDate < sixMonthsFromNow) {
        return <ExpiringNextSixMonthsChip />;
    }

    // Active
    return <ActiveChip />;
};
