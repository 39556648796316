import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Divider, Grid2 as Grid, Stack, TextField, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { usePermissionCheck } from "@/hooks/usePermissionCheck";
import { normalizeDateString } from "@/helpers/dates";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { useCompanyCurrency } from "@/contexts/CompanyCurrencyContext";

import { SupplierDropDown } from "@/components/SupplierDropdown";
import { SupplierChip } from "@/components/SuppliersChip";
import { UpdateContractFormParams } from "@/types";
import { ContractField } from "../field";
import { Blank } from "./Blank";
import { BusinessUnitPicker } from "./BusinessUnitPicker";
import { ChipList } from "./ChipList";
import { SpendCategoryPicker } from "./SpendCategoryPicker";

const ContractBasicDetails_ContractFragment = graphql(`
    fragment ContractBasicDetails_ContractFragment on Contract {
        title
        description
        startDate
        endDate
        renewalDate
        totalSpend
        spendCategories {
            id
        }
        businessUnits {
            id
        }
        supplier {
            id
            name
        }
    }
`);

interface ContractDetailsBasicDetailsProps {
    editMode: boolean;
    contract: FragmentType<typeof ContractBasicDetails_ContractFragment>;
}

export const ContractBasicDetails: React.FC<ContractDetailsBasicDetailsProps> = (props) => {
    const { formatMessage } = useIntl();
    const canOpenSupplierCard = usePermissionCheck("suppliers", "general", "read").data ?? false;
    const spendCategoryAndBusinessUnitFeatureFlag = useFeatureToggle(
        "standardized-spend-category-and-business-unit",
        false
    );
    const currency = useCompanyCurrency();
    const contract = getFragmentData(ContractBasicDetails_ContractFragment, props.contract);
    const { control, watch, setValue } = useFormContext<UpdateContractFormParams>();

    const spendCategories = contract.spendCategories
        .filter((category) => category != null)
        .map((category) => category.id);
    const businessUnits = contract.businessUnits
        .filter((businessUnit) => businessUnit != null)
        .map((businessUnit) => businessUnit.id);
    return (
        <Stack gap={3}>
            <Typography variant="textLg" fontWeight={500}>
                <FormattedMessage defaultMessage="Basic details" />
            </Typography>
            <Divider />
            <Grid container rowGap={3} columnSpacing={3}>
                <ContractField
                    edit={props.editMode}
                    size={12}
                    label={formatMessage({ defaultMessage: "Contract title" })}
                >
                    {contract.title}
                    <Controller
                        name="title"
                        control={control}
                        defaultValue={contract.title}
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                sx={{ minWidth: "260px" }}
                                value={field.value}
                                onChange={field.onChange}
                                placeholder={formatMessage({ defaultMessage: "Add title" })}
                            />
                        )}
                    />
                </ContractField>
                <ContractField
                    blank={contract.description == null}
                    edit={props.editMode}
                    size={12}
                    label={formatMessage({ defaultMessage: "Description" })}
                >
                    <Typography variant="textMd">{contract.description}</Typography>
                    <Controller
                        name="description"
                        control={control}
                        defaultValue={contract.description ?? ""}
                        render={({ field }) => (
                            <TextField
                                minRows={3}
                                value={field.value}
                                slotProps={{
                                    htmlInput: {
                                        autosize: true,
                                        style: {
                                            resize: "vertical",
                                        },
                                    },
                                }}
                                onChange={field.onChange}
                                multiline
                                fullWidth
                                placeholder={formatMessage({ defaultMessage: "Add description" })}
                            />
                        )}
                    />
                </ContractField>
                <ContractField size={8} edit={props.editMode} label={formatMessage({ defaultMessage: "Supplier" })}>
                    <Stack direction="row">
                        {contract.supplier ? (
                            <SupplierChip
                                disabled={!canOpenSupplierCard}
                                name={contract.supplier.name}
                                id={contract.supplier.id}
                            />
                        ) : (
                            <Blank />
                        )}
                    </Stack>
                    <Controller
                        name="supplier"
                        control={control}
                        defaultValue={contract.supplier ?? null}
                        render={({ field }) => (
                            <SupplierDropDown
                                fullWidth
                                value={field.value}
                                onChange={(value) => {
                                    if (value == null) {
                                        field.onChange(null);
                                        return;
                                    }
                                    field.onChange({ id: value.id, name: value.name });
                                }}
                            />
                        )}
                    />
                </ContractField>
                <ContractField
                    size={4}
                    label={formatMessage({ defaultMessage: "Total spend" })}
                    blank={contract.totalSpend === 0 || contract.totalSpend == null}
                    edit={props.editMode}
                >
                    {currency.formatInteger(contract.totalSpend)}
                    <TextField fullWidth disabled value={currency.formatInteger(contract.totalSpend)} />
                </ContractField>
                <ContractField
                    edit={props.editMode}
                    size={4}
                    label={formatMessage({ defaultMessage: "Start date" })}
                    blank={contract.startDate == null}
                >
                    {normalizeDateString(contract.startDate)?.format("DD MMM YYYY")}
                    <Controller
                        name="startDate"
                        defaultValue={normalizeDateString(contract.startDate)}
                        render={({ field }) => (
                            <DesktopDatePicker
                                slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
                                maxDate={watch("endDate") ? dayjs(watch("endDate")) : undefined}
                                value={field.value ? dayjs(field.value) : null}
                                timezone="UTC"
                                onChange={(value: dayjs.Dayjs | null) => {
                                    const newDate = normalizeDateString(value)?.toISOString() ?? null;
                                    const isDirty = field.value !== newDate;
                                    setValue("startDate", newDate, { shouldDirty: isDirty });
                                }}
                            />
                        )}
                    />
                </ContractField>
                <ContractField
                    edit={props.editMode}
                    size={4}
                    label={formatMessage({ defaultMessage: "End date" })}
                    blank={contract.endDate == null}
                >
                    {normalizeDateString(contract.endDate)?.format("DD MMM YYYY")}
                    <Controller
                        name="endDate"
                        defaultValue={normalizeDateString(contract.endDate)}
                        render={({ field }) => (
                            <DesktopDatePicker
                                slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
                                value={field.value ? dayjs(field.value) : null}
                                timezone="UTC"
                                minDate={watch("startDate") ? dayjs(watch("startDate")) : undefined}
                                onChange={(value: dayjs.Dayjs | null) => {
                                    const newDate = normalizeDateString(value)?.toISOString() ?? null;
                                    const isDirty = field.value !== newDate;
                                    setValue("endDate", newDate, { shouldDirty: isDirty });
                                }}
                            />
                        )}
                    />
                </ContractField>
                <ContractField
                    edit={props.editMode}
                    size={4}
                    label={formatMessage({ defaultMessage: "Renewal date" })}
                    blank={contract.renewalDate == null}
                >
                    {normalizeDateString(contract.renewalDate)?.format("DD MMM YYYY")}
                    <Controller
                        name="renewalDate"
                        defaultValue={normalizeDateString(contract.renewalDate)}
                        render={({ field }) => (
                            <DesktopDatePicker
                                slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
                                value={field.value ? dayjs(field.value) : null}
                                timezone="UTC"
                                onChange={(value: dayjs.Dayjs | null) => {
                                    const newDate = normalizeDateString(value)?.toISOString() ?? null;
                                    const isDirty = field.value !== newDate;
                                    setValue("renewalDate", newDate, { shouldDirty: isDirty });
                                }}
                            />
                        )}
                    />
                </ContractField>
                {spendCategoryAndBusinessUnitFeatureFlag && (
                    <>
                        <ContractField
                            edit={props.editMode}
                            size={4}
                            label={formatMessage({ defaultMessage: "Spend category" })}
                            blank={spendCategories.length === 0}
                        >
                            <ChipList ids={spendCategories} />
                            <Controller
                                name="spendCategories"
                                defaultValue={spendCategories}
                                render={({ field }) => (
                                    <SpendCategoryPicker value={field.value} onChange={field.onChange} />
                                )}
                            />
                        </ContractField>
                        <ContractField
                            edit={props.editMode}
                            size={4}
                            label={formatMessage({ defaultMessage: "Business unit" })}
                            blank={businessUnits.length === 0}
                        >
                            <ChipList ids={businessUnits} />
                            <Controller
                                name="businessUnits"
                                defaultValue={businessUnits}
                                render={({ field }) => (
                                    <BusinessUnitPicker value={field.value} onChange={field.onChange} />
                                )}
                            />
                        </ContractField>
                    </>
                )}
            </Grid>
        </Stack>
    );
};
