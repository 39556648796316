import { Row } from "../../contractListItem";
import { getStatusChipContent } from "./getStatusChipContent";

interface ContractStatusCellProps {
    row: Row;
}

export const ContractStatusCell: React.FC<ContractStatusCellProps> = ({ row }) => {
    const endDatefield = row.fields.find((field) => field.dataType === "END_DATE");
    const endDate = endDatefield?.data ? new Date(String(endDatefield.data)) : null;

    return getStatusChipContent(endDate);
};
