import { getBorderToken } from "@ignite-analytics/theme";
import { Box, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { useEffect, useRef, useState } from "react";

type TextHoverTooltipProps = {
    leaveDelay?: number;
} & TooltipProps;

// A styled tooltip, with larger borders
const StyledTooltip = styled(({ className, ...props }: TextHoverTooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: getBorderToken("border300", theme),
    },
    [`& .${tooltipClasses.tooltip}`]: {
        borderRadius: theme.shape.borderRadius,
        borderColor: getBorderToken("border300", theme),
        borderWidth: "1px",
        borderStyle: "solid",
    },
}));

// A tooltip that shows the content only when the inner element overflws
export const OverflowTooltip = ({ children, ...props }: TextHoverTooltipProps) => {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const childRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (childRef.current) {
            setIsOverflowed(childRef.current.scrollWidth > childRef.current.clientWidth);
        }
    }, [children]);

    return (
        <StyledTooltip disableHoverListener={!isOverflowed} {...props}>
            <Box
                sx={{
                    // overflow truncate
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                ref={childRef}
            >
                {children}
            </Box>
        </StyledTooltip>
    );
};
