import { useQuery } from "@tanstack/react-query";
import { useUserOrThrow } from "@/contexts/useUser";
import { env } from "@/env";
import { captureMessage } from "@/errorHandling/errors";

type ObjectRelation = {
    object: string;
    relation: string;
};

async function fetchPermissions(namespace: string, tuples: ObjectRelation[]): Promise<boolean[]> {
    const permissionObjects = fetch(`${env.REACT_APP_AUTH_SIDECAR_API_URL}/api/v1/permissions/check/${namespace}`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(tuples),
    })
        .then((response) => {
            if (response.ok) {
                return response.json() as Promise<boolean[]>;
            }
            throw new Error("Failed to get permissions");
        })
        .catch((err) => {
            captureMessage("Failed to get permissions", { extra: { err } });
            throw err;
        });
    return permissionObjects;
}

export function usePermissionCheck(namespace: string, object: string, relation: string) {
    const { tenant } = useUserOrThrow();
    return useQuery({
        queryKey: [tenant, "permission", namespace, object, relation],
        queryFn: () => fetchPermissions(namespace, [{ object, relation }]).then((res) => res[0]),
        enabled: true,
        retry: 1,
        staleTime: 1000 * 60 * 1,
    });
}
