import { Category } from "@ignite-analytics/icons";
import { Chip, Stack, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { getGroupAncestors, GroupAncestors } from "@/hooks/useGroupsV2";
import { ViewGroupStructure } from "../../../../components/GroupStructure";

interface Props {
    ids: string[];
}
export const ChipList = ({ ids }: Props) => {
    const [ancestors, setAncestors] = useState<GroupAncestors[]>([]);
    useEffect(() => {
        getGroupAncestors(ids).then((res) => {
            setAncestors(res);
        });
    }, [ids]);

    if (!ancestors || ancestors.length === 0) {
        return null;
    }

    const [first, ...rest] = ancestors;

    return (
        <Stack direction="row" spacing={1} maxWidth="100%">
            <Tooltip title={<ViewGroupStructure entries={[...first.parents.map((p) => p.name), first.elem.name]} />}>
                <Chip
                    icon={<Category />}
                    color="primary"
                    label={first.elem.name}
                    sx={{ overflow: "hidden", textOverflow: "hidden", maxWidth: "354px" }}
                />
            </Tooltip>
            {rest.length > 0 && (
                <Tooltip
                    title={
                        <Stack spacing={1} p={1}>
                            {rest.map((cat) => (
                                <Tooltip
                                    key={cat.elem.id}
                                    title={
                                        <ViewGroupStructure
                                            entries={[...cat.parents.map((p) => p.name), cat.elem.name]}
                                        />
                                    }
                                >
                                    <Chip key={cat.elem.id} icon={<Category />} color="primary" label={cat.elem.name} />
                                </Tooltip>
                            ))}
                        </Stack>
                    }
                >
                    <Chip color="primary" label={`+${rest.length}`} />
                </Tooltip>
            )}
        </Stack>
    );
};
