import { ShapeIcon } from "@ignite-analytics/components";
import { File, Trash } from "@ignite-analytics/icons";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { useSnackbar } from "@/contexts/useSnackbar";
import { captureMessage } from "@/errorHandling/errors";
import { CloseButton } from "../CloseButton";
import { DetailPage_GetContractQuery } from "@/pages/detail";

const DeleteDocumentModal_DeleteDocumentMutation = graphql(`
    mutation DeleteDocumentModal_DeleteDocumentMutation($input: DeleteDocumentRequest!) {
        deleteDocument(input: $input) {
            id
        }
    }
`);

const DeleteDocumentModal_DocumentFragment = graphql(`
    fragment DeleteDocumentModal_DocumentFragment on ContractDocument {
        id
        filename
        tag
        isPublic
    }
`);

interface DeleteDocumentModalProps {
    document: FragmentType<typeof DeleteDocumentModal_DocumentFragment>;
    open: boolean;
    onClose: () => void;
}

export const DeleteDocumentModal: React.FC<DeleteDocumentModalProps> = (props) => {
    const { postSnackbar } = useSnackbar();
    const document = getFragmentData(DeleteDocumentModal_DocumentFragment, props.document);

    const [deleteDocument, { loading }] = useMutation(DeleteDocumentModal_DeleteDocumentMutation, {
        refetchQueries: [DetailPage_GetContractQuery],
        awaitRefetchQueries: true,
        onCompleted: () => {
            postSnackbar({
                message: <FormattedMessage defaultMessage="Document deleted successfully" />,
                severity: "success",
            });
            track("Contract details: Document deleted", { documentId: document.id });
            props.onClose();
        },
        onError: (error) => {
            captureMessage("Failed to delete document", {
                tags: { documentId: document.id },
                extra: { error },
            });
            postSnackbar({
                message: <FormattedMessage defaultMessage="Failed to delete document" />,
                severity: "error",
            });
            props.onClose();
        },
    });

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="xs">
            <DialogContent>
                <CloseButton onClose={props.onClose} />

                <Stack gap={2} alignItems="center">
                    <Stack alignItems="center" gap={2.5}>
                        <ShapeIcon icon={<Trash />} color="warning" variant="default" size="medium" />
                        <Stack gap={1} alignItems="center">
                            <Typography variant="textLg" color="textPrimary" fontWeight={500}>
                                <FormattedMessage defaultMessage="Delete document" />
                            </Typography>
                            <Typography variant="textMd" color="textTextBody">
                                <FormattedMessage defaultMessage="Do you want to proceed and delete this document?" />
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack direction="row" gap={1} alignItems="center" px={2.5}>
                        {/* icon + filename */}
                        <ShapeIcon icon={<File />} />
                        <Typography variant="textMd" color="textPrimary" fontWeight={500}>
                            {document.filename}
                        </Typography>
                    </Stack>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button fullWidth onClick={props.onClose} color="secondary">
                    <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <LoadingButton
                    fullWidth
                    loading={loading}
                    onClick={() => {
                        deleteDocument({ variables: { input: { id: document.id } } });
                    }}
                    color="primary"
                >
                    <FormattedMessage defaultMessage="Delete document" />
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
