import { AccessLevel } from "../types";
import { ManageAccessDialog } from "./ManageAccessDialog";

interface ManageBulkContractAccessProps {
    contractIds: string[];
    isOpen: boolean;
    onClose: () => void;
    refetchContracts: () => void;
    defaultAccess: AccessLevel;
    customPostSuccessSnackbar?: () => void;
}

export const ManageBulkContractAccess = ({
    isOpen,
    contractIds,
    defaultAccess,
    onClose,
    refetchContracts,
    customPostSuccessSnackbar,
}: ManageBulkContractAccessProps) => {
    return (
        <ManageAccessDialog
            isOpen={isOpen}
            onClose={onClose}
            contractIds={contractIds}
            refetchContracts={refetchContracts}
            defaultAccess={defaultAccess}
            customPostSuccessSnackbar={customPostSuccessSnackbar}
        />
    );
};
