import { useLazyQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { graphql } from "@/generated";
import { PresetOption, TableFilter, TableQuickFilter } from "@/components/TableFilter/types";

const useGetFilterDefinitions_Suppliers = graphql(`
    query useGetFilterDefinitions_Suppliers($input: GetSuppliersInput!) {
        getSuppliers(input: $input) {
            suppliers {
                id
                name
            }
            total
        }
    }
`);

const useGetFilterDefinitions_Users = graphql(`
    query useGetFilterDefinitions_Users($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                id
                firstName
                lastName
            }
        }
    }
`);

/**
 * Hook for defining table filters with async data fetching
 *
 * This hook demonstrates how to set up different types of filters including:
 * - Preset filters with predefined options
 * - Async multi-select filters that fetch options from an API
 * - Date range filters
 * - Number range filters with currency support
 *
 * The filters defined here are processed by useTotalOverviewRows to create GraphQL query variables.
 *
 * @returns {TableFilter[]} Array of filter definitions
 *
 * @example
 * ```typescript
 * // In your component:
 * const filters = useGetFilterDefinitions();
 * const {
 *   appliedFilters,
 *   onApplyFilters,
 *   rows // filtered data based on applied filters
 * } = useTotalOverviewRows(searchTerm);
 *
 * return (
 *   <Filters
 *     filters={filters}
 *     appliedFilters={appliedFilters}
 *     onApplyFilters={onApplyFilters}
 *   />
 * );
 * ```
 *
 * To create your own filter definitions hook:
 * ```typescript
 * const useMyFilterDefinitions = () => {
 *   // For async filters, define your data fetching
 *   const getMyOptions = useCallback(async (search: string) => {
 *     // Fetch and return options
 *     return [{ id: "1", label: "Option 1" }];
 *   }, []);
 *
 *   // Define filters that will be processed by your data fetching hook
 *   return useMemo(() => [
 *     {
 *       id: "myFilter",
 *       label: "My Filter",
 *       type: "asyncMultiSelect",
 *       fetchOptions: getMyOptions,
 *       options: [],
 *       value: []
 *     }
 *   ], [getMyOptions]);
 * };
 *
 * // Process filters in your data fetching hook similar to useTotalOverviewRows:
 * const useMyDataFetching = () => {
 *   const [appliedFilters, setAppliedFilters] = useState<TableFilter[]>([]);
 *
 *   // Process filters into query variables
 *   const queryVariables = {
 *     myFilter: getAppliedMyFilter(appliedFilters),
 *     // ... other variables
 *   };
 *
 *   // Fetch data with processed filters
 *   const { data } = useQuery(MY_QUERY, { variables: queryVariables });
 *
 *   return { appliedFilters, onApplyFilters: setAppliedFilters, data };
 * };
 * ```
 */
export const useGetFilterDefinitions = (): { filters: TableFilter[]; quickFilters: TableQuickFilter[] } => {
    const { formatMessage } = useIntl();
    const [getSuppliersData] = useLazyQuery(useGetFilterDefinitions_Suppliers);

    const getSuppliers = useCallback(
        async (search: string) => {
            const { data } = await getSuppliersData({
                variables: { input: { nameSearch: search } },
            });
            return data?.getSuppliers.suppliers?.map((s) => ({ id: s.id, label: s.name })) ?? [];
        },
        [getSuppliersData]
    );

    const [getUsersData] = useLazyQuery(useGetFilterDefinitions_Users);

    const getUsers = useCallback(
        async (search: string) => {
            const { data } = await getUsersData({
                variables: { input: { terms: search, limit: 100 } },
            });
            return data?.getUsers.result?.map((u) => ({ id: u.id, label: `${u.firstName} ${u.lastName}` })) ?? [];
        },
        [getUsersData]
    );

    return useMemo(() => {
        const expiringValue: PresetOption = {
            value: "expiring",
            label: formatMessage({ defaultMessage: "Expiring next 6 months" }),
        };
        const recentlyExpiredValue: PresetOption = {
            value: "recently-expired",
            label: formatMessage({ defaultMessage: "Expired last 6 months" }),
        };
        const contractStatusFilter: TableFilter = {
            id: "status",
            label: "Contract status",
            type: "presetMultiSelect",
            options: [
                { value: "active", label: formatMessage({ defaultMessage: "All active" }) },
                expiringValue,
                recentlyExpiredValue,
                { value: "expired", label: formatMessage({ defaultMessage: "All expired" }) },
                { value: "not-started", label: formatMessage({ defaultMessage: "Not started" }) },
                { value: "no-end-date", label: formatMessage({ defaultMessage: "No end date added" }) },
            ],
            value: [],
        };

        const filters: TableFilter[] = [
            {
                id: "supplier",
                label: "Supplier",
                type: "asyncMultiSelect" as const,
                fetchOptions: getSuppliers,
                options: [],
                value: [],
                includeBlanks: false,
                includeBlanksLabel: formatMessage({ defaultMessage: "No supplier" }),
            },
            contractStatusFilter,
            {
                id: "endDate",
                label: "Expiration date",
                type: "dateRange",
                value: {},
            },
            {
                id: "responsible",
                label: "Contract owner",
                type: "asyncMultiSelect" as const,
                fetchOptions: getUsers,
                options: [],
                value: [],
                includeBlanks: false,
                includeBlanksLabel: formatMessage({ defaultMessage: "No contract owner" }),
            },
            {
                id: "spend",
                label: "Spend on contract",
                type: "numberRange",
                isCurrency: true,
                value: {},
            },
        ];

        const quickFilters: TableQuickFilter[] = [
            {
                label: formatMessage({ defaultMessage: "Expiring next 6 months" }),
                apply: () => [{ ...contractStatusFilter, value: [expiringValue] }],
            },
            {
                label: formatMessage({ defaultMessage: "Expired last 6 months" }),
                apply: () => [{ ...contractStatusFilter, value: [recentlyExpiredValue] }],
            },
        ];

        return { filters, quickFilters };
    }, [getSuppliers, getUsers, formatMessage]);
};
