import { Autocomplete, Chip, Stack, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { useSearchTagsV2 } from "@/hooks/useSearchTagsV2";
import { Blank } from "../Blank";
import { UpdateContractFormParams } from "@/types";

const TagList_ContractCustomFieldTagListFragment = graphql(`
    fragment TagList_ContractCustomFieldTagListFragment on ContractCustomFieldTagList {
        id
        tagList
        name
    }
`);

interface TagListProps {
    data: FragmentType<typeof TagList_ContractCustomFieldTagListFragment>;
    edit?: boolean;
}

export const TagList: React.FC<TagListProps> = (props) => {
    const data = getFragmentData(TagList_ContractCustomFieldTagListFragment, props.data);
    const { control } = useFormContext<UpdateContractFormParams>();
    const tags = useSearchTagsV2(data.id);
    const { formatMessage } = useIntl();

    if (props.edit) {
        return (
            <Controller
                name={`customfields/${data.id}`}
                control={control}
                defaultValue={{ id: data.id, tagList: data.tagList }}
                render={({ field }) => (
                    <Autocomplete
                        multiple
                        loading={tags.loading}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    placeholder={formatMessage({ defaultMessage: "Select multiple" })}
                                    InputProps={{ ...params.InputProps, size: "small" }}
                                />
                            );
                        }}
                        options={tags.options}
                        fullWidth
                        value={field.value.tagList ?? undefined}
                        onChange={(_, value) => {
                            field.onChange({
                                id: data.id,
                                tagList: value,
                            });
                        }}
                    />
                )}
            />
        );
    }

    if (!data.tagList || data.tagList.length === 0) {
        return <Blank />;
    }
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyItems="start"
            gap={2}
            sx={{ overflowX: "hidden" }}
            flexWrap="wrap"
        >
            {(data.tagList ?? []).map((tag) => (
                <Chip key={tag} label={tag} />
            ))}
        </Stack>
    );
};
