import { createRoute, SearchSchemaInput } from "@tanstack/react-router";

import dayjs from "dayjs";
import { GetContractsInput } from "@/generated/graphql";
import { ContractListSearchInput, contractListValidateSearchSchema } from "./schema";

import { queryClient } from "@/contexts";

import { supplierNameQueryKey } from "@/querykeys";

import { CONTRACT_STATUS_COLUMN_ID } from "@/pages/list/columns/generatedColumnIds";
import FilterWrappedContractList from "@/pages/list/FilterWrappedContractList";
import { contractsFromGraphql, getContractMetadata, loadContracts } from "@/pages/list/loaders";
import { rootRoute } from "../routes";

const getSortByKey = (sortBy: string) => {
    if (sortBy === CONTRACT_STATUS_COLUMN_ID) {
        return "endDate";
    }
    return sortBy;
};

export const listRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/list",
    component: FilterWrappedContractList,
    validateSearch: (input: ContractListSearchInput & SearchSchemaInput) =>
        contractListValidateSearchSchema.parse(input),
    loaderDeps: ({ search }) => search,
    loader: async ({ context, deps: { dmsFilter, status, perPage, page, sortBy, sortOrder, searchTerm, group } }) => {
        const { userId } = context.user;
        const filters = dmsFilter ?? [];
        if (page > 0) {
            // eslint-disable-next-line no-param-reassign
            page -= 1;
        }

        // Fetch from graphql. This should not be used in production, but can be toggled on during development
        // by setting the value to true. The table itself is also rendered differently because the data shape is different.
        // If we don't fetch from graphql, the "v2" field is null.
        const experimentalFetchFromGraphql = false;
        if (experimentalFetchFromGraphql) {
            const columns = await getContractMetadata();
            const filter: GetContractsInput = {};
            if (searchTerm !== undefined && searchTerm.length > 0) {
                filter.search = searchTerm;
            }
            if (group === "currentUser") {
                filter.responsibles = [userId];
            }
            if (status === "active") {
                // filter.endDateAfter = dayjs().format("YYYY-MM-DD");
                // filter.startDateBefore = dayjs().format("YYYY-MM-DD");

                filter.where = {
                    endDate: { gt: dayjs().format("YYYY-MM-DD") },
                    startDate: { lt: dayjs().format("YYYY-MM-DD") },
                };
            } else if (status === "expiring-soon") {
                filter.where = {
                    endDate: { lt: dayjs().add(6, "months").format("YYYY-MM-DD") },
                };
            } else if (status === "renewing-soon") {
                filter.where = {
                    renewalDate: {
                        lt: dayjs().add(6, "months").format("YYYY-MM-DD"),
                        gt: dayjs().format("YYYY-MM-DD"),
                    },
                };
            }
            const data = await contractsFromGraphql(filter);
            return {
                newTable: experimentalFetchFromGraphql,
                contracts: null,
                columns,
                v2: { total: data.length, data },
            };
        }

        const { contracts, unmapped, columns } = await queryClient.ensureQueryData({
            queryKey: [
                context.user.tenant,
                "contracts",
                { filters, status, perPage, page, sortBy, sortOrder, searchTerm, group },
            ],
            queryFn: () =>
                loadContracts({
                    status,
                    pagination: { page, perPage },
                    sort: sortBy && sortOrder ? { sortBy: getSortByKey(sortBy), sortOrder } : undefined,
                    filters,
                    searchTerm,
                }),
        });
        unmapped.forEach((row) => {
            if (row.supplierId) {
                const { value, label } = row.supplierId;
                if (value) {
                    queryClient.setQueryData(supplierNameQueryKey(context.user.tenant, value), label);
                }
            }
        });

        return { contracts, columns, v2: null };
    },
});
