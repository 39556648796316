import { Typography } from "@mui/material";
import { getLocale } from "@ignite-analytics/locale";
import * as Sentry from "@sentry/react";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { Blank } from "../Blank";

const MonetaryAmount_ContractCustomFieldMonetaryAmountFragment = graphql(`
    fragment MonetaryAmount_ContractCustomFieldMonetaryAmountFragment on ContractCustomFieldMonetaryAmount {
        id
        name
        monetaryAmount {
            currency
            amount
            date
        }
    }
`);

interface MonetaryAmountProps {
    data: FragmentType<typeof MonetaryAmount_ContractCustomFieldMonetaryAmountFragment>;
    edit?: boolean;
}

export const MonetaryAmount: React.FC<MonetaryAmountProps> = (props) => {
    const data = getFragmentData(MonetaryAmount_ContractCustomFieldMonetaryAmountFragment, props.data);

    if (props.edit) {
        return <Blank />;
    }

    if (!data.monetaryAmount) {
        return <Blank />;
    }

    // we really expect currency and amount to be set, so we'll fallback to showing blank if that's
    // not the case.
    if (!data.monetaryAmount.currency || !data.monetaryAmount.amount) {
        Sentry.captureMessage("MonetaryAmount: currency or amount is missing", { extra: { data } });
        return <Blank />;
    }
    const locale = getLocale();
    const formatter = new Intl.NumberFormat(locale, {
        currencyDisplay: "code",
        style: "currency",
        currency: data.monetaryAmount.currency,
    });
    return <Typography variant="textMd">{formatter.format(data.monetaryAmount.amount)}</Typography>;
};
