import { InformationCircle } from "@ignite-analytics/icons";
import { Tooltip } from "@mui/material";

interface InformationTooltipProps {
    content: React.ReactNode;
    placement?: "top" | "bottom" | "left" | "right";
}

export const InformationTooltip: React.FC<InformationTooltipProps> = ({ content, placement = "top" }) => {
    return (
        <Tooltip size="xlarge" title={content} placement={placement}>
            <InformationCircle color="inherit" sx={{ cursor: "help" }} />
        </Tooltip>
    );
};
