import { Document } from "../../pages/detail/components/ContractSidebar/DocumentsCard/components/ContractDocuments/hooks";
import { MicrosoftDocumentViewer } from "./MicrosoftDocumentViewer";
import { PdfDocumentViewer } from "./PdfDocumentViewer";

interface DocumentViewerProps {
    document: Document;
}

export const DocumentViewer: React.FC<DocumentViewerProps> = ({ document }) => {
    if (document.filename?.endsWith(".pdf")) {
        if (!document.url) {
            return <div>No URL found</div>;
        }
        return <PdfDocumentViewer url={document.url} />;
    }

    if (
        document.filename?.endsWith(".docx") ||
        document.filename?.endsWith(".doc") ||
        document.filename?.endsWith(".xlsx") ||
        document.filename?.endsWith(".xls")
    ) {
        if (!document.url) {
            return <div>No URL found</div>;
        }
        return <MicrosoftDocumentViewer url={document.url} />;
    }

    return <div>Unsupported file type</div>;
};
