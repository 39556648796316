import { ApolloProvider } from "@apollo/client";
import { getDesignTokens } from "@ignite-analytics/theme";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LicenseInfo } from "@mui/x-license-pro";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";

import { env } from "@/env";
import { ErrorHandlerProvider } from "@/errorHandling/ErrorHandlerContext";
import { dayJsLocale } from "@/formatDate";
import { createGraphqlClient } from "@/graphqlClient";
import { useOnUnhandledRejectionReporter } from "@/hooks/useOnUnhandledRejectionReporter";
import { FragmentInitialProps } from "@/types";
import { CompanyCurrencyProvider } from "./CompanyCurrencyContext";
import { FeatureContext } from "./FeatureContext";
import { IntlContext } from "./IntlContext";
import { PermissionHandlerProvider } from "./PermissionsContext";
import { SnackbarProvider } from "./useSnackbar/provider";
import { useUserOrThrow } from "./useUser";

// TODO: apolloClient should be accessed via useApolloClient. But this is used in non-hook files
// eslint-disable-next-line import/no-mutable-exports
export let apolloClient = createGraphqlClient(env.REACT_APP_GRAPHQL_ROUTER_URL, null);

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1,
            enabled: false,
            gcTime: 1000 * 60 * 15,
        },
    },
});

LicenseInfo.setLicenseKey(env.REACT_APP_MUI_TOKEN);

type Props = {
    children: React.ReactNode;
    theme: FragmentInitialProps["theme"] & string;
    locale: FragmentInitialProps["locale"] & string;
};

export const ContextProviders: React.FC<Props> = ({ locale, theme, children }) => {
    useOnUnhandledRejectionReporter();
    const { lang } = dayJsLocale();

    // Assumes that the user is always available
    const user = useUserOrThrow();

    // update the client to include the context
    apolloClient = createGraphqlClient(env.REACT_APP_GRAPHQL_ROUTER_URL, user.tenant);

    return (
        <ThemeProvider theme={createTheme(getDesignTokens(theme, "light"))}>
            <IntlContext locale={locale}>
                <SnackbarProvider>
                    <ErrorHandlerProvider>
                        <ApolloProvider client={apolloClient}>
                            <QueryClientProvider client={queryClient}>
                                <CssBaseline />
                                <FeatureContext>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang}>
                                        <CompanyCurrencyProvider>
                                            <PermissionHandlerProvider enabled>{children}</PermissionHandlerProvider>
                                        </CompanyCurrencyProvider>
                                    </LocalizationProvider>
                                </FeatureContext>
                            </QueryClientProvider>
                        </ApolloProvider>
                    </ErrorHandlerProvider>
                </SnackbarProvider>
            </IntlContext>
        </ThemeProvider>
    );
};
