import { ShapeIcon } from "@ignite-analytics/components";
import { ExclamationTriangleSolid, X } from "@ignite-analytics/icons";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Row } from "../../contractListItem";

type Props = {
    open: boolean;
    onConfirm: (title: string, offsetMonths: number) => void;
    onCancel: () => void;
    selectedRows: Row[];
};

export const BulkCreateReminderDialog: React.FC<Props> = ({ selectedRows, open, onCancel, onConfirm }) => {
    const [offsetMonths, setOffsetMonths] = useState<number>(3);
    const [title, setTitle] = useState("");

    const { formatMessage } = useIntl();

    const handleConfirm = () => {
        onConfirm(title, offsetMonths);
    };

    const warnings = useMemo(() => {
        const numContractsWithoutEndDate = selectedRows.filter((row) => {
            const endDateField = row.fields.find((field) => field.dataType === "END_DATE");
            if (!endDateField) return false;
            return endDateField.data === null;
        }).length;
        const numContractsWithEndDateBeforeOffset = selectedRows.filter((row) => {
            const endDateField = row.fields.find((field) => field.dataType === "END_DATE");
            if (!endDateField?.data) return false;

            const reminderDate = new Date(endDateField.data);
            reminderDate.setMonth(reminderDate.getMonth() - offsetMonths);
            return reminderDate < new Date();
        }).length;

        return {
            numContractsWithoutEndDate,
            numContractsWithEndDateBeforeOffset,
        };
    }, [offsetMonths, selectedRows]);

    const showWarnings = warnings.numContractsWithoutEndDate > 0 || warnings.numContractsWithEndDateBeforeOffset > 0;

    const numContracts = selectedRows.length;
    return (
        <Dialog open={open} onClose={onCancel} maxWidth="sm">
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <Stack gap={1}>
                        <Typography variant="textXl">
                            <FormattedMessage
                                defaultMessage="Create alert for {numContracts} contracts"
                                values={{ numContracts }}
                            />
                        </Typography>
                        <Typography variant="textSm" color="textTextBody">
                            <FormattedMessage defaultMessage="Alerts will be sent to the contract responsible(s), when triggered, by e-mail and in-app notifications." />
                        </Typography>
                    </Stack>
                    <IconButton onClick={onCancel}>
                        <X />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                {showWarnings && (
                    <Stack direction="row" pt={3}>
                        <Alert severity="warning" icon={false}>
                            <Stack direction="row" alignItems="center">
                                <ShapeIcon color="warning" size="large">
                                    <ExclamationTriangleSolid fontSize="inherit" />
                                </ShapeIcon>
                                <Stack>
                                    <Typography variant="textSm" fontWeight={500}>
                                        <FormattedMessage defaultMessage=" No alerts will be created for some contracts" />
                                    </Typography>
                                    <ul style={{ paddingLeft: "24px" }}>
                                        {warnings.numContractsWithoutEndDate > 0 && (
                                            <li>
                                                <Typography variant="textSm" fontWeight={500}>
                                                    <FormattedMessage
                                                        defaultMessage="{numContractsWithoutEndDate} of the {numContracts} contracts do not have an end date specified."
                                                        values={{
                                                            numContractsWithoutEndDate:
                                                                warnings.numContractsWithoutEndDate,
                                                            numContracts,
                                                        }}
                                                    />
                                                </Typography>
                                            </li>
                                        )}
                                        {warnings.numContractsWithEndDateBeforeOffset > 0 && (
                                            <li>
                                                <Typography variant="textSm" fontWeight={500}>
                                                    <FormattedMessage
                                                        defaultMessage="{numContractsWithEndDateBeforeOffset} of the {numContracts} contracts has it’s end date before “{offsetMonths} months” notice period"
                                                        values={{
                                                            numContractsWithEndDateBeforeOffset:
                                                                warnings.numContractsWithEndDateBeforeOffset,
                                                            numContracts,
                                                            offsetMonths,
                                                        }}
                                                    />
                                                </Typography>
                                            </li>
                                        )}
                                    </ul>
                                </Stack>
                            </Stack>
                        </Alert>
                    </Stack>
                )}
                <Stack direction="row" pt={3}>
                    <TextField
                        label={formatMessage({ defaultMessage: "Title for alert" })}
                        placeholder={formatMessage({ defaultMessage: "Contract is expiring in 6 months" })}
                        required
                        variant="outlined"
                        fullWidth
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value);
                        }}
                    />
                </Stack>
                <Stack pt={4}>
                    <Typography variant="textSm">
                        <FormattedMessage defaultMessage="When should we remind you?" />
                    </Typography>
                    <Stack direction="row" alignItems="center" gap={1} py={1}>
                        <TextField
                            type="number"
                            sx={{
                                width: "70px",
                                // Always show arrows in input field
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                    opacity: 1,
                                },
                            }}
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                },
                            }}
                            value={offsetMonths}
                            onChange={(e) => {
                                const value = parseInt(e.target.value, 10);
                                if (Number.isNaN(value)) return;
                                setOffsetMonths(value);
                            }}
                        />
                        <Typography variant="textSm">
                            <FormattedMessage defaultMessage="month(s) before the contract's end date" />
                        </Typography>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" gap={1}>
                    <Button onClick={onCancel} color="secondary">
                        <FormattedMessage defaultMessage="Cancel" />
                    </Button>
                    <Button onClick={handleConfirm} disabled={title.length === 0} color="primary">
                        <FormattedMessage defaultMessage="Create alerts" />
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
