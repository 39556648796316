import React from "react";
import { SupplierChip } from "@/components/SuppliersChip";

type Props = {
    name: string;
    id: string;
    canOpen: boolean;
};

export const SupplierCustomField: React.FC<Props> = ({ name, id, canOpen }) => {
    return <SupplierChip name={name} id={id} size="small" disabled={!canOpen} hideIcon />;
};
