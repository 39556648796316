import dayjs from "dayjs";

/**
 * "Normalize" a date string by converting to UTC and setting the time to midnight.
 * */
export const normalizeDateString = (datestring: string | null | undefined | dayjs.Dayjs): dayjs.Dayjs | undefined => {
    if (!datestring) return undefined;
    // NOTE: Setting the time to midnight is not necessary, but makes it very clear that it is a date.
    return dayjs.utc(datestring).set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0);
};
