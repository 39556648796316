import { TreeItem2 } from "@mui/x-tree-view";

import { styled } from "@mui/material";

import { ComponentProps } from "react";
import HighlightedLabel from "@/components/TreePicker/components/HighlightedLabel";

const CustomTreeItem = styled(TreeItem2)(() => ({
    "& .MuiTreeItem-content": {
        display: "flex",
        alignItems: "center",
    },
    "& .MuiTreeItem-label": {
        flexGrow: 1,
    },
    "& .MuiTreeItem-iconContainer": {
        order: 1,
    },
}));

interface TreeItemProps extends ComponentProps<typeof TreeItem2> {
    searchTerm: string;
    label: string;
}

export const TreeItem: React.FC<TreeItemProps> = ({ itemId, label, searchTerm, ...props }) => {
    return (
        <CustomTreeItem itemId={itemId} label={<HighlightedLabel label={label} searchTerm={searchTerm} />} {...props} />
    );
};
