import { useMutation } from "@apollo/client";
import { Trash } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { IconButton, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useIntl } from "react-intl";
import { SidebarCard } from "./SidebarCard";

import { AddAlertPopup } from "@/components/AddAlertPopup";
import { useSnackbar } from "@/contexts/useSnackbar";
import { useErrorHandler } from "@/errorHandling/ErrorHandlerContext";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { detailRoute } from "@/routes/detail/$id";
import { DetailPage_GetContractQuery } from "../..";

const AlertsCard_ReminderFragment = graphql(`
    fragment AlertsCard_ReminderFragment on ContractReminder {
        id
        message
        scheduledOn
        triggeredAt
    }
`);

const AlertsCard_ContractFragment = graphql(`
    fragment AlertsCard_ContractFragment on Contract {
        id
        endDate
        reminders {
            ...AlertsCard_ReminderFragment
        }
    }
`);

const CreateAlertMutation = graphql(`
    mutation CreateAlert($input: CreateContractReminderRequest!) {
        createContractReminder(input: $input) {
            reminder {
                ...AlertsCard_ReminderFragment
            }
        }
    }
`);

const DeleteAlertMutation = graphql(`
    mutation DeleteAlert($input: DeleteContractReminderRequest!) {
        deleteContractReminder(input: $input) {
            id
        }
    }
`);

interface AlertsCardProps {
    editMode: boolean;
    contract: FragmentType<typeof AlertsCard_ContractFragment>;
}

export const AlertsCard: React.FC<AlertsCardProps> = (props) => {
    const { id } = detailRoute.useParams();
    const [alertModalOpen, setAlertModalOpen] = useState(false);

    const { formatMessage } = useIntl();
    const { postSnackbar } = useSnackbar();
    const { handleError } = useErrorHandler();

    const contract = getFragmentData(AlertsCard_ContractFragment, props.contract);
    const alerts = getFragmentData(AlertsCard_ReminderFragment, contract.reminders);

    const [createAlert] = useMutation(CreateAlertMutation, {
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: DetailPage_GetContractQuery,
                variables: {
                    id,
                },
            },
        ],
        onCompleted: () => {
            postSnackbar({
                message: formatMessage({ defaultMessage: "Alert created" }),
                severity: "success",
            });
        },
        onError: handleError,
    });

    const [deleteAlert] = useMutation(DeleteAlertMutation, {
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: DetailPage_GetContractQuery,
                variables: {
                    id,
                },
            },
        ],
        onCompleted: () => {
            postSnackbar({
                message: formatMessage({ defaultMessage: "Alert deleted" }),
                severity: "success",
            });
        },
        onError: handleError,
    });

    const handleCreate = async (message: string, scheduledOn: string) => {
        const monthsFromExpiration = contract.endDate
            ? dayjs.utc(scheduledOn).diff(dayjs.utc(contract.endDate), "month")
            : undefined;
        track("Contracts: Add alert", { scheduledOn, monthsFromExpiration });
        await createAlert({
            variables: {
                input: { message, scheduledOn, contractID: contract.id },
            },
        });
        setAlertModalOpen(false);
    };

    const handleDelete = (id: string) => {
        track("Contracts: Delete alert");
        deleteAlert({
            variables: {
                input: { reminderID: id, contractID: contract.id },
            },
        });
    };

    return (
        <SidebarCard
            edit={props.editMode}
            label={formatMessage({ defaultMessage: "Alerts" })}
            editLabel={formatMessage({ defaultMessage: "Add new" })}
            onClickEdit={() => {
                setAlertModalOpen(true);
            }}
        >
            <Stack gap={2.5}>
                {alerts.map((alert) => (
                    <Stack direction="row" alignItems="center" key={alert.id}>
                        <Stack>
                            <Typography variant="textSm" fontWeight={500}>
                                {alert.message}
                            </Typography>
                            <Typography variant="textSm" color="GrayText" fontWeight={400}>
                                {dayjs(alert.scheduledOn).format("DD MMM YYYY")}
                            </Typography>
                        </Stack>
                        <IconButton
                            sx={{ ml: "auto", visibility: props.editMode ? "visible" : "hidden" }}
                            onClick={() => handleDelete(alert.id)}
                        >
                            <Trash />
                        </IconButton>
                    </Stack>
                ))}
                <AddAlertPopup
                    open={alertModalOpen}
                    onClose={() => {
                        setAlertModalOpen(false);
                    }}
                    onSubmit={handleCreate}
                />
            </Stack>
        </SidebarCard>
    );
};
