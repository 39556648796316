import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogContent, Stack, TextField, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

type Props = {
    open: boolean;
    onClose: () => void;
    onSubmit: (message: string, scheduledOn: string) => Promise<void>;
};
export const AddAlertPopup = ({ open, onClose, onSubmit }: Props) => {
    const [name, setName] = useState<string>("");
    const [scheduledOn, setScheduledOn] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogContent>
                <Stack gap={3} width="485px">
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Create new alert" />
                    </Typography>
                    <Typography variant="textSm">
                        <FormattedMessage defaultMessage="Alerts will be sent to the contract responsible(s), when triggered, by e-mail and in-app notifications." />
                    </Typography>

                    <TextField
                        label={<FormattedMessage defaultMessage="Alert name" />}
                        required
                        variant="outlined"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />

                    <DesktopDatePicker
                        disablePast
                        label={<FormattedMessage defaultMessage="Alert date" />}
                        slotProps={{ textField: { required: true } }}
                        onChange={(value) => {
                            setScheduledOn((value as dayjs.Dayjs).format());
                        }}
                    />

                    <Stack gap={1.5} direction="row" justifyContent="flex-end">
                        <Button onClick={() => onClose()} color="secondary" size="small">
                            <FormattedMessage defaultMessage="Cancel" />
                        </Button>
                        <LoadingButton
                            loading={loading}
                            disabled={!name || !scheduledOn}
                            size="small"
                            onClick={() => {
                                setLoading(true);
                                onSubmit(name, scheduledOn).then(() => {
                                    setLoading(false);
                                    onClose();
                                });
                            }}
                        >
                            <FormattedMessage defaultMessage="Create" />
                        </LoadingButton>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
