import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import { FormattedMessage } from "react-intl";
import { EllipticTypography } from "@/components/EllipticTypography";
import { GroupChip } from "@/components/TreePicker/components/GroupChip";
import { graphql } from "@/generated";
import { TreeSelect } from "../../../../components/TreePicker/components/TreeSelect";
import { LeafNode } from "../../../../components/TreePicker/types";

const SpendCategoryPicker_GetAllSpendCategories = graphql(`
    query SpendCategoryPicker_GetAllSpendCategories {
        getAllSpendCategories {
            categories {
                id
                level
                name
                parentId
            }
        }
    }
`);

interface Props {
    value: string[];
    onChange: (value: string[]) => void;
}

export const SpendCategoryPicker: React.FC<Props> = ({ value, onChange }) => {
    const { data } = useQuery(SpendCategoryPicker_GetAllSpendCategories);

    const spendCategories: LeafNode[] = useMemo(() => {
        if (!data) {
            return [];
        }

        return data.getAllSpendCategories.categories.map((spendCategory) => {
            return {
                id: spendCategory.id,
                level: spendCategory.level,
                label: spendCategory.name,
                parentId: spendCategory.parentId,
                children: [],
            };
        });
    }, [data]);

    const buttonContent = useMemo(() => {
        if (!value.length) {
            return (
                <EllipticTypography variant="textMd" width="100%" textAlign="start">
                    <FormattedMessage defaultMessage="Select categories" />
                </EllipticTypography>
            );
        }

        const selectedCategories = data?.getAllSpendCategories.categories
            .filter((spendCategory) => value.includes(spendCategory.id))
            .map((spendCategory) => {
                return {
                    id: spendCategory.id,
                    name: spendCategory.name,
                };
            });

        return <GroupChip groups={selectedCategories} />;
    }, [data, value]);

    return (
        <TreeSelect
            multiSelect
            items={spendCategories}
            value={value}
            onChange={onChange}
            buttonContent={buttonContent}
        />
    );
};
