import { createRoute } from "@tanstack/react-router";

import { queryClient, apolloClient } from "@/contexts";
import { defaultLayoutQueryKey } from "@/querykeys";

import { FullWidthSpinner } from "@/components/FullWidthSpinner";
import { DetailPage, DetailPage_GetContractQuery } from "@/pages/detail";
import { getLayoutByIdOrDefault } from "@/pages/list/loaders";
import { rootRoute } from "@/routes/routes";

export const detailRoute = createRoute({
    getParentRoute: () => rootRoute,
    pendingComponent: FullWidthSpinner,
    pendingMs: 0,
    path: "/detail/$id",
    component: DetailPage,
    loader: async ({ context, params }) => {
        const layout = await queryClient.ensureQueryData({
            queryKey: defaultLayoutQueryKey(context.user.tenant),
            queryFn: () => getLayoutByIdOrDefault("default"),
        });

        // Prefetch the contract data on hover. This is something we've done for a while
        // already, so to lose that snapiness, we continue to prefetch here. When
        // a user hovers over a row on the contract table, we prefetch
        //
        // More details:
        // https://www.apollographql.com/docs/react/performance/performance#prefetching-data
        apolloClient.query({
            query: DetailPage_GetContractQuery,
            variables: { id: params.id },
        });
        return { layout };
    },
});

// Nested subpath on detail route allowing the data list modal
export const detailDataListModalSubRoute = createRoute({
    ...detailRoute.options,
    getParentRoute: () => detailRoute,
    path: "/data-list-modal/spend",
});
