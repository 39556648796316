import axios, { AxiosRequestConfig } from "axios";
import { v4 } from "uuid";
import { env } from "./env";
import { LANGUAGE_ISO_639, TENANT } from "./localStorageKeys";

const applyRequestIdMiddleware = async (config: AxiosRequestConfig<any>) => {
    const id = v4();
    const headers: Record<string, string> = config.headers ?? {};
    headers["x-request-id"] = id;
    return {
        ...config,
        headers,
    };
};

const createClient = (config: AxiosRequestConfig) => {
    const client = axios.create(config);
    client.interceptors.request.use(applyRequestIdMiddleware);
    return client;
};

const makeCommonHeaders: () => Record<string, string> = () => ({
    "Accept-Language": localStorage.getItem(LANGUAGE_ISO_639) ?? "en-US",
    "Content-Type": "application/json",
});

const makeHeadersWithTenant: () => Record<string, string> = () => ({
    ...makeCommonHeaders(),
    "x-tenant-id": localStorage.getItem(TENANT) ?? "",
});

export const makeContractsApiHttp = () =>
    createClient({
        baseURL: env.REACT_APP_CONTRACTS_BASE_URL,
        withCredentials: true,
        headers: makeHeadersWithTenant(),
    });
export const makeGraphqlRouter = () =>
    createClient({
        baseURL: env.REACT_APP_GRAPHQL_ROUTER_URL,
        withCredentials: true,
        headers: makeHeadersWithTenant(),
    });
