import { Button, Stack } from "@mui/material";
import { GridFooterContainer, GridSlotProps, useGridApiContext } from "@mui/x-data-grid-pro";
import { FormattedMessage } from "react-intl";

declare module "@mui/x-data-grid-pro" {
    interface FooterPropsOverrides {
        onLoadMore?: () => void;
        totalRowCount?: number;
    }
}

const GridFooter = (props: GridSlotProps["footer"]): React.ReactNode => {
    const apiRef = useGridApiContext();
    let hasNextPage = false;
    if (props.totalRowCount) {
        hasNextPage = apiRef.current.getRowsCount() < props.totalRowCount;
    }
    if (!hasNextPage) {
        return null;
    }
    return (
        <GridFooterContainer>
            <Stack justifyContent="center" alignItems="center" width="100%">
                <Button color="linkPrimary" onClick={() => props.onLoadMore?.()}>
                    <FormattedMessage defaultMessage="Load more" />
                </Button>
            </Stack>
        </GridFooterContainer>
    );
};

export { GridFooter };
