import { Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { SupplierChip } from "@/components/SuppliersChip";
import { UserAvatars } from "@/components/UserAvatars";
import { useCompanyCurrency } from "@/contexts/CompanyCurrencyContext";
import { totalOverviewRoute } from "@/routes/total-overview";
import { StatusCell } from "./StatusCell";
import { Row } from "./types";
import { TitleCustomField } from "@/components/ContractsTablePro/components/CustomField/TitleCustomField";

export const useColumns = () => {
    const { formatMessage } = useIntl();
    const currency = useCompanyCurrency();
    return useMemo<GridColDef<Row>[]>(
        () => [
            {
                field: "title",
                headerName: formatMessage({ defaultMessage: "Contract title" }),
                flex: 1,
                renderCell: (params) => {
                    return (
                        <TitleCustomField
                            contractId={params.row.id ?? ""}
                            title={params.row.title}
                            from={totalOverviewRoute.fullPath}
                            endDate={params.row.endDate}
                        />
                    );
                },
            },
            {
                field: "supplier",
                headerName: formatMessage({ defaultMessage: "Supplier" }),
                sortable: false,
                flex: 1,
                renderCell: (params) => {
                    if (!params.row.supplier)
                        return (
                            <Typography variant="textSm" color="textDisabled">
                                <FormattedMessage defaultMessage="No supplier" />
                            </Typography>
                        );
                    return (
                        <SupplierChip
                            hideIcon
                            name={params.row.supplier?.name ?? ""}
                            id={params.row.supplier?.id ?? ""}
                        />
                    );
                },
            },
            {
                field: "status",
                headerName: formatMessage({ defaultMessage: "Contract status" }),
                flex: 1,
                renderCell: (params) => {
                    return <StatusCell endDate={params.row.endDate ?? null} />;
                },
            },
            {
                field: "endDate",
                headerName: formatMessage({ defaultMessage: "Expiration date" }),
                flex: 1,
                renderCell: (params) => {
                    if (!params.row.endDate)
                        return (
                            <Stack direction="row" alignItems="center" height="100%">
                                <Typography variant="textSm" color="textTextDisabled">
                                    <FormattedMessage defaultMessage="Not set" />
                                </Typography>
                            </Stack>
                        );
                    return (
                        <Stack direction="row" alignItems="center" height="100%">
                            <Typography variant="textSm" color="textPrimary">
                                <FormattedDate value={params.row.endDate} day="2-digit" month="short" year="numeric" />
                            </Typography>
                        </Stack>
                    );
                },
            },
            {
                field: "responsible",
                sortable: false,
                headerName: formatMessage({ defaultMessage: "Contract owner" }),
                flex: 1,
                renderCell: (params) => {
                    return (
                        <Stack direction="row" alignItems="center" height="100%">
                            <Typography>
                                <UserAvatars users={params.row.responsible} maxAvatars={5} />
                            </Typography>
                        </Stack>
                    );
                },
            },
            {
                field: "spend",
                headerName: formatMessage({ defaultMessage: "Spend on contract" }),
                flex: 1,
                renderCell: (params) => {
                    return (
                        <Stack direction="row" alignItems="center" height="100%">
                            {params.row.totalSpend ? (
                                <Typography variant="textSm" color="textPrimary">
                                    {currency.formatInteger(params.row.totalSpend)}
                                </Typography>
                            ) : (
                                <Typography variant="textSm" color="textDisabled">
                                    <FormattedMessage defaultMessage="No data found" />
                                </Typography>
                            )}
                        </Stack>
                    );
                },
            },
        ],
        [formatMessage, currency]
    );
};
