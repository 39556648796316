import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { GridSortModel } from "@mui/x-data-grid-pro";
import { captureException } from "@sentry/react";
import { useCallback, useMemo, useState } from "react";
import { track } from "@ignite-analytics/track";
import {
    AsyncMultiSelectFilter,
    DateRangeFilter,
    NumberRangeFilter,
    PresetMultiSelectFilter,
    TableFilter,
} from "@/components/TableFilter/types";
import { getFragmentData } from "@/generated/fragment-masking";
import { graphql } from "@/generated/gql";
import { ContractSort, ContractSortKey, ContractStatus as ContractStatusGraphql } from "@/generated/graphql";
import { hasValue } from "@/helpers/hasValue";
import { Responsible, responsibleSchema, Row, Supplier, supplierSchema } from "./types";

const TotalOverview_ContractFragment = graphql(`
    fragment TotalOverview_Contract on Contract {
        id
        title
        startDate
        endDate
        totalSpend
        supplier {
            id
            name
        }
        responsibles {
            id
            firstName
            lastName
        }
    }
`);

const TotalOverview_GetContracts = graphql(`
    query TotalOverview_GetContracts($input: GetContractsInput) {
        getContracts(input: $input) {
            data {
                ...TotalOverview_Contract
            }
            total
        }
    }
`);

const threeMonthsFromNow = new Date();
threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 3);

const getSortModel = (sortModel: GridSortModel): ContractSort[] => {
    if (sortModel.length === 0) return [];
    let sortKey: ContractSortKey | undefined;

    switch (sortModel[0].field) {
        case "title":
            sortKey = "TITLE";
            break;
        case "startDate":
            sortKey = "START_DATE";
            break;
        case "endDate":
            sortKey = "END_DATE";
            break;
        case "status":
            sortKey = "END_DATE";
            break;
        case "spend":
            sortKey = "TOTAL_SPEND";
            break;
        default:
            sortKey = undefined;
    }
    const sorts: ContractSort[] = sortKey
        ? [{ key: sortKey, direction: sortModel[0].sort === "asc" ? "ASC" : "DESC" }]
        : [];
    // Always add a final sort on ID, to ensure the order is unique. This is important for pagination
    sorts.push({ key: "ID", direction: "ASC" });

    return sorts;
};

const getAppliedResponsiblesFilter = (appliedFilters: TableFilter[]) => {
    const appliedResponsibleFilter = appliedFilters.find((filter) => filter.id === "responsible");

    if (appliedResponsibleFilter && appliedResponsibleFilter.type === "asyncMultiSelect") {
        if (appliedResponsibleFilter.value.length === 0 && !appliedResponsibleFilter.includeBlanks) {
            return undefined;
        }

        const anyOf = appliedResponsibleFilter.value.length
            ? appliedResponsibleFilter.value.map((v) => v.id)
            : undefined;
        const isNull = appliedResponsibleFilter.includeBlanks || undefined;

        return {
            responsible: {
                anyOf,
                isNull,
            },
        };
    }
    return undefined;
};

const getAppliedStatusFilter = (appliedFilters: TableFilter[]) => {
    const appliedStatusFilter: PresetMultiSelectFilter | undefined = appliedFilters.find(
        (filter) => filter.id === "status" && filter.type === "presetMultiSelect"
    ) as PresetMultiSelectFilter | undefined;

    if (!appliedStatusFilter || !appliedStatusFilter.value) {
        return {};
    }

    const anyOf: ContractStatusGraphql[] = appliedStatusFilter.value
        .map<ContractStatusGraphql | undefined>(({ value }) => {
            switch (value) {
                case "active":
                    return "ACTIVE";
                case "expiring":
                    return "EXPIRING_SOON";
                case "recently-expired":
                    return "RECENTLY_EXPIRED";
                case "not-started":
                    return "NOT_STARTED";
                case "expired":
                    return "EXPIRED";
                case "no-end-date":
                    return "NO_END_DATE";
                default:
                    captureException(`Unknown status filter value: ${value}`, { tags: { app: "contracts-app" } });
                    return undefined;
            }
        })
        .filter(hasValue);

    return anyOf.length > 0 ? { status: { anyOf } } : {};
};

const getAppliedEndDateFilter = (appliedFilters: TableFilter[]) => {
    const appliedEndDateFilter: DateRangeFilter | undefined = appliedFilters.find(
        (filter) => filter.id === "endDate" && filter.type === "dateRange"
    ) as DateRangeFilter | undefined;

    if (!appliedEndDateFilter) {
        return {};
    }

    return {
        endDate: {
            gt: appliedEndDateFilter.value.from,
            lt: appliedEndDateFilter.value.to,
        },
    };
};

const getAppliedTotalSpendFilter = (appliedFilters: TableFilter[]) => {
    const appliedTotalSpendFilter: NumberRangeFilter | undefined = appliedFilters.find(
        (filter) => filter.id === "spend" && filter.type === "numberRange"
    ) as NumberRangeFilter | undefined;

    if (!appliedTotalSpendFilter) {
        return {};
    }

    return {
        spend: {
            gt: appliedTotalSpendFilter.value.min,
            lt: appliedTotalSpendFilter.value.max,
        },
    };
};

const getAppliedSupplierFilter = (appliedFilters: TableFilter[]) => {
    const appliedSupplierFilter: AsyncMultiSelectFilter | undefined = appliedFilters.find(
        (filter) => filter.id === "supplier" && filter.type === "asyncMultiSelect"
    ) as AsyncMultiSelectFilter | undefined;

    if (!appliedSupplierFilter) {
        return undefined;
    }

    if (appliedSupplierFilter.value.length === 0 && !appliedSupplierFilter.includeBlanks) {
        return undefined;
    }

    return {
        supplier: {
            anyOf: appliedSupplierFilter.value.length > 0 ? appliedSupplierFilter.value.map((v) => v.id) : undefined,
            isNull: appliedSupplierFilter.includeBlanks || undefined,
        },
    };
};

export function useTotalOverviewRows(searchTerm: string) {
    const defaultSort: GridSortModel = [{ field: "endDate", sort: "desc" }];
    const [sortModel, setSortModel] = useState<GridSortModel>(defaultSort);

    const [appliedFilters, setAppliedFilters] = useState<TableFilter[]>([]);
    const [offset, setOffset] = useState(0);
    const pageSize = 50;

    const variablesWithoffset = useCallback(
        (offset: number) => {
            return {
                input: {
                    where: {
                        ...getAppliedStatusFilter(appliedFilters),
                        ...getAppliedEndDateFilter(appliedFilters),
                        ...getAppliedTotalSpendFilter(appliedFilters),
                        ...getAppliedSupplierFilter(appliedFilters),
                        ...getAppliedResponsiblesFilter(appliedFilters),
                    },
                    search: searchTerm,
                    sort: getSortModel(sortModel),
                    limit: pageSize,
                    offset,
                },
            };
        },
        [appliedFilters, searchTerm, sortModel]
    );

    const { data, loading, fetchMore } = useQuery(TotalOverview_GetContracts, {
        variables: variablesWithoffset(offset),
        errorPolicy: "all",
    });

    const onLoadMore = useCallback(() => {
        const newOffset = offset + pageSize;
        setOffset(newOffset);
        track("Contract overview: Load more", { offset });
        fetchMore({
            variables: { ...variablesWithoffset(newOffset) },
            updateQuery: (prev, opts) => {
                const result = [...prev.getContracts.data];

                for (let i = 0; i < opts.fetchMoreResult.getContracts.data.length; i++) {
                    const contract = opts.fetchMoreResult.getContracts.data[i];
                    // Just appending _should_ work. However, we're being extra cautious of duplicates. We're
                    // reporting to sentry in case we encounter duplicates
                    if (!result.find((c) => c.id === contract.id)) {
                        result.push(contract);
                    } else {
                        Sentry.captureMessage("Load more: duplicate contract on load more", {
                            tags: { app: "contracts", contractId: contract.id },
                        });
                    }
                }
                return { getContracts: { data: result, total: prev.getContracts.total } };
            },
        });
    }, [fetchMore, offset, variablesWithoffset]);

    const contracts = getFragmentData(TotalOverview_ContractFragment, data?.getContracts.data ?? []);

    const rows: Row[] = useMemo(() => {
        return contracts.map((contract) => {
            let validatedSupplier: Supplier | undefined;
            try {
                validatedSupplier = supplierSchema.parse(contract.supplier);
            } catch {
                validatedSupplier = undefined;
            }

            const validatedResponsibles =
                contract.responsibles
                    ?.map((resp) => {
                        try {
                            return responsibleSchema.parse(resp);
                        } catch {
                            return null;
                        }
                    })
                    .filter((resp): resp is Responsible => resp !== null) ?? [];

            return {
                id: contract.id,
                title: contract.title,
                startDate: contract.startDate,
                endDate: contract.endDate,
                totalSpend: contract.totalSpend,
                supplier: validatedSupplier,
                responsible: validatedResponsibles,
            };
        });
    }, [contracts]);

    const onApplyFilters = useCallback(
        (filters: TableFilter[]) => {
            setOffset(0);
            setAppliedFilters(filters);
        },
        [setAppliedFilters]
    );

    return {
        rows,
        onSortContracts: setSortModel,
        sortModel,
        loading,
        appliedFilters,
        totalRows: data?.getContracts.total,
        onApplyFilters,
        onLoadMore,
    };
}
