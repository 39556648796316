import { useQuery } from "@apollo/client";
import { Card, CardContent, CardHeader, Stack, Table, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { CommentSection } from "./components/CommentSection";

import { getFragmentData } from "@/generated";
import { graphql } from "@/generated/gql";
import { detailRoute } from "@/routes/detail/$id";
import { Activities } from "./Activites";

const ActivityLog_ActivityMetaDataFragment = graphql(`
    fragment ActivityLog_ActivityMetaData on ContractActivityMetadata {
        requestId
        contractId
        seqNumber
    }
`);

const ActivityLog_ContractActivity = graphql(`
    fragment ActivityLog_ContractActivity on ContractActivity {
        ... on BaseContractActivity {
            meta {
                ...ActivityLog_ActivityMetaData
            }
        }
        ...Activities_ContractActivity
    }
`);

export const ActivityLog_ContractActivities = graphql(`
    query ActivityLog_ContractActivities($input: ContractActivitiesInput!) {
        contractActivities(input: $input) {
            activities {
                ...ActivityLog_ContractActivity
            }
            total
        }
    }
`);

export const ActivityLog = () => {
    const { id } = detailRoute.useParams();

    const { data } = useQuery(ActivityLog_ContractActivities, {
        errorPolicy: "all",
        variables: {
            input: {
                contractId: id,
            },
        },
    });

    const activities = getFragmentData(ActivityLog_ContractActivity, data?.contractActivities.activities) ?? [];

    const groupedChanges = Object.values(
        activities.reduce(
            (acc, activity) => {
                const meta = getFragmentData(ActivityLog_ActivityMetaDataFragment, activity.meta);
                if (!acc[meta?.requestId]) {
                    acc[meta?.requestId] = [];
                }
                acc[meta?.requestId].push(activity);
                return acc;
            },
            {} as Record<string, typeof activities>
        )
    );

    const renderedActivities = groupedChanges.map((group) => {
        const meta = getFragmentData(ActivityLog_ActivityMetaDataFragment, group[0].meta);
        const key = `${meta?.contractId}-${meta?.seqNumber}`;

        return <Activities key={key} activities={group} />;
    });

    return (
        <Stack px={4} gap={3} alignItems="start" maxWidth={2500} justifyContent="center" mx="auto">
            <Card sx={{ width: "100%" }}>
                <CardHeader
                    title={
                        <Typography variant="textLg" fontWeight={500}>
                            <FormattedMessage defaultMessage="Activity log" />
                        </Typography>
                    }
                />
                <CardContent>
                    <CommentSection />
                    <Table width="100%">{renderedActivities}</Table>
                </CardContent>
            </Card>
        </Stack>
    );
};
