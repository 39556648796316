import { Button, Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { track } from "@ignite-analytics/track";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { DocumentSectionHeader } from "./Header";
import { DocumentEntry } from "./DocumentEntry";
import { AddDocumentModal } from "./AddDocumentModal";

const DocumentSection_ContractFragment = graphql(`
    fragment DocumentSection_ContractFragment on Contract {
        documents {
            id
            ...DocumentEntry_DocumentFragment
            ...EditDocumentModal_DocumentFragment
            ...DeleteDocumentModal_DocumentFragment
        }
        ...DocumentSectionHeader_ContractFragment
        ...AddDocumentModal_ContractFragment
    }
`);

interface DocumentSectionProps {
    contract: FragmentType<typeof DocumentSection_ContractFragment>;
    canEdit: boolean;
}

const PREVIEW_DOC_COUNT = 3;

export const DocumentSection: React.FC<DocumentSectionProps> = (props) => {
    const contract = getFragmentData(DocumentSection_ContractFragment, props.contract);

    // How many documents to display
    const [showAll, setShowAll] = useState(false);
    const displayShowAllButton = !showAll && contract.documents.length > PREVIEW_DOC_COUNT;
    const displayedDocuments = showAll ? contract.documents : contract.documents.slice(0, PREVIEW_DOC_COUNT);

    // modals
    const [addDocumentOpen, setAddDocumentOpen] = useState(false);

    return (
        <Stack px={3} py={2.5} gap={2.5} component={Paper} elevation={1}>
            <DocumentSectionHeader
                canAddNew={props.canEdit}
                onClickAddNew={() => setAddDocumentOpen(true)}
                contract={contract}
            />

            {/* Entries... */}
            {displayedDocuments.map((doc) => {
                return <DocumentEntry canEdit={props.canEdit} key={doc.id} document={doc} />;
            })}

            {/* Show all button */}
            {displayShowAllButton && (
                <Button
                    size="xsmall"
                    color="secondary"
                    onClick={() => {
                        track("Contract Details: Show all documents clicked");
                        setShowAll(true);
                    }}
                >
                    <FormattedMessage defaultMessage="Show all" />
                </Button>
            )}

            {/* Special case: no documents */}
            {contract.documents.length === 0 && (
                <Stack justifyContent="center" component={Paper} elevation={1} p={2}>
                    <Typography variant="textXs" color="textSecondary">
                        <FormattedMessage defaultMessage="No documents uploaded" />
                    </Typography>
                </Stack>
            )}

            {/* Modals */}
            <AddDocumentModal contract={contract} open={addDocumentOpen} onClose={() => setAddDocumentOpen(false)} />
        </Stack>
    );
};
