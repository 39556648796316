import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { track } from "@ignite-analytics/track";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { useSnackbar } from "@/contexts/useSnackbar";
import { captureMessage } from "@/errorHandling/errors";
import { CloseButton } from "../CloseButton";
import { DocumentDrop } from "@/components/DocumentDrop";
import { DocumentEntries } from "../EditDocumentEntry";
import { ContractDocumentTag } from "@/generated/graphql";
import { DetailPage_GetContractQuery } from "@/pages/detail";

const AddDocumentModal_ContractFragment = graphql(`
    fragment AddDocumentModal_ContractFragment on Contract {
        id
    }
`);

const AddDocumentModal_CreateDocumentUploadURLsMutation = graphql(`
    mutation AddDocumentModal_CreateDocumentUploadURLsMutation($input: CreateDocumentUploadURLsRequest!) {
        createDocumentUploadURLs(input: $input) {
            urls {
                documentId
                key
                url
            }
        }
    }
`);

interface AddDocumentModalProps {
    open: boolean;
    onClose: () => void;
    contract: FragmentType<typeof AddDocumentModal_ContractFragment>;
}

const MAX_FILESIZE_MB = 30;

export const AddDocumentModal: React.FC<AddDocumentModalProps> = (props) => {
    const { postSnackbar } = useSnackbar();
    const contract = getFragmentData(AddDocumentModal_ContractFragment, props.contract);
    const [uploading, setUploading] = useState(false);
    const { formatMessage } = useIntl();

    // Contain various info about the file we wish to upload.
    type DocumentToUpload = {
        key: string;
        file: File; // contents
        filename: string; // name
        documentType: ContractDocumentTag | null;
        isPublic: boolean;
        loading?: boolean;
        error?: string;
        onRemove?: () => void;
        setDocumentType: (value: ContractDocumentTag | null) => void;
        setIsPublic: (value: boolean) => void;
    };

    const [docsToUpload, setDocsToUpload] = useState<DocumentToUpload[]>([]);

    // convenience function to update a document
    const update = (
        id: string,
        data: { documentType?: ContractDocumentTag | null; isPublic?: boolean; loading?: boolean; error?: string }
    ) => {
        setDocsToUpload((prev) => {
            const i = prev.findIndex((el) => el.key === id);
            if (i === -1) return prev;
            return [...prev.slice(0, i), { ...prev[i], ...data }, ...prev.slice(i + 1)];
        });
    };
    const remove = (id: string) => {
        setDocsToUpload((prev) => prev.filter((el) => el.key !== id));
    };

    const [createDocumentUploadURLs, { loading: creatingUploadURLs }] = useMutation(
        AddDocumentModal_CreateDocumentUploadURLsMutation,
        {
            refetchQueries: [DetailPage_GetContractQuery],
            awaitRefetchQueries: true,
            onCompleted: () => {
                track("Contract Details: Add document: document uploaded", {
                    count: docsToUpload.length,
                    privateCount: docsToUpload.filter((ent) => !ent.isPublic).length,
                    publicCount: docsToUpload.filter((ent) => ent.isPublic).length,
                    tags: docsToUpload.map((ent) => ent.documentType).join(", "),
                    tagCount: docsToUpload.filter((ent) => ent.documentType).length,
                });
                postSnackbar({
                    message: <FormattedMessage defaultMessage="Documents added successfully" />,
                    severity: "success",
                });
            },
            onError: (error) => {
                captureMessage("Failed to upload documents", {
                    extra: { error },
                });
                postSnackbar({
                    message: <FormattedMessage defaultMessage="Failed to upload documents" />,
                    severity: "error",
                });
                handleClose();
            },
        }
    );

    const loading = creatingUploadURLs || uploading;

    const handleClose = () => {
        props.onClose();
        setDocsToUpload([]);
    };
    const handleSave = async () => {
        // do not upload files that have errors (e.g. due to file size)
        const filtered = docsToUpload.filter((ent) => !ent.error);
        if (filtered.length === 0) return;

        // First, obtain URLs we upload to.
        // Then, we upload each file individually
        const res = await createDocumentUploadURLs({
            variables: {
                input: {
                    contractId: contract.id,
                    files: filtered.map((ent) => ({
                        key: ent.key,
                        filename: ent.file.name,
                        contentType: ent.file.type,
                        tag: ent.documentType,
                        isPublic: ent.isPublic,
                    })),
                },
            },
        });

        if (!res.data?.createDocumentUploadURLs?.urls) return;

        const promises = filtered.map(async (ent) => {
            const uri = res.data?.createDocumentUploadURLs?.urls.find((url) => url.key === ent.key)?.url;
            if (!uri) return;

            const result = await fetch(uri, {
                method: "PUT",
                body: ent.file,
                headers: { "Content-Type": ent.file.type },
            });
            if (result.status !== 200) {
                captureMessage("Failed to upload document", {
                    extra: { status: result.status, tag: ent.documentType, isPublic: ent.isPublic },
                });
                update(ent.key, { loading: false, error: "Failed to upload document" });
            } else {
                update(ent.key, { loading: false, error: undefined });
            }
        });
        await Promise.all(promises);

        setUploading(false);
        handleClose();
    };

    return (
        <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>
                <CloseButton onClose={handleClose} />
                <Stack gap={1}>
                    <Typography variant="textXl">
                        <FormattedMessage defaultMessage="Add a new document" />
                    </Typography>
                    <Typography variant="textSm" color="textTextHelper">
                        <FormattedMessage defaultMessage="Upload one or more documents, select a document type and access to document" />
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack gap={3} pt={3}>
                    <DocumentDrop
                        onChange={async (files) => {
                            if (files.length === 0) return;

                            const added: DocumentToUpload[] = [];
                            // eslint-disable-next-line no-restricted-syntax
                            for (const file of files) {
                                let error: string | undefined;
                                if (file.size > MAX_FILESIZE_MB * 1024 * 1024) {
                                    error = formatMessage(
                                        { defaultMessage: "File exceeds {size}MB limit" },
                                        { size: MAX_FILESIZE_MB }
                                    );
                                }

                                const key = Math.random().toString(36).substring(7);
                                added.push({
                                    key,
                                    file,
                                    documentType: null,
                                    isPublic: true,
                                    error,
                                    filename: file.name,
                                    setDocumentType: (value) => update(key, { documentType: value }),
                                    setIsPublic: (value) => update(key, { isPublic: value }),
                                    onRemove: () => remove(key),
                                });
                            }
                            setDocsToUpload((prev) => [...prev, ...added]);
                        }}
                    />
                    {docsToUpload.length > 0 && <DocumentEntries entries={docsToUpload} />}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" sx={{ mr: 1 }}>
                    <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <LoadingButton
                    loading={loading}
                    onClick={handleSave}
                    color="primary"
                    disabled={docsToUpload.filter((doc) => !doc.error).length === 0}
                >
                    <FormattedMessage
                        defaultMessage="{count, plural, =0 {Add document} one {Add document} other {Add {count} documents}}"
                        values={{ count: docsToUpload.length }}
                    />
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
