import { formatDateAsUTC } from "@ignite-analytics/locale";
import { Chip, Link as Stack, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { ReactElement } from "react";

import { useCompanyCurrency } from "@/contexts/CompanyCurrencyContext";
import { env } from "@/env";
import { usePermissionCheck } from "@/hooks/usePermissionCheck";

import { CustomField, Row } from "../contractListItem";
import { UserCell } from "./UserCell";
import { SupplierCustomField } from "./CustomField/SupplierCustomField";
import { TitleCustomField } from "./CustomField/TitleCustomField";

const fixDmsContacts = env.REACT_APP_ENV !== "local";

const formatDate = (date: string | Date | number) => {
    return formatDateAsUTC(date, { month: "short" });
};

type RenderMonetaryAmountCellProps = {
    amount?: number | null;
};
export const RenderMonetaryAmountCell = ({ amount }: RenderMonetaryAmountCellProps) => {
    const currency = useCompanyCurrency();
    if (amount === undefined || amount === null) {
        return <Typography sx={{ textAlign: "right" }}>-</Typography>;
    }
    return (
        <Typography sx={{ textAlign: "right" }} variant="inherit">
            {currency.formatCompact(amount)}
        </Typography>
    );
};

type CustomFieldProps = {
    field: CustomField;
    row: Row;
};
export const RenderCustomField = ({ field, row }: CustomFieldProps): ReactElement => {
    const canOpenSupplierCard = usePermissionCheck("suppliers", "general", "read").data ?? false;
    const currency = useCompanyCurrency();

    switch (field.dataType) {
        case "TITLE":
            return (
                <TitleCustomField
                    contractId={field.data.value ?? ""}
                    title={field.data.label}
                    endDate={row.fields.find((f) => f.dataType === "END_DATE")?.data}
                />
            );

        case "SUPPLIER":
            return (
                <>
                    {field.data?.value && (
                        <SupplierCustomField
                            name={field.data.label}
                            id={field.data.value}
                            canOpen={canOpenSupplierCard}
                        />
                    )}
                </>
            );
        case "END_DATE":
            return <>{field.data ? formatDate(field.data) : ""}</>;
        case "TEXT":
            return (
                <Typography variant="textSm" noWrap>
                    {field.data}
                </Typography>
            );
        case "TEXT_LIST":
        case "TAG":
            return <>{field.data}</>;
        case "NUMBER":
        case "NUMBER_AGGREGATION":
            if (!field.data) {
                return <></>;
            }
            return (
                <Typography variant="textSm" sx={{ textAlign: "right" }}>
                    {Number(field.data).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                </Typography>
            );
        case "MONETARY_AMOUNT":
            return (
                <Typography variant="textSm" sx={{ textAlign: "right" }} key={field.id}>
                    {field.data?.currency} {field.data?.amount ? currency.formatCompact(field.data.amount) : "-"}
                </Typography>
            );
        case "SPEND": {
            // the currency code is written in the header, so we don't need to display it here
            let amount = currency.formatInteger(field.data);
            if (currency.code) {
                amount = amount.replace(currency.code, "");
            }
            return (
                <Typography variant="textSm" sx={{ textAlign: "right" }} key={field.id}>
                    {amount}
                </Typography>
            );
        }
        case "BOOLEAN":
            return (
                <Typography key={field.id} sx={{ textAlign: "center" }}>
                    <Checkbox checked={field.data ?? false} />
                </Typography>
            );
        case "DATE_AGGREGATION":
        case "DATE":
            return (
                <Typography variant="textSm" key={field.id}>
                    {field.data ? formatDate(field.data) : ""}
                </Typography>
            );
        case "CONTACT":
        case "COMPANY":
            return <>{field.data?.label ?? ""}</>;
        case "USER":
            return <UserCell userIds={field.data?.value ? [field.data?.value] : []} />;
        case "GROUP_STRUCTURE":
            return <>{field.data?.id?.label ?? ""}</>;
        case "TAG_LIST":
            return (
                <Stack display="block">
                    {field.data?.map((item) => (
                        <Chip sx={{ m: 0.5 }} label={item} key={field.name + item} size="small" />
                    ))}
                </Stack>
            );
        case "CONTACT_LIST":
            return (
                <Stack display="block">
                    {field.data?.map((item) => (
                        <Chip sx={{ m: 0.5 }} label={item.label} key={item.value} size="small" />
                    ))}
                </Stack>
            );
        case "USER_LIST":
            return (
                <>
                    {fixDmsContacts && <UserCell userIds={field.data.map((d) => d.value as string)} />}
                    {!fixDmsContacts && (
                        <Stack display="block">
                            {field.data?.map((item) => (
                                <Chip sx={{ m: 0.5 }} label={item.label} key={item.value} size="small" />
                            ))}
                        </Stack>
                    )}
                </>
            );
        case "GROUP_STRUCTURE_LIST":
            return (
                <Stack display="block">
                    {field.data?.ids?.map((item) => (
                        <Chip sx={{ m: 0.5 }} label={item.label} key={item.value} size="small" />
                    ))}
                </Stack>
            );
        default:
            return <Typography variant="textSm" key={field.id} />;
    }
};
