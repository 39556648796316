import { ShapeIcon } from "@ignite-analytics/components";
import { Contracts } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Stack, Switch, Typography } from "@mui/material";
import { DataGridPro, GridSortModel } from "@mui/x-data-grid-pro";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { TotalOverviewCards } from "./TotalOverviewCards";

import { useColumns } from "./useColumns";
import { useTotalOverviewRows } from "./useTotalOverviewRows";

import { AsyncMultiSelectFilter } from "@/components/TableFilter/types";
import { useUserOrThrow } from "@/contexts/useUser";
import { GridFooter } from "./TableFooter";
import { TableToolbar, TableToolbarProps } from "./TableToolbar";
import { useGetFilterDefinitions } from "./useGetFilterDefinitions";

declare module "@mui/x-data-grid-pro" {
    interface ToolbarPropsOverrides extends TableToolbarProps {}
}

export const TotalOverview: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const user = useUserOrThrow();

    const columns = useColumns();
    const { totalRows, rows, onSortContracts, loading, appliedFilters, onApplyFilters, sortModel, onLoadMore } =
        useTotalOverviewRows(searchTerm);

    const onSearch = useCallback(
        (value: string) => {
            track("Contracts > Contract monitoring > Search", { searchTerm: value });
            setSearchTerm(value);
        },
        [setSearchTerm]
    );

    const handleChangeSortModel = useCallback(
        (model: GridSortModel) => {
            track("Contracts > Contract monitoring > Sort", { sortModel: model, numSorts: model.length });
            onSortContracts(model);
        },
        [onSortContracts]
    );

    const { filters, quickFilters } = useGetFilterDefinitions();

    const handleToggleShowMyContracts = () => {
        // Add user id to responsibles filter if it is not there, otherwise remove it
        const responsibleFilter = appliedFilters.find((filter) => filter.id === "responsible") as
            | AsyncMultiSelectFilter
            | undefined;
        let showMyContracts = false;
        if (responsibleFilter) {
            const currentUserInFilter =
                responsibleFilter.value.length === 1 && responsibleFilter.value[0].id === user.userId;
            if (currentUserInFilter) {
                onApplyFilters(appliedFilters.filter((filter) => filter.id !== "responsible"));
            } else {
                showMyContracts = true;
                const filtersWithoutResponsible = appliedFilters.filter((filter) => filter.id !== "responsible");
                const currentUser = [{ id: user.userId, label: `${user.firstName} ${user.lastName}` }];
                onApplyFilters([
                    ...filtersWithoutResponsible,
                    {
                        ...responsibleFilter,
                        value: currentUser,
                    },
                ]);
            }
        } else {
            showMyContracts = true;
            const newResponsibleFilter: AsyncMultiSelectFilter = filters.find(
                (filter) => filter.id === "responsible"
            ) as AsyncMultiSelectFilter;
            onApplyFilters([
                ...appliedFilters,
                { ...newResponsibleFilter, value: [{ id: user.userId, label: `${user.firstName} ${user.lastName}` }] },
            ]);
        }
        track("Contracts > Contract monitoring > Toggle only show my contracts", { showMyContracts });
    };

    const showMyContracts =
        appliedFilters.some(
            (filter) =>
                filter.id === "responsible" &&
                filter.type === "asyncMultiSelect" &&
                Array.isArray(filter.value) &&
                filter.value.length === 1 &&
                filter.value[0].id === user.userId
        ) ?? false;

    return (
        <Stack spacing={2}>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Switch checked={showMyContracts} onChange={handleToggleShowMyContracts} />
                    <Typography color="textTextSecondary" variant="textSm" fontWeight={500}>
                        <FormattedMessage defaultMessage="Only show contracts that you are owner of" />
                    </Typography>
                </Stack>
            </Stack>
            <TotalOverviewCards showMyContracts={showMyContracts} />

            <Stack height="70vh" gap={3} pt={1}>
                <Stack direction="row" gap={1}>
                    <ShapeIcon color="accent">
                        <Contracts />
                    </ShapeIcon>
                    <Typography variant="textXl" fontWeight="500">
                        <FormattedMessage defaultMessage="Contracts" />
                    </Typography>
                </Stack>
                <DataGridPro
                    rows={rows}
                    columns={columns}
                    onSortModelChange={handleChangeSortModel}
                    rowSelection={false}
                    disableColumnMenu
                    disableColumnResize
                    disableColumnFilter
                    sortingMode="server"
                    sortModel={sortModel}
                    loading={loading}
                    slotProps={{
                        loadingOverlay: {
                            variant: "skeleton",
                            noRowsVariant: "skeleton",
                        },
                        footer: {
                            onLoadMore,
                            totalRowCount: totalRows,
                        },
                        toolbar: {
                            totalNumberOfRows: totalRows,
                            visibleRowCount: rows.length,
                            searchTerm,
                            onSearch,
                            appliedFilters,
                            filters,
                            quickFilters,
                            onApplyFilters,
                        },
                    }}
                    slots={{
                        toolbar: TableToolbar,
                        footer: GridFooter,
                    }}
                />
            </Stack>
        </Stack>
    );
};
