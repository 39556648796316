import React from "react";
import { Stack, Button, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "@tanstack/react-router";

export const PermissionDenied: React.FC = () => {
    const navigate = useNavigate();
    return (
        <Stack
            gap={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100vh" // full viewport height
        >
            <Typography variant="textMd">
                <FormattedMessage defaultMessage="You do not have permission to view this contract" />
            </Typography>
            <Button
                color="secondary"
                onClick={() => {
                    navigate({
                        to: "/list",
                    });
                }}
            >
                <FormattedMessage defaultMessage="Go back" />
            </Button>
        </Stack>
    );
};
