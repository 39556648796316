import { FormattedMessage } from "react-intl";

import { Button, Stack } from "@mui/material";

import { AccessLevel, AccessLevels } from "../types";

interface EditPublicAccessProps {
    contractIds: string[];
    onSave: (contractIds: string[], access: AccessLevel) => void;
    onCancel: () => void;
    disableSaveButton: boolean;
}

export const EditPublicAccess: React.FC<EditPublicAccessProps> = ({
    contractIds,
    onSave,
    onCancel,
    disableSaveButton,
}) => {
    const handleSave = () => {
        onSave(contractIds, AccessLevels.PUBLIC);
    };

    return (
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button variant="outlined" color="secondary" onClick={onCancel}>
                <FormattedMessage defaultMessage="Cancel" />
            </Button>
            <Button onClick={() => handleSave()} disabled={disableSaveButton}>
                <FormattedMessage defaultMessage="Confirm changes" />
            </Button>
        </Stack>
    );
};
