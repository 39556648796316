import { FormattedMessage } from "react-intl";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { RenderActivity } from "./RenderActivity";

const DocumentDeletedActivity_DocumentDeletedContractActivity = graphql(`
    fragment DocumentDeletedActivity_DocumentDeletedContractActivity on DocumentDeletedContractActivity {
        filename
    }
`);

export interface DocumentDeletedActivityProps {
    activity: FragmentType<typeof DocumentDeletedActivity_DocumentDeletedContractActivity>;
}

export const DocumentDeletedActivity: React.FC<DocumentDeletedActivityProps> = (props) => {
    const activity = getFragmentData(DocumentDeletedActivity_DocumentDeletedContractActivity, props.activity);
    return (
        <RenderActivity
            action="deleted"
            title={<FormattedMessage defaultMessage="Document" />}
            value={activity.filename}
        />
    );
};
