import { createRootRouteWithContext, createRouter, stringifySearchWith } from "@tanstack/react-router";
import { Root } from "@/routes/__root";

import { listRoute } from "./list";

import { User } from "@/contexts/useUser/types";
import { askDocumentExperimentRoute } from "./ask-document-experiment";
import { detailDataListModalSubRoute, detailRoute } from "./detail/$id";
import { indexRoute } from "./index";
import { layoutsRoute } from "./layouts/$id";
import { totalOverviewRoute } from "./total-overview";
// Root route
export const rootRoute = createRootRouteWithContext<{ user: User }>()({
    component: Root,
});

// Combine all routes
const routeTree = rootRoute.addChildren([
    indexRoute,
    listRoute,
    layoutsRoute,
    detailRoute.addChildren([detailDataListModalSubRoute]),
    askDocumentExperimentRoute,
    totalOverviewRoute,
]);

const emptyUser: User = {
    email: "",
    firstName: "",
    lastName: "",
    tenant: "",
    userId: "",
};

function getRouter(basepath: string) {
    return createRouter({
        basepath,
        context: { user: emptyUser }, // placeholder - will be populated by RouterProvider
        defaultPreload: "intent",
        routeTree,
        defaultNotFoundComponent: () => "404 Not Found",
        stringifySearch: (search) => {
            // Shallow copy that we can mutate
            const stringifiedSearch = { ...search };

            if (stringifiedSearch.searchTerm === "") {
                // special-case: to avoid ?searchTerm= in the URL, we can just omit it in case it's empty
                // eslint-disable-next-line no-param-reassign
                delete stringifiedSearch.searchTerm;
            }
            if (stringifiedSearch.page === 1) {
                // special-case: to avoid ?page=1 in the URL, we can just omit it in case it's 0
                // eslint-disable-next-line no-param-reassign
                delete stringifiedSearch.page;
            }
            if (stringifiedSearch.perPage === 25) {
                // omit ?perPage=25 in the URL
                // eslint-disable-next-line no-param-reassign
                delete stringifiedSearch.perPage;
            }
            if (stringifiedSearch.sortOrder === false) {
                // omit ?sortOrder=false in the URL
                // eslint-disable-next-line no-param-reassign
                delete stringifiedSearch.sortOrder;
            }
            if (stringifiedSearch.group === "all") {
                delete stringifiedSearch.group;
            }
            return stringifySearchWith(JSON.stringify)(stringifiedSearch);
        },
    });
}

const staticRouter = getRouter("/");
export { getRouter, staticRouter };
