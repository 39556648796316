import { Checkbox, FormControl, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { useCallback } from "react";
import { ClearFilterButton } from "./ClearFilterButton";
import { PresetMultiSelectFilter } from "./types";

interface PresetSelectProps {
    filter: PresetMultiSelectFilter;
    onChange: (filter: PresetMultiSelectFilter) => void;
}

export const PresetSelect: React.FC<PresetSelectProps> = ({ filter, onChange }) => {
    const handleClearFilter = () => {
        onChange({ ...filter, value: [] });
    };

    const isSelected = useCallback(
        (value: string) => {
            return filter.value.some((v) => v.value === value);
        },
        [filter.value]
    );

    return (
        <Stack paddingX={2} spacing={1}>
            <FormControl fullWidth>
                {filter.options.map((option) => (
                    <ListItem key={option.value} dense disablePadding>
                        <ListItemButton
                            onClick={() => {
                                let newValue: PresetMultiSelectFilter["value"];
                                if (isSelected(option.value)) {
                                    newValue = filter.value.filter((v) => v.value !== option.value);
                                } else {
                                    newValue = [...filter.value, option];
                                }
                                onChange({ ...filter, value: newValue });
                            }}
                        >
                            <ListItemIcon sx={{ minWidth: "12px" }}>
                                <Checkbox checked={isSelected(option.value)} edge="start" />
                            </ListItemIcon>
                            <ListItemText primary={option.label} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </FormControl>
            <ClearFilterButton onClear={handleClearFilter} disabled={!filter.value} />
        </Stack>
    );
};
