import { ExclamationTriangle, Trash } from "@ignite-analytics/icons";
import { IconButton, Skeleton, Stack, Switch, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { track } from "@ignite-analytics/track";
import { ShapeIcon } from "@ignite-analytics/components";
import { ContractDocumentTag } from "@/generated/graphql";
import { DocumentTypeSelect } from "./EditDocumentModal/DocumentTypeSelect";
import { InformationTooltip } from "@/components/InformationTooltip";
import { OverflowTooltip } from "@/components/OverflowTooltip";

export type DocumentEntry = {
    key: string;
    filename: string;
    documentType: ContractDocumentTag | null | undefined;
    isPublic: boolean;
    setDocumentType: (documentType: ContractDocumentTag | null) => void;
    setIsPublic: (isPublic: boolean) => void;
    // if present, will render a remove button
    onRemove?: () => void;
    error?: string;
};

interface DocumentEntries {
    entries: DocumentEntry[];
    loading?: boolean;
}

export const DocumentEntries: React.FC<DocumentEntries> = (props) => {
    const WIDTH_TYPE = "230px";
    const WIDTH_PRIVATE = "73px";
    const WIDTH_REMOVE = "73px";
    const WIDTH_TITLE = "371px";

    const showRemoveHeader = props.entries.some((entry) => entry.onRemove);

    return (
        <Stack gap={2} component="li">
            {/* Header */}
            <Stack direction="row" gap={3}>
                {/* Document title */}
                <Stack gap={3} width={WIDTH_TITLE} mr="auto">
                    <Stack gap={1.5}>
                        <Typography variant="textSm" fontWeight={500} color="textPrimary">
                            <FormattedMessage defaultMessage="Document title" />
                        </Typography>
                    </Stack>
                    {props.entries.map((entry) => (
                        <Stack direction="row" height="100%" alignItems="center" key={entry.key} gap={1.5}>
                            {entry.error && <ShapeIcon color="error" icon={<ExclamationTriangle />} />}
                            <Stack gap={0.5}>
                                <OverflowTooltip title={entry.filename}>
                                    <Typography
                                        textOverflow="ellipsis"
                                        variant="textSm"
                                        noWrap
                                        color="textPrimary"
                                        fontWeight={500}
                                    >
                                        {entry.filename}
                                    </Typography>
                                </OverflowTooltip>
                                {entry.error && (
                                    <Typography variant="textSm" fontWeight={500} color="textTextError">
                                        {entry.error}
                                    </Typography>
                                )}
                            </Stack>
                        </Stack>
                    ))}
                </Stack>

                {/* Document type */}
                <Stack gap={3}>
                    <Stack direction="row" alignItems="center" width={WIDTH_TYPE} gap={0.75}>
                        <Typography variant="textSm" fontWeight={500} color="textPrimary">
                            <FormattedMessage defaultMessage="Document type" />
                        </Typography>
                        <InformationTooltip
                            content={
                                <FormattedMessage defaultMessage="Select a document type to keep it organized and easy to find later" />
                            }
                        />
                    </Stack>
                    {props.entries.map((entry) => (
                        <Stack width={WIDTH_TYPE} key={entry.key}>
                            {props.loading ? (
                                <Skeleton key={entry.key} width="100%" height={40} />
                            ) : (
                                <DocumentTypeSelect
                                    value={entry.documentType ?? null}
                                    onChange={(value) => {
                                        track("Contract details: Document updated: type changed", {
                                            documentId: entry.key,
                                            documentType: value,
                                        });
                                        entry.setDocumentType(value);
                                    }}
                                />
                            )}
                        </Stack>
                    ))}
                </Stack>

                {/* Access */}
                <Stack gap={3}>
                    <Stack direction="row" gap={0.75} alignItems="center" width={WIDTH_PRIVATE}>
                        <Typography variant="textSm" fontWeight={500} color="textPrimary">
                            <FormattedMessage defaultMessage="Private" />
                        </Typography>
                        <InformationTooltip
                            content={
                                <FormattedMessage defaultMessage="If private, only contract owners and administrators have access to the file" />
                            }
                        />
                    </Stack>
                    {props.entries.map((entry) =>
                        props.loading ? (
                            <Skeleton key={entry.key} width="100%" height={40} />
                        ) : (
                            <Switch
                                key={entry.key}
                                sx={{ my: "auto", width: WIDTH_PRIVATE }}
                                checked={!entry.isPublic}
                                onChange={() => {
                                    track("Contract details: Document updated: access changed", {
                                        documentId: entry.key,
                                        isPublic: !entry.isPublic,
                                    });
                                    entry.setIsPublic(!entry.isPublic);
                                }}
                            />
                        )
                    )}
                </Stack>

                {/* Remove button */}
                {showRemoveHeader && (
                    <Stack gap={3} width={WIDTH_REMOVE}>
                        <Stack gap={1.5} alignItems="center">
                            <Typography variant="textSm" fontWeight={500} color="textPrimary">
                                <FormattedMessage defaultMessage="Remove" />
                            </Typography>
                        </Stack>
                        {props.entries.map((entry) =>
                            props.loading ? (
                                <Skeleton key={entry.key} width="100%" />
                            ) : (
                                <Stack gap={1.5} key={entry.key} justifyContent="center" height={40}>
                                    <IconButton size="2xsmall" color="secondary" onClick={() => entry.onRemove!()}>
                                        <Trash color="error" />
                                    </IconButton>
                                </Stack>
                            )
                        )}
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};
