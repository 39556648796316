import { GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { useCallback, useMemo, useState } from "react";
import { Row } from "@/components/ContractsTablePro/contractListItem";
import { useUserOrThrow } from "@/contexts/useUser";
import { usePermissionCheck } from "@/hooks/usePermissionCheck";
import { listRoute } from "@/routes/list";

export function useRows() {
    const { contracts } = listRoute.useLoaderData();
    const { userId } = useUserOrThrow();

    const hasAdministratorPermission = usePermissionCheck("contracts", "general", "create")?.data ?? false;

    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

    const onRowSelectionModelChange = (newSelection: GridRowSelectionModel) => {
        setRowSelectionModel(newSelection);
    };

    const canEditByContractId = useMemo(() => {
        return (
            contracts?.data.reduce<{ [contractId: string]: boolean }>((acc, contract) => {
                if (hasAdministratorPermission) {
                    acc[contract.id] = true;
                    return acc;
                }
                acc[contract.id] = contract.responsibleIds.includes(userId);
                return acc;
            }, {}) ?? {}
        );
    }, [contracts?.data, hasAdministratorPermission, userId]);

    const isRowSelectable = useCallback(
        (params: GridRowParams<Row>) => {
            return canEditByContractId[params.id] ?? false;
        },
        [canEditByContractId]
    );

    return {
        rows: contracts?.data ?? [],
        totalNumberOfRows: contracts?.total ?? 0,
        isRowSelectable,
        rowSelectionModel,
        onRowSelectionModelChange,
    };
}
