type EntitledUser = {
    subjectId: string;
    permission: string;
};

export type ContractPermissions = {
    objectId: string;
    entitleds: EntitledUser[];
};

export enum AccessLevels {
    RESTRICTED = "restricted",
    PUBLIC = "public",
}

export type AccessLevel = AccessLevels.RESTRICTED | AccessLevels.PUBLIC | undefined;
