import { Chip, MenuItem, Stack } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { UserItem } from "@/components/UserAvatars";

import { ContractPermissions } from "@/hooks/useContractPermissions/types";
import { UserRoleChip } from "@/pages/detail/components/ContractSidebar/AccessCard/UserRoleChip";
import { SelectUserPermissionLevel } from "./SelectUserPermissionLevel";

interface UsersWithReadPermissionProps {
    permissions: ContractPermissions[];
    contractIds: string[];
    onChange: (user: ContractPermissions, permissionLevel: string) => void;
    getPermissionLevel: (user: ContractPermissions) => {
        accessLevel: string;
        numberOfContractsWithAccess: number;
    };
}

export const UsersWithReadPermission: React.FC<UsersWithReadPermissionProps> = ({
    permissions,
    contractIds,
    onChange,
    getPermissionLevel,
}) => {
    if (permissions.length === 0) {
        return null;
    }
    const isContractResponsible = permissions.some((permission) => permission.reads.some((read) => read.isResponsible));
    return (
        <Stack>
            {permissions.map((permission) => (
                <MenuItem key={permission.user.id}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                        <UserItem user={permission.user} />
                        <Stack minWidth={200}>
                            {!permission.reads.some((read) => read.isResponsible) && (
                                <SelectUserPermissionLevel
                                    contractIds={contractIds}
                                    getPermissionLevel={getPermissionLevel}
                                    user={permission}
                                    onChange={onChange}
                                />
                            )}
                            {/* Can't change here if user is contract responsible */}
                            {isContractResponsible && (
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <Chip
                                        label={<FormattedMessage defaultMessage="Contract responsible" />}
                                        size="small"
                                    />
                                    <UserRoleChip role={permission.roleId} />
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </MenuItem>
            ))}
        </Stack>
    );
};
