import { useQuery } from "@apollo/client";
import { Autocomplete, TextField } from "@mui/material";
import { useIntl } from "react-intl";
import { graphql } from "@/generated";
import { ContractDocumentTag } from "@/generated/graphql";
import { useFormatDocumentTag } from "../useFormatDocumentTag";

const DocumentTypeSelect_GetDocumentTagsQuery = graphql(`
    query DocumentTypeSelect_GetDocumentTagsQuery {
        getDocumentTags
    }
`);

interface DocumentTypeSelectProps {
    value: ContractDocumentTag | null;
    onChange: (value: ContractDocumentTag | null) => void;
}

export const DocumentTypeSelect: React.FC<DocumentTypeSelectProps> = (props) => {
    const { data, loading } = useQuery(DocumentTypeSelect_GetDocumentTagsQuery, {
        fetchPolicy: "cache-first",
    });
    const { formatMessage } = useIntl();
    const formatTag = useFormatDocumentTag();

    return (
        <Autocomplete
            fullWidth
            loading={loading}
            size="small"
            options={data?.getDocumentTags ?? []}
            getOptionLabel={(option) => formatTag(option)}
            value={props.value}
            onChange={(_, value) => props.onChange(value)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={formatMessage({ defaultMessage: "Select document type" })}
                    variant="outlined"
                />
            )}
        />
    );
};
