import { Chip } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { useFormatDocumentTag } from "./useFormatDocumentTag";

const DocumentTag_DocumentFragment = graphql(`
    fragment DocumentTag_DocumentFragment on ContractDocument {
        tag
    }
`);

interface DocumentTagProps {
    document: FragmentType<typeof DocumentTag_DocumentFragment>;
}

export const DocumentTag: React.FC<DocumentTagProps> = (props) => {
    const document = getFragmentData(DocumentTag_DocumentFragment, props.document);
    const formatTag = useFormatDocumentTag();

    if (!document.tag) {
        return <Chip color="infoGray" label={<FormattedMessage defaultMessage="Unknown" />} />;
    }
    return <Chip label={formatTag(document.tag)} />;
};
