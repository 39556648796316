import { createRoute } from "@tanstack/react-router";

import { TotalOverviewPage } from "@/pages/totalOverview";
import { rootRoute } from "@/routes/routes";

export const totalOverviewRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/overview",
    component: TotalOverviewPage,
});
