import { Link as MuiLink, Stack, Typography } from "@mui/material";
import { Link } from "@tanstack/react-router";
import React from "react";

import { track } from "@ignite-analytics/track";
import dayjs from "dayjs";
import { OverflowTooltip } from "@/components/OverflowTooltip";
import { detailRoute } from "@/routes/detail/$id";
import { listRoute } from "@/routes/list";

type Props = {
    contractId: string;
    title: string;
    from?: string;

    // For tracking
    endDate?: string | null;
};

export const TitleCustomField: React.FC<Props> = ({ contractId, title, from = listRoute.fullPath, endDate }) => {
    const expiresInMonths = endDate ? dayjs(endDate).diff(dayjs(), "month") : undefined;
    return (
        <Stack justifyContent="center" alignItems="start" height="100%">
            <MuiLink
                onClick={() => track("Contracts > Click contract title", { endDate, expiresInMonths })}
                component={Link}
                from={from}
                to={detailRoute.to}
                params={{ id: contractId }}
                noWrap
                sx={{
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    "&:hover": {
                        textDecorationColor: (theme) => theme.palette.text.primary,
                    },
                }}
            >
                <OverflowTooltip title={title}>
                    <Typography color="textPrimary" fontWeight="600" variant="textSm">
                        {title}
                    </Typography>
                </OverflowTooltip>
            </MuiLink>
        </Stack>
    );
};
