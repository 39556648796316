import { useContext } from "react";
import { UserContext } from "./context";
import { User } from "./types";

export function useUser(): User | undefined {
    const context = useContext(UserContext);
    return context;
}

export function useUserOrThrow(): User {
    const user = useUser();
    if (!user) {
        throw new Error("User not found");
    }
    return user;
}
