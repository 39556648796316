import { useCallback, useMemo, useState } from "react";
import { Snackbar } from "@/components/Snackbar";
import { SnackbarContext } from "./context";
import { Snack } from "./types";

export const SnackbarProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const [snack, setSnack] = useState<Snack | null>(null);

    const handleClose = useCallback(() => {
        setSnack(null);
    }, []);

    const postSnackbar = useCallback(setSnack, [setSnack]);

    const value = useMemo(() => ({ postSnackbar }), [postSnackbar]);

    return (
        <SnackbarContext.Provider value={value}>
            <Snackbar open={!!snack} snack={snack} onClose={handleClose} />
            {children}
        </SnackbarContext.Provider>
    );
};
