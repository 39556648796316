import { captureException } from "@sentry/react";
import { apolloClient } from "@/contexts";
import { captureMessage } from "@/errorHandling/errors";
import { graphql } from "@/generated";
import { replaceNullsWithUndefineds } from "@/helpers/hasValue";

const getContactsQueryDocument = graphql(`
    query Contacts_GetContactsBySupplierId($input: GetContactsBySupplierIdInput!) {
        getContactsBySupplierId(input: $input) {
            result {
                contacts {
                    id
                    firstName
                    lastName
                    email
                    phone {
                        number
                    }
                }
            }
        }
    }
`);

export async function getContactsBySupplierId(id: string | null) {
    if (!id) {
        return [];
    }
    return apolloClient
        .query({
            fetchPolicy: "no-cache",
            query: getContactsQueryDocument,
            variables: {
                input: {
                    supplierId: id,
                },
            },
        })
        .then((result) => {
            return result.data.getContactsBySupplierId.result.contacts.map((c) => ({
                id: c.id,
                firstName: c.firstName ?? "",
                lastName: c.lastName ?? "",
                email: c.email ?? "",
                phone: c.phone?.number ?? "",
            }));
        })
        .catch((e) => {
            captureMessage("Error fetching contacts by supplier id", { extra: { error: e } });
            return [];
        });
}

const upsertContactsMutationDocument = graphql(`
    mutation UpsertContact($input: UpsertContactsInput!) {
        upsertContacts(input: $input) {
            result {
                id
                firstName
                lastName
                email
                phone {
                    number
                }
            }
        }
    }
`);

const getContactByIdQueryDocument = graphql(`
    query GetContactById($input: GetContactsInput!) {
        getContacts(input: $input) {
            result {
                id
                firstName
                lastName
                email
                phone {
                    number
                }
            }
        }
    }
`);

async function getContactById(id: string) {
    const res = await apolloClient.query({
        query: getContactByIdQueryDocument,
        variables: {
            input: {
                contacts: [{ id, phone: {} }],
                limit: 1,
                offset: 0,
                sort: { by: "id", dir: "asc" },
            },
        },
    });
    const raw = replaceNullsWithUndefineds(res.data.getContacts.result[0]);
    let phone = raw.phone.number;
    if (phone === "") {
        phone = undefined;
    }
    return { id: raw.id, firstName: raw.firstName, lastName: raw.lastName, email: raw.email, phone };
}

export async function addContact(
    supplierId: string,
    params: { firstName: string; lastName: string; email: string; phone?: string }
) {
    const res = await apolloClient.mutate({
        mutation: upsertContactsMutationDocument,
        variables: {
            input: {
                contacts: [
                    {
                        id: "",
                        firstName: params.firstName,
                        lastName: params.lastName,
                        email: params.email,
                        phone: { number: params.phone },
                        companyId: supplierId,
                    },
                ],
            },
        },
    });
    if (res.errors) {
        captureException("Failed to add contact", { extra: { errors: res.errors } });
        return undefined;
    }
    if (res.data) {
        return res.data.upsertContacts.result[0].id;
    }
    return undefined;
}
export async function editContact(
    id: string,
    params: { firstName?: string; lastName?: string; email?: string; phone?: string }
) {
    const contact = await getContactById(id);
    const merged = { ...contact, ...params, phone: { number: params.phone } };
    const res = await apolloClient.mutate({
        mutation: upsertContactsMutationDocument,
        variables: {
            input: {
                contacts: [merged],
            },
        },
    });
    if (res.errors) {
        captureException("Failed to edit contact", { extra: { errors: res.errors } });
    }
}
