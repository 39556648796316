import { LeafNode, LeafNodeRecord } from "./types";

export function getItem(items: LeafNode[], id: string): LeafNode | undefined {
    // search all the items and its children for the item with the given id
    // eslint-disable-next-line
    for (const item of items) {
        if (item.id === id) {
            return item;
        }

        if (item.children) {
            const childItem = getItem(item.children, id);
            if (childItem) {
                return childItem;
            }
        }
    }
}

function createNodeMap(flatList: LeafNode[]): LeafNodeRecord {
    // Create a map to store nodes by their IDs for quick lookup
    const nodeMap: Record<string, LeafNode> = {};

    // Initialize all nodes in the map with `label` instead of `name`
    flatList.forEach((node) => {
        const { ...rest } = node;
        nodeMap[node.id] = { ...rest, children: [] };
    });

    return nodeMap;
}

export function buildTree(flatList: LeafNode[]): LeafNode[] {
    const nodeMap = createNodeMap(flatList);
    // Build the tree structure
    const tree: LeafNode[] = [];
    flatList.forEach((node) => {
        if (node.parentId) {
            // Add to its parent's children array
            const parent = nodeMap[node.parentId];
            if (parent) {
                parent.children.push(nodeMap[node.id]);
            }
        } else {
            // If no parentId, it's a root node
            tree.push(nodeMap[node.id]);
        }
    });

    return tree;
}
