import { Button, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { DotsHorizontal, Lock, Trash } from "@ignite-analytics/icons";
import dayjs from "dayjs";
import { useState } from "react";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { DocumentTag } from "./DocumentTag";
import { EditDocumentModal } from "./EditDocumentModal";
import { RiddleMenu } from "@/components/RiddleMenu";
import { DeleteDocumentModal } from "./DeleteDocumentModal";
import { captureMessage } from "@/errorHandling/errors";
import { OverflowTooltip } from "@/components/OverflowTooltip";

const DocumentEntry_DocumentFragment = graphql(`
    fragment DocumentEntry_DocumentFragment on ContractDocument {
        id
        filename
        url
        createdAt
        isPublic
        ...DocumentTag_DocumentFragment
        ...EditDocumentModal_DocumentFragment
        ...DeleteDocumentModal_DocumentFragment
    }
`);

interface DocumentEntryProps {
    document: FragmentType<typeof DocumentEntry_DocumentFragment>;
    canEdit: boolean;
}

export const DocumentEntry: React.FC<DocumentEntryProps> = (props) => {
    const document = getFragmentData(DocumentEntry_DocumentFragment, props.document);
    const { formatMessage } = useIntl();

    // menu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    // modals
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    if (!document.url) {
        captureMessage("DocumentEntry: document.url is null, but expected to exist", {
            extra: { documentId: document.id },
        });
    }

    return (
        <Stack p={2} direction="row" gap={1} component={Paper} elevation={1} alignItems="center">
            <Stack mr="auto" gap={1}>
                <Stack direction="row" gap={1} alignItems="center" width="100%" maxWidth="600px">
                    {!document.isPublic && (
                        <Tooltip
                            size="xlarge"
                            title={
                                <FormattedMessage defaultMessage="Only contract owners and administrators have access to this file" />
                            }
                        >
                            <Lock color="secondary" />
                        </Tooltip>
                    )}
                    <OverflowTooltip title={document.filename}>
                        {/* TODO: theme color */}
                        <Typography variant="textSm" color="#272A31" fontWeight={500} noWrap>
                            {document.filename}
                        </Typography>
                    </OverflowTooltip>
                </Stack>
                <Stack direction="row" gap={1} alignItems="center">
                    <DocumentTag document={document} />
                    <Typography variant="textXs" color="textTextHelper">
                        <FormattedMessage
                            defaultMessage="Added {date}"
                            values={{ date: dayjs.utc(document.createdAt).format("DD MMM YYYY") }}
                        />
                    </Typography>
                </Stack>
            </Stack>
            <Button size="xsmall" color="secondary" onClick={() => openFileInNewTab(document.url)}>
                <FormattedMessage defaultMessage="View" />
            </Button>

            {/* Menu button */}
            <IconButton size="xsmall" color="secondary" onClick={(e) => setAnchorEl(e.currentTarget)}>
                <DotsHorizontal />
            </IconButton>
            <RiddleMenu
                sx={{ width: "183px" }}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                entries={[
                    {
                        label: formatMessage({ defaultMessage: "Edit" }),
                        disabled: !props.canEdit,
                        onClick: () => {
                            setEditModal(true);
                            setAnchorEl(null);
                        },
                    },
                    {
                        label: formatMessage({ defaultMessage: "Download" }),
                        onClick: () => openFileInNewTab(document.url),
                        divider: true,
                    },
                    {
                        label: formatMessage({ defaultMessage: "Delete" }),
                        icon: { pos: "start", component: <Trash /> },
                        disabled: !props.canEdit,
                        danger: true,
                        onClick: () => {
                            setDeleteModal(true);
                            setAnchorEl(null);
                        },
                    },
                ]}
            />

            {/* Modals */}
            <EditDocumentModal document={document} open={editModal} onClose={() => setEditModal(false)} />
            <DeleteDocumentModal document={document} open={deleteModal} onClose={() => setDeleteModal(false)} />
        </Stack>
    );
};

function openFileInNewTab(url: string | null | undefined) {
    if (!url) return;
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.rel = "noopener noreferrer";
    a.click();
}
