import { useQuery } from "@apollo/client";
import { ShapeIcon } from "@ignite-analytics/components";
import { CheckCircle, Timer, XCircle } from "@ignite-analytics/icons";
import { Grid2 as Grid, Skeleton, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { graphql } from "@/generated";
import { InfoCard } from "./InfoCard";

const ContractStatisticsDocument = graphql(`
    query ContractStatistics($input: ContractTotalOverviewStatisticsInput!) {
        contractTotalOverviewStatistics(input: $input) {
            activeCount
            activeTotalSpend
            expiringSoonCount
            expiringSoonTotalSpend
            recentlyExpiredCount
            recentlyExpiredTotalSpend
        }
    }
`);

interface TotalOverviewCardsProps {
    showMyContracts: boolean;
}

export const TotalOverviewCards: React.FC<TotalOverviewCardsProps> = (props) => {
    const { data, loading, error } = useQuery(ContractStatisticsDocument, {
        variables: {
            input: {
                onlyOwnedContracts: props.showMyContracts,
            },
        },
    });

    return (
        <Grid container spacing={3}>
            <Grid size={4}>
                {loading && <Skeleton variant="rectangular" height={100} />}
                {error && (
                    <Typography>
                        <FormattedMessage defaultMessage="Error loading contract statistics" />
                    </Typography>
                )}
                {data && (
                    <InfoCard
                        title={<FormattedMessage defaultMessage="Active contracts" />}
                        icon={
                            <ShapeIcon color="success">
                                <CheckCircle />
                            </ShapeIcon>
                        }
                        numberOfContracts={data.contractTotalOverviewStatistics.activeCount}
                        spend={data.contractTotalOverviewStatistics.activeTotalSpend}
                    />
                )}
            </Grid>
            <Grid size={4}>
                {loading && <Skeleton variant="rectangular" height={100} />}
                {error && (
                    <Typography>
                        <FormattedMessage defaultMessage="Error loading contract statistics" />
                    </Typography>
                )}
                {data && (
                    <InfoCard
                        title={<FormattedMessage defaultMessage="Contracts expiring next 6 months" />}
                        icon={
                            <ShapeIcon color="warning">
                                <Timer />
                            </ShapeIcon>
                        }
                        numberOfContracts={data.contractTotalOverviewStatistics.expiringSoonCount}
                        spend={data.contractTotalOverviewStatistics.expiringSoonTotalSpend}
                    />
                )}
            </Grid>
            <Grid size={4}>
                {loading && <Skeleton variant="rectangular" height={100} />}
                {error && (
                    <Typography>
                        <FormattedMessage defaultMessage="Error loading contract statistics" />
                    </Typography>
                )}
                {data && (
                    <InfoCard
                        title={<FormattedMessage defaultMessage="Contracts expired last 6 months" />}
                        icon={
                            <ShapeIcon color="error">
                                <XCircle />
                            </ShapeIcon>
                        }
                        numberOfContracts={data.contractTotalOverviewStatistics.recentlyExpiredCount}
                        spend={data.contractTotalOverviewStatistics.recentlyExpiredTotalSpend}
                    />
                )}
            </Grid>
        </Grid>
    );
};
