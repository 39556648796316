import { useQuery } from "@apollo/client";
import { Alert, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Bar, BarChart, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer, XAxis, YAxis } from "recharts";

import { useCompanyCurrency } from "@/contexts/CompanyCurrencyContext";
import { graphql } from "@/generated";
import { DateInterval } from "@/generated/graphql";
import { detailRoute } from "@/routes/detail/$id";
import { ChartTooltip } from "./ChartTooltip";
import { getFullLabel, getShortLabel } from "./helpers";

type Props = {
    interval: DateInterval;
};

const DATA_KEY = "value";
const DATA_YEAR_KEY = "year";
const contentHeight = 250;
export type SpendDevelopmentPayload = {
    label: string;
    [DATA_KEY]: number;
    [DATA_YEAR_KEY]: string;
};

const ContractSpendDevelopmentDocument = graphql(`
    query ContractSpendDevelopment($id: ID!, $spendDevelopmentInput: GetContractSpendDevelopmentInput!) {
        getContract(id: $id) {
            spendDevelopment(input: $spendDevelopmentInput) {
                results {
                    label
                    thisPeriod {
                        periodStartDate
                        value
                    }
                }
            }
        }
    }
`);

export const SpendDevelopmentChart: React.FC<Props> = ({ interval }) => {
    const { formatCompact, format } = useCompanyCurrency();
    const { id: contractId } = detailRoute.useParams();

    const theme = useTheme();
    const labelColor = theme.palette.text.textHelper;
    const gridColor = theme.palette.divider;
    const barColor = theme.palette.secondary.light;

    const { data, loading, error } = useQuery(ContractSpendDevelopmentDocument, {
        variables: {
            id: contractId,
            spendDevelopmentInput: {
                interval,
                period: {
                    relative: {
                        // Last 5 years
                        period: "YEAR",
                        length: 5,
                        offset: -1,
                    },
                },
            },
        },
    });

    const { formatMessage } = useIntl();

    if (loading) return <Skeleton variant="rectangular" height={contentHeight} />;
    if (error)
        return (
            <Stack justifyContent="center" alignItems="center" height={contentHeight}>
                <Alert severity="error">{formatMessage({ defaultMessage: "Something went wrong" })}</Alert>
            </Stack>
        );

    let totalSpend = 0;
    const values = data?.getContract?.spendDevelopment?.results.map((result) => {
        totalSpend += result.thisPeriod.value;
        return {
            label: result.label,
            [DATA_KEY]: result.thisPeriod.value,
            [DATA_YEAR_KEY]: result.thisPeriod.periodStartDate.slice(0, 4),
        };
    });

    if (data?.getContract?.spendDevelopment?.results?.length && totalSpend === 0) {
        return (
            <Stack justifyContent="center" alignItems="center" height={contentHeight}>
                <Typography variant="textMd">
                    <FormattedMessage defaultMessage="No spend data connected to this contract" />
                </Typography>
            </Stack>
        );
    }

    return (
        <ResponsiveContainer minHeight={contentHeight}>
            <BarChart barGap={0} data={values} margin={{ left: 10, right: 10 }}>
                <YAxis
                    fontSize="14px"
                    color={labelColor}
                    axisLine={{ stroke: "transparent" }}
                    tickLine={false}
                    tickFormatter={(value: number) => formatCompact(value)}
                    padding={{ top: 10, bottom: 10 }}
                />
                <XAxis
                    fontSize="14px"
                    color={labelColor}
                    dataKey="label"
                    tickFormatter={(l) => getShortLabel(l, interval)}
                    axisLine={false}
                    tickLine={false}
                />
                <RechartsTooltip<number, string>
                    content={
                        <ChartTooltip
                            tooltipFormatter={(value) => ({ value: format(value) })}
                            labelFormatter={(payload) => {
                                return getFullLabel(payload, interval);
                            }}
                        />
                    }
                    cursor={false}
                />
                <CartesianGrid color={gridColor} strokeDasharray="1 3" vertical={false} /> {/* Add grid lines */}
                <Bar animationDuration={400} type="monotone" fill={barColor} dataKey={DATA_KEY} />
            </BarChart>
        </ResponsiveContainer>
    );
};
