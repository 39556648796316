import { TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { Blank } from "../Blank";
import { UpdateContractFormParams } from "@/types";

const TextList_ContractCustomFieldTextListFragment = graphql(`
    fragment TextList_ContractCustomFieldTextListFragment on ContractCustomFieldTextList {
        id
        name
        textList
    }
`);

interface TextListProps {
    data: FragmentType<typeof TextList_ContractCustomFieldTextListFragment>;
    edit?: boolean;
}

export const TextList: React.FC<TextListProps> = (props) => {
    const data = getFragmentData(TextList_ContractCustomFieldTextListFragment, props.data);
    const { control } = useFormContext<UpdateContractFormParams>();
    const { formatMessage } = useIntl();

    if (props.edit) {
        return (
            <Controller
                name={`customfields/${data.id}`}
                control={control}
                defaultValue={{ id: data.id, textList: data.textList }}
                render={({ field }) => (
                    <TextField
                        placeholder={formatMessage({ defaultMessage: "Separated by comma" })}
                        fullWidth
                        value={field.value.textList?.join(", ") ?? ""}
                        onChange={(event) => {
                            const parts = event.target.value.split(",").map((part) => part.trim());
                            field.onChange({ id: data.id, textList: parts });
                        }}
                    />
                )}
            />
        );
    }

    if (!data.textList || data.textList.length === 0) {
        return <Blank />;
    }

    const joined = data.textList.join(", ");
    return <Typography variant="textMd">{joined}</Typography>;
};
