import { Controller, useFormContext } from "react-hook-form";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { GroupSelect } from "@/components/TreePicker";
import { Blank } from "../Blank";
import { GroupChip } from "@/components/TreePicker/components/GroupChip";
import { UpdateContractFormParams } from "@/types";

const Group_ContractCustomFieldGroupFragment = graphql(`
    fragment Group_ContractCustomFieldGroupFragment on ContractCustomFieldGroup {
        id
        name
        type
        externalReferenceId
        group {
            id
            name
        }
    }
`);

interface GroupProps {
    data: FragmentType<typeof Group_ContractCustomFieldGroupFragment>;
    edit?: boolean;
}

export const Group: React.FC<GroupProps> = (props) => {
    const data = getFragmentData(Group_ContractCustomFieldGroupFragment, props.data);
    const { control } = useFormContext<UpdateContractFormParams>();

    if (props.edit) {
        return (
            <Controller
                name={`customfields/${data.id}`}
                control={control}
                defaultValue={{ id: data.id, referenceId: data.group?.id }}
                render={({ field }) => (
                    <GroupSelect
                        multiple={false}
                        id={data.externalReferenceId ?? ""}
                        value={field.value.referenceId ? [field.value.referenceId] : []}
                        onChange={(val) => {
                            if (val.length === 0) {
                                field.onChange({ id: data.id, referenceId: null });
                                return;
                            }
                            field.onChange({ id: data.id, referenceId: val[0] });
                        }}
                    />
                )}
            />
        );
    }

    if (!data.group) {
        return <Blank />;
    }

    return <GroupChip groups={[data.group]} />;
};
