import { Stack } from "@mui/material";

import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Contract } from "@/types";

import { ContractContactCard } from "./ContractContactCard";
import { DocumentsCard } from "./DocumentsCard";

import { AlertsCard } from "./AlertsCard";
import { NotesCard } from "./NotesCard";

import { AccessCard } from "./AccessCard";
import { ContractResponsibleCard } from "./ContractResponsibleCard";
import { getFragmentData, graphql, FragmentType } from "@/generated";

const ContractSidebar_ContractFragment = graphql(`
    fragment ContractSidebar_ContractFragment on Contract {
        ...ContractResponsibleCard_ContractFragment
        ...AlertsCard_ContractFragment
        ...AccessCard_ContractFragment
        ...ContractContactCard_ContractFragment
    }
`);

interface ContractSidebarProps {
    onContractChange: (contract: Contract) => void;
    contract: FragmentType<typeof ContractSidebar_ContractFragment>;
    canEdit: boolean;
    editMode: boolean;
}

export const ContractSidebar: React.FC<ContractSidebarProps> = ({ canEdit, editMode, onContractChange, ...props }) => {
    const documentTagFeatureToggle = useFeatureToggle("contracts-document-tags");
    const contract = getFragmentData(ContractSidebar_ContractFragment, props.contract);

    return (
        <Stack gap={2} minWidth="318px" maxWidth="1157px">
            <ContractResponsibleCard contract={contract} editMode={editMode} />
            <AccessCard contract={contract} canEdit={canEdit} editMode={editMode} onContractChange={onContractChange} />
            <ContractContactCard contract={contract} editMode={editMode} />
            {/* // Show document card in sidebar if preview is not toggled on */}
            {!documentTagFeatureToggle && <DocumentsCard editMode={editMode} canEdit={canEdit} />}
            <AlertsCard editMode={editMode} contract={contract} />
            <NotesCard />
        </Stack>
    );
};
