import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { track } from "@ignite-analytics/track";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { ContractDocumentTag } from "@/generated/graphql";
import { useSnackbar } from "@/contexts/useSnackbar";
import { captureMessage } from "@/errorHandling/errors";
import { DocumentEntries } from "../EditDocumentEntry";
import { CloseButton } from "../CloseButton";

const EditDocumentModal_DocumentFragment = graphql(`
    fragment EditDocumentModal_DocumentFragment on ContractDocument {
        id
        filename
        tag
        isPublic
    }
`);

const EditDocumentModal_UpdateDocumentMutation = graphql(`
    mutation EditDocumentModal_UpdateDocumentMutation($input: UpdateDocumentRequest!) {
        updateDocument(input: $input) {
            ...EditDocumentModal_DocumentFragment
        }
    }
`);

interface EditDocumentModalProps {
    open: boolean;
    onClose: () => void;
    document: FragmentType<typeof EditDocumentModal_DocumentFragment>;
}
export const EditDocumentModal: React.FC<EditDocumentModalProps> = (props) => {
    const document = getFragmentData(EditDocumentModal_DocumentFragment, props.document);
    const { postSnackbar } = useSnackbar();

    const [updateDocument, { loading }] = useMutation(EditDocumentModal_UpdateDocumentMutation, {
        onCompleted: () => {
            postSnackbar({
                message: <FormattedMessage defaultMessage="Document updated" />,
                severity: "success",
            });
            handleClose();
        },
        onError: (error) => {
            captureMessage("Failed to update document", { extra: { error } });
            postSnackbar({
                message: <FormattedMessage defaultMessage="Failed to update document" />,
                severity: "error",
            });
            handleClose();
        },
    });

    const handleClose = () => {
        props.onClose();
        setDocumentType(document.tag ?? null);
        setIsPublic(document.isPublic);
    };

    const [documentType, setDocumentType] = useState<ContractDocumentTag | null>(document.tag ?? null);
    const [isPublic, setIsPublic] = useState(document.isPublic);

    const hasChanges = documentType !== document.tag || isPublic !== document.isPublic;

    return (
        <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <Stack gap={1}>
                        <Typography variant="textXl">
                            <FormattedMessage defaultMessage="Edit document" />
                        </Typography>
                        <Typography variant="textSm" color="textTextBody">
                            <FormattedMessage defaultMessage="Edit document type or access to document" />
                        </Typography>
                    </Stack>
                </Stack>
                <CloseButton onClose={handleClose} />
            </DialogTitle>

            <Divider sx={{ py: 2, mx: 2 }} />

            <DialogContent>
                <DocumentEntries
                    loading={loading}
                    entries={[
                        {
                            key: document.id,
                            filename: document.filename,
                            documentType,
                            isPublic,
                            setIsPublic,
                            setDocumentType,
                        },
                    ]}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="secondary" sx={{ mr: 1 }}>
                    <FormattedMessage defaultMessage="Close" />
                </Button>
                <LoadingButton
                    loading={loading}
                    onClick={() => {
                        const input = {
                            id: document.id,
                            tag: documentType !== document.tag ? { value: documentType } : undefined,
                            isPublic: isPublic !== document.isPublic ? isPublic : undefined,
                        };
                        track("Contract details: Document updated", input);
                        updateDocument({ variables: { input } });
                    }}
                    color="primary"
                    disabled={!hasChanges}
                >
                    <FormattedMessage defaultMessage="Save changes" />
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
