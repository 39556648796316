import { Button, Stack } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface ClearFilterButtonProps {
    onClear: () => void;
    disabled?: boolean;
}

export const ClearFilterButton: React.FC<ClearFilterButtonProps> = ({ onClear, disabled }) => {
    return (
        <Stack direction="row" justifyContent="space-between">
            <Button onClick={onClear} color="ghostPrimary" disabled={disabled}>
                <FormattedMessage defaultMessage="Clear all" />
            </Button>
        </Stack>
    );
};
