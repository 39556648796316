import React from "react";
import { Button, Chip, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { InformationCircle, Plus } from "@ignite-analytics/icons";
import { FragmentType, getFragmentData, graphql } from "@/generated";

const DocumentSectionHeader_ContractFragment = graphql(`
    fragment DocumentSectionHeader_ContractFragment on Contract {
        documents {
            id
            url
        }
    }
`);

interface DocumentSectionHeaderProps {
    canAddNew: boolean;
    onClickAddNew: () => void;
    contract: FragmentType<typeof DocumentSectionHeader_ContractFragment>;
}

export const DocumentSectionHeader: React.FC<DocumentSectionHeaderProps> = (props) => {
    const contract = getFragmentData(DocumentSectionHeader_ContractFragment, props.contract);

    // documents that can be viewed
    const countAccessible = contract.documents.filter((doc) => doc.url !== null).length;

    // private documents that the current user does not have access to
    // const countInaccessible = contract.documents.length - countAccessible;
    const countInaccessible = contract.documents.length - countAccessible;
    return (
        <Stack gap={2.5}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" gap={1.5} alignItems="center">
                    <Typography variant="textLg" fontWeight={500} color="textPrimary">
                        <FormattedMessage defaultMessage="Documents" />
                    </Typography>
                    <Chip size="large" color="infoGray" label={countAccessible} />
                </Stack>
                <Button
                    size="xsmall"
                    color="secondary"
                    startIcon={<Plus />}
                    onClick={props.onClickAddNew}
                    disabled={!props.canAddNew}
                >
                    <FormattedMessage defaultMessage="Add new" />
                </Button>
            </Stack>
            {countInaccessible > 0 && (
                <Stack alignSelf="start" gap={1} alignItems="center" direction="row">
                    <InformationCircle />
                    <Typography variant="textSm" color="textTextBody">
                        <FormattedMessage
                            defaultMessage="This contract has {count} restricted documents that you do not have access to."
                            values={{ count: countInaccessible }}
                        />
                    </Typography>
                </Stack>
            )}
        </Stack>
    );
};
