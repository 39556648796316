import { Autocomplete, Chip, Stack, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { useSearchTagsV2 } from "@/hooks/useSearchTagsV2";
import { Blank } from "../Blank";
import { UpdateContractFormParams } from "@/types";

const Tag_ContractCustomFieldTagFragment = graphql(`
    fragment Tag_ContractCustomFieldTagFragment on ContractCustomFieldTag {
        id
        tag
        name
    }
`);

interface TagProps {
    data: FragmentType<typeof Tag_ContractCustomFieldTagFragment>;
    edit?: boolean;
}

export const Tag: React.FC<TagProps> = (props) => {
    const data = getFragmentData(Tag_ContractCustomFieldTagFragment, props.data);
    const { control } = useFormContext<UpdateContractFormParams>();
    const tags = useSearchTagsV2(data.id);
    const { formatMessage } = useIntl();

    if (props.edit) {
        return (
            <Controller
                name={`customfields/${data.id}`}
                control={control}
                defaultValue={{ id: data.id, tag: data.tag }}
                render={({ field }) => (
                    <Autocomplete
                        loading={tags.loading}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    placeholder={formatMessage({ defaultMessage: "Select one" })}
                                    InputProps={{ ...params.InputProps, size: "small" }}
                                />
                            );
                        }}
                        options={tags.options}
                        fullWidth
                        value={field.value?.tag}
                        onChange={(_, value) => {
                            field.onChange({ id: data.id, tag: value });
                        }}
                    />
                )}
            />
        );
    }

    if (!data.tag) {
        return <Blank />;
    }
    return (
        <Stack direction="row" alignItems="center" justifyItems="start">
            <Chip label={data.tag} />
        </Stack>
    );
};
