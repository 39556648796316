import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import { FormattedMessage } from "react-intl";
import { EllipticTypography } from "@/components/EllipticTypography";
import { GroupChip } from "@/components/TreePicker/components/GroupChip";
import { graphql } from "@/generated";
import { TreeSelect } from "./components/TreeSelect";
import { LeafNode } from "./types";

const GroupSelect_GetGroupsByGroupStructure = graphql(`
    query GroupSelect_GetGroupsByGroupStructure($input: GetAllGroupsByGroupStructureInput!) {
        getAllGroupsByGroupStructure(input: $input) {
            groups {
                id
                level
                name
                parentId
            }
        }
    }
`);

interface Props {
    id: string;
    value: string[];
    onChange: (value: string[]) => void;
    multiple?: boolean;
}

export const GroupSelect: React.FC<Props> = ({ id, value, onChange, multiple }) => {
    const { data } = useQuery(GroupSelect_GetGroupsByGroupStructure, {
        skip: !id,
        variables: {
            input: {
                id,
            },
        },
    });

    const allCategories: LeafNode[] = useMemo(() => {
        if (!data) {
            return [];
        }

        return data.getAllGroupsByGroupStructure.groups.map((category) => {
            return {
                id: category.id,
                level: category.level,
                label: category.name,
                parentId: category.parentId,
                children: [],
            };
        });
    }, [data]);

    const buttonContent = useMemo(() => {
        if (!value.length) {
            return (
                <EllipticTypography variant="textMd" width="100%" textAlign="start">
                    <FormattedMessage defaultMessage="Select categories" />
                </EllipticTypography>
            );
        }

        const selectedCategories = data?.getAllGroupsByGroupStructure.groups
            .filter((category) => value.includes(category.id))
            .map((category) => {
                return {
                    id: category.id,
                    name: category.name,
                };
            });

        return <GroupChip groups={selectedCategories} />;
    }, [data, value]);

    return (
        <TreeSelect
            multiSelect={!!multiple}
            items={allCategories}
            value={value}
            onChange={onChange}
            buttonContent={buttonContent}
        />
    );
};
