import { X } from "@ignite-analytics/icons";
import { Alert, IconButton, Snackbar as MuiSnackbar, Stack, Typography } from "@mui/material";
import React from "react";
import { Snack } from "@/contexts/useSnackbar/types";

interface SnackbarProps {
    open: boolean;
    snack: Snack | null;
    onClose: () => void;
}

export const Snackbar: React.FC<SnackbarProps> = ({ open, snack, onClose }) => {
    return (
        <MuiSnackbar
            open={open}
            transitionDuration={10}
            autoHideDuration={5000}
            onClose={onClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
            <Alert severity={snack?.severity}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1.25}>
                    <Typography variant="textSm">{snack?.message}</Typography>
                    <IconButton onClick={onClose} size="2xsmall">
                        <X />
                    </IconButton>
                </Stack>
            </Alert>
        </MuiSnackbar>
    );
};
