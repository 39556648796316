import { Stack, Tooltip, Typography } from "@mui/material";
import { TooltipProps as RechartsTooltipProps } from "recharts";

/**
 * PieCharts and BarCharts have different ways of defining the color of the payload from recharts.
 * This functions is safe to use to extract the right fill color
 */
function getPayloadColor(payloadElement: unknown) {
    const payloadElementAsOptionals = payloadElement as { color?: string; payload?: { fill?: string } };
    if (payloadElementAsOptionals.color) {
        return payloadElementAsOptionals.color;
    }
    if ("payload" in payloadElementAsOptionals && payloadElementAsOptionals.payload?.fill) {
        return payloadElementAsOptionals.payload.fill;
    }
}

/**
 * Vendored from apps/spend/src/components/ChartTooltip/index.tsx and simplified
 */
type Props = {
    labelFormatter: (payload: Record<string, string | number>) => string;
    tooltipFormatter: (
        value: number,
        label?: string,
        payload?: Record<string, string | number>
    ) => { value: string; valueLabel?: string };
} & RechartsTooltipProps<number, string>;

export const ChartTooltip: React.FC<Props> = ({ labelFormatter, tooltipFormatter, ...rechartProps }) => {
    const { active, payload, label: rawLabel, coordinate } = rechartProps;

    if (!payload?.length) {
        return false;
    }

    const firstPayload = payload[0].payload;
    const labelOrDefault = rawLabel ?? firstPayload.name;
    const label = firstPayload ? labelFormatter(firstPayload) : labelOrDefault;
    return (
        <Tooltip
            placement="top"
            open={active}
            title={
                <Stack bgcolor="white" direction="column" spacing={1} p={1} width="100%">
                    <Typography variant="textSm" fontWeight={600}>
                        {label}
                    </Typography>
                    {payload.map((payloadElement) => {
                        const color = getPayloadColor(payloadElement);
                        const { value, valueLabel } = tooltipFormatter(
                            payloadElement.value ?? 0,
                            payloadElement.name,
                            payloadElement.payload
                        );
                        return (
                            <Stack key={`${payloadElement.dataKey}`} direction="row" alignItems="center" gap={1}>
                                <div
                                    style={{
                                        borderRadius: 5,
                                        width: 5,
                                        height: 35,
                                        backgroundColor: color,
                                    }}
                                />
                                <Stack>
                                    <Typography variant="textXs">{valueLabel}</Typography>
                                    <Typography variant="textMd">{value}</Typography>
                                </Stack>
                            </Stack>
                        );
                    })}
                </Stack>
            }
        >
            <div style={{ position: "absolute", left: coordinate?.x ?? 0, top: coordinate?.y ?? 0 }} />
        </Tooltip>
    );
};
