import { Grid2 as Grid, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import { Blank } from "./components/Blank";

type ContractFieldProps = {
    label: string;
    size: number;
    // multiple
    children?: React.ReactNode;
    blank?: boolean;
    edit?: boolean;
    loading?: boolean;
};

export const ContractField = ({ blank, label, size, children, edit, loading }: ContractFieldProps) => {
    // if 2 children are passed -> first is rendered in display mode, second in edit mode
    // otherwise, the same child is rendered in both modes
    const childNodes = React.Children.toArray(children);
    if (![0, 1, 2].includes(childNodes.length)) {
        // throw an error here ot make life easier for the developer
        throw new Error(
            `ContractField expects 0, 1 or 2 children, but got ${childNodes.length} children. (label=${label})`
        );
    }
    let displayNode = childNodes[0];
    let editNode = childNodes.length === 2 ? childNodes[1] : displayNode;

    if (typeof displayNode === "string") {
        displayNode = <Typography variant="textMd">{displayNode}</Typography>;
    }
    if (typeof editNode === "string") {
        editNode = <Typography variant="textMd">{editNode}</Typography>;
    }

    return (
        <Grid size={size}>
            <Stack gap={1} alignItems="stretch" height="100%">
                <Typography variant="textMd" fontWeight={500}>
                    {label}
                </Typography>
                <Stack height="100%" alignItems="center" direction="row">
                    {loading ? (
                        <Skeleton width={250} variant="text" height={40} />
                    ) : edit ? (
                        editNode
                    ) : blank ? (
                        <Blank />
                    ) : (
                        displayNode
                    )}
                </Stack>
            </Stack>
        </Grid>
    );
};
