import { useIntl } from "react-intl";

import { ContractDocuments } from "@/pages/detail/components/ContractSidebar/DocumentsCard/components/ContractDocuments";
import { useDocuments } from "@/pages/detail/components/ContractSidebar/DocumentsCard/components/ContractDocuments/hooks";

import { detailRoute } from "@/routes/detail/$id";
import { SidebarCard } from "../SidebarCard";

interface DocumentsCardProps {
    editMode: boolean;
    canEdit: boolean;
}

export const DocumentsCard: React.FC<DocumentsCardProps> = ({ editMode, canEdit }) => {
    const { id } = detailRoute.useParams();
    const { formatMessage } = useIntl();
    const documents = useDocuments(id);

    return (
        <SidebarCard label={formatMessage({ defaultMessage: "Documents" })}>
            <ContractDocuments
                upload={async (files) => {
                    await documents.upload(files);
                    await documents.refetch();
                }}
                edit={editMode}
                canEdit={canEdit}
                documents={documents.data}
                onDelete={(fileId) => documents.delete(fileId)}
                onToggleVisibility={(fileId, checked) => documents.toggleVisibility(fileId, checked)}
            />
        </SidebarCard>
    );
};
