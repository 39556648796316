import { FormattedMessage } from "react-intl";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { DateActivity } from "./DateActivity";

const RenewalDateActivity_RenewalDateContractActivity = graphql(`
    fragment RenewalDateActivity_RenewalDateContractActivity on RenewalDateContractActivity {
        renewalDate
        prevRenewalDate
    }
`);

interface RenewalDateActivityProps {
    activity: FragmentType<typeof RenewalDateActivity_RenewalDateContractActivity>;
}

export const RenewalDateActivity: React.FC<RenewalDateActivityProps> = (props) => {
    const activity = getFragmentData(RenewalDateActivity_RenewalDateContractActivity, props.activity);

    return (
        <DateActivity
            title={<FormattedMessage defaultMessage="Renewal Date" />}
            date={activity.renewalDate}
            prevDate={activity.prevRenewalDate}
        />
    );
};
