import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { DotsVertical, FileArrowDown, InformationCircle, Trash } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Button, Checkbox, Grid2 as Grid, IconButton, Link as MuiLink, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { EmtpyCardContent } from "@/components/EmptyCardContent";
import { DocumentDrop } from "../../../../../../../components/DocumentDrop";
import DocumentViewerSideDrawer from "../../../../../../../components/DocumentViewer/DocumentViewerSideDrawer";
import { InformationTooltip } from "../../../../../../../components/InformationTooltip";
import { OverflowTooltip } from "../../../../../../../components/OverflowTooltip";
import { Document } from "./hooks";
import { RiddleMenu } from "@/components/RiddleMenu";

const TITLE_COLUMN_SIZE = 20;
const ACCESS_COLUMN_SIZE = 3;
const ACTIONS_COLUMN_SIZE = 1;
interface Props {
    edit: boolean;
    canEdit: boolean;
    documents: Document[];
    onToggleVisibility: (id: string, checked: boolean) => Promise<void>;
    onDelete: (id: string) => Promise<void>;
    upload: (files: File[]) => Promise<void>;
}

const RestrictedDocumentsNotice = ({
    numRestrictedDocumentsWithoutAccess,
}: {
    numRestrictedDocumentsWithoutAccess: number;
}) => (
    <Stack pt={1}>
        <Stack direction="row" alignItems="center" gap={1}>
            <InformationCircle color="secondary" />
            <Typography variant="textSm" color="textTextHelper">
                <FormattedMessage
                    defaultMessage="This contract has {numRestrictedDocumentsWithoutAccess} restricted documents that you do not have access to."
                    values={{ numRestrictedDocumentsWithoutAccess }}
                />
            </Typography>
        </Stack>
    </Stack>
);

export const ContractDocuments = ({ upload, documents, onToggleVisibility, onDelete, edit, canEdit }: Props) => {
    const visibleDocuments = documents.filter((doc) => doc.url !== null);
    const [focusedRow, setFocusedRow] = useState<Document | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { formatMessage } = useIntl();

    const documentPreviewFeatureToggle = useFeatureToggle("contracts-document-preview");

    const [attatchmentPreview, setAttatchmentPreview] = useState<Document | undefined>(undefined);

    const handleDownloadFileFromUrl = (url: string | null | undefined) => {
        if (!url) {
            return;
        }
        const a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.rel = "noopener noreferrer";
        a.click();
    };
    const onCloseFilePreview = () => {
        setAttatchmentPreview(undefined);
    };

    const numRestrictedDocumentsWithoutAccess = documents.filter((doc) => !doc.isPublic && !doc.url).length;

    if (visibleDocuments.length === 0) {
        return (
            <Stack spacing={2}>
                <EmtpyCardContent title={formatMessage({ defaultMessage: "No documents" })} />
                {numRestrictedDocumentsWithoutAccess > 0 && (
                    <RestrictedDocumentsNotice
                        numRestrictedDocumentsWithoutAccess={numRestrictedDocumentsWithoutAccess}
                    />
                )}
                <DocumentDrop
                    maxFileSizeMB={30}
                    disabled={!canEdit}
                    onChange={async (files) => {
                        const filesArray = Array.from(files);
                        await upload(filesArray);
                    }}
                />
            </Stack>
        );
    }

    return (
        <>
            <Grid container columns={24} rowGap={1}>
                <Grid size={TITLE_COLUMN_SIZE}>
                    <Stack>
                        <Typography fontWeight="500" variant="textSm">
                            <FormattedMessage defaultMessage="File name" />
                        </Typography>
                    </Stack>
                </Grid>
                <Grid size={ACCESS_COLUMN_SIZE}>
                    <Stack direction="row" alignItems="center" justifyContent="start">
                        <Stack gap={0.75} direction="row" alignItems="center" justifyContent="start">
                            <Typography fontWeight="500" variant="textSm">
                                <FormattedMessage defaultMessage="Public" />
                            </Typography>
                            <InformationTooltip
                                content={
                                    <FormattedMessage defaultMessage="Here you can view the document's access level: public or private" />
                                }
                            />
                        </Stack>
                    </Stack>
                </Grid>
                {/* Content */}
                {visibleDocuments.map((doc) => (
                    <React.Fragment key={doc.id}>
                        <Grid size={TITLE_COLUMN_SIZE}>
                            <Stack
                                sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                <OverflowTooltip size="small" title={doc.filename}>
                                    {documentPreviewFeatureToggle ? (
                                        <Button
                                            // regular left click: open preview
                                            // ctrl + left click: open in new tab
                                            // middle click: open in new tab
                                            onAuxClick={() => {
                                                track("Contract Details: Document opened", { middleClick: true });
                                                window.open(doc.url!, "_blank");
                                            }}
                                            onClick={(e) => {
                                                if (e.ctrlKey || e.metaKey) {
                                                    track("Contract Details: Document opened", { meta: true });
                                                    window.open(doc.url!, "_blank");
                                                } else {
                                                    track("Contract Details: Document opened", { preview: true });
                                                    setAttatchmentPreview(doc);
                                                }
                                            }}
                                            color="linkPrimary"
                                        >
                                            {doc.filename}
                                        </Button>
                                    ) : (
                                        <MuiLink
                                            onClick={() => track("Contract Details: File downloaded")}
                                            href={doc.url!}
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            {doc.filename}
                                        </MuiLink>
                                    )}
                                </OverflowTooltip>
                            </Stack>
                        </Grid>

                        <Grid size={ACCESS_COLUMN_SIZE} justifyContent="end" alignItems="end">
                            <Stack justifyContent="center" alignItems="start" height="100%">
                                <Checkbox
                                    disabled={!edit || !canEdit}
                                    defaultChecked={doc.isPublic}
                                    onChange={(_, checked) => onToggleVisibility(doc.id, checked)}
                                />
                            </Stack>
                        </Grid>
                        <Grid size={ACTIONS_COLUMN_SIZE} justifyContent="end">
                            {edit && (
                                <Stack justifyContent="center" alignItems="end" height="100%">
                                    <IconButton
                                        onClick={(e) => {
                                            setAnchorEl(e.currentTarget);
                                            setFocusedRow(doc);
                                        }}
                                        size="2xsmall"
                                    >
                                        <DotsVertical />
                                    </IconButton>
                                </Stack>
                            )}
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
            {numRestrictedDocumentsWithoutAccess > 0 && (
                <RestrictedDocumentsNotice numRestrictedDocumentsWithoutAccess={numRestrictedDocumentsWithoutAccess} />
            )}
            <Stack>
                <DocumentDrop
                    maxFileSizeMB={30}
                    disabled={!canEdit}
                    onChange={async (files) => {
                        const filesArray = Array.from(files);
                        await upload(filesArray);
                    }}
                />
            </Stack>

            <RiddleMenu
                anchorEl={anchorEl}
                sx={{ width: 240 }}
                entries={[
                    {
                        label: formatMessage({ defaultMessage: "Delete" }),
                        danger: true,
                        onClick: async () => onDelete(focusedRow!.id),
                        icon: { pos: "start", component: <Trash /> },
                    },
                    {
                        label: formatMessage({ defaultMessage: "Download" }),
                        onClick: () => handleDownloadFileFromUrl(focusedRow?.url),
                        icon: { pos: "start", component: <FileArrowDown /> },
                    },
                ]}
                onClose={() => setAnchorEl(null)}
            />

            <DocumentViewerSideDrawer onClose={onCloseFilePreview} doc={attatchmentPreview} />
        </>
    );
};
