import { Stack, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

type RenderActivityProps = {
    action?: "updated" | "uploaded" | "deleted"; // Action type to be displayed in the activity log, defaults to "updated"

    title: ReactNode;
    prev?: ReactNode;
    value: ReactNode;
};
export const RenderActivity: React.FC<RenderActivityProps> = ({
    title,
    prev,
    value,
    action = "updated",
}: RenderActivityProps) => {
    return (
        <Stack direction="row" alignItems="center" gap={1} height="100%">
            <Stack direction="row">
                <Typography variant="textSm" color="textTextHelper">
                    {action === "updated" && <FormattedMessage defaultMessage="Updated" />}
                    {action === "uploaded" && <FormattedMessage defaultMessage="Uploaded" />}
                    {action === "deleted" && <FormattedMessage defaultMessage="Deleted" />}
                </Typography>
                &nbsp;
                <Typography variant="textSm" fontWeight={600}>
                    {title}
                </Typography>
                &nbsp;
                {prev !== undefined && (
                    <Typography variant="textSm" color="textTextHelper">
                        <FormattedMessage defaultMessage="from" />
                    </Typography>
                )}
            </Stack>
            {prev && (
                <>
                    {prev}
                    <Typography variant="textSm" color="textTextHelper">
                        <FormattedMessage defaultMessage="->" />
                    </Typography>
                </>
            )}
            {value}
        </Stack>
    );
};
