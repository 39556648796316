import { Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import { DateRangeFilter } from "./types";
import { ClearFilterButton } from "./ClearFilterButton";

interface DateRangeFilterProps {
    filter: DateRangeFilter;
    onChange: (value: DateRangeFilter) => void;
}

export const DateRange: React.FC<DateRangeFilterProps> = ({ filter, onChange }) => {
    const fromLabel = (
        <Typography variant="body2">
            {filter.label} <FormattedMessage defaultMessage="From" />
        </Typography>
    );
    const toLabel = (
        <Typography variant="body2">
            {filter.label} <FormattedMessage defaultMessage="To" />
        </Typography>
    );

    const handleClearFilter = () => {
        onChange({ ...filter, value: { from: undefined, to: undefined } });
    };

    return (
        <Stack spacing={2} paddingX={2}>
            <DatePicker
                label={fromLabel}
                value={filter.value?.from ? dayjs(filter.value.from) : null}
                onChange={(newValue) =>
                    onChange({
                        ...filter,
                        value: {
                            ...filter.value,
                            from: newValue?.toISOString().split("T")[0] ?? undefined,
                        },
                    })
                }
                slots={{
                    textField: TextField,
                }}
            />
            <DatePicker
                label={toLabel}
                value={filter.value?.to ? dayjs(filter.value.to) : null}
                onChange={(newValue) =>
                    onChange({
                        ...filter,
                        value: {
                            ...filter.value,
                            to: newValue?.toISOString().split("T")[0] ?? undefined,
                        },
                    })
                }
                slots={{
                    textField: TextField,
                }}
            />
            <ClearFilterButton onClear={handleClearFilter} disabled={!filter.value} />
        </Stack>
    );
};
