import { CircleSolid } from "@ignite-analytics/icons";
import { Chip } from "@mui/material";
import { FormattedMessage } from "react-intl";

export const ExpiredMoreThanSixMonthsAgoChip: React.FC = () => {
    return (
        <Chip
            variant="status"
            iconSize="small"
            size="small"
            icon={<CircleSolid />}
            color="error"
            label={<FormattedMessage defaultMessage="Expired" />}
        />
    );
};

export const ExpiredLastSixMonthsChip: React.FC = () => {
    return (
        <Chip
            variant="status"
            iconSize="small"
            size="small"
            icon={<CircleSolid />}
            color="error"
            label={<FormattedMessage defaultMessage="Expired last 6 months" />}
        />
    );
};

export const ExpiringNextSixMonthsChip: React.FC = () => {
    return (
        <Chip
            variant="status"
            iconSize="small"
            color="warning"
            size="small"
            icon={<CircleSolid />}
            label={<FormattedMessage defaultMessage="Expiring next 6 months" />}
        />
    );
};

export const ActiveChip: React.FC = () => {
    return (
        <Chip
            variant="status"
            iconSize="small"
            color="success"
            size="small"
            icon={<CircleSolid />}
            label={<FormattedMessage defaultMessage="Active" />}
        />
    );
};
