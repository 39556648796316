import { createRoute, Navigate } from "@tanstack/react-router";
import { rootRoute } from "./routes";
import { listRoute } from "./list";

// Index route
export const indexRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/",
    component: () => <Navigate to={listRoute.to} search={{ status: "all" }} />,
});
export {
    // Index route
    rootRoute,
};
