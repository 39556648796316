import { FormattedDate, FormattedMessage } from "react-intl";
import { ReactNode } from "react";
import { RenderActivity } from "./RenderActivity";

type DateActivityProps = {
    title: ReactNode;
    date: string | null | undefined;
    prevDate: string | null | undefined;
};

export const DateActivity: React.FC<DateActivityProps> = ({ title, date, prevDate }) => {
    return (
        <RenderActivity
            title={title}
            value={
                date ? (
                    <FormattedDate value={date} day="2-digit" month="short" year="numeric" />
                ) : (
                    <FormattedMessage defaultMessage="-" />
                )
            }
            prev={prevDate ? <FormattedDate value={prevDate} day="2-digit" month="short" year="numeric" /> : undefined}
        />
    );
};
