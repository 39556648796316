import { FormattedMessage } from "react-intl";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { DateActivity } from "./DateActivity";

const StartDateActivity_StartDateContractActivity = graphql(`
    fragment StartDateActivity_StartDateContractActivity on StartDateContractActivity {
        startDate
        prevStartDate
    }
`);

interface StartDateActivityProps {
    activity: FragmentType<typeof StartDateActivity_StartDateContractActivity>;
}

export const StartDateActivity: React.FC<StartDateActivityProps> = (props) => {
    const activity = getFragmentData(StartDateActivity_StartDateContractActivity, props.activity);

    return (
        <DateActivity
            title={<FormattedMessage defaultMessage="Start Date" />}
            date={activity.startDate}
            prevDate={activity.prevStartDate}
        />
    );
};
