import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { useSnackbar } from "@/contexts/useSnackbar";
import { useErrorHandler } from "@/errorHandling/ErrorHandlerContext";
import { makeContractsApiHttp } from "@/http";
import { AccessLevel, AccessLevels } from "../types";

export function useSavePermissions(
    onClose: () => void,
    refetchContracts: () => void,
    customPostSuccessSnackbar?: () => void
) {
    const { handleError } = useErrorHandler();
    const { postSnackbar } = useSnackbar();
    const { formatMessage } = useIntl();
    const http = useMemo(() => makeContractsApiHttp(), []);
    const onSave = useCallback(
        (contractIds: string[], access: AccessLevel) => {
            http.patch(`/contracts/access/bulk`, {
                operations: contractIds.map((contractId) => ({
                    path: `${contractId}/isPrivate`,
                    operation: "replace",
                    value: access === AccessLevels.RESTRICTED,
                })),
            })
                .then(() => {
                    onClose();
                    refetchContracts();
                    if (customPostSuccessSnackbar) {
                        customPostSuccessSnackbar();
                    } else {
                        postSnackbar({
                            message: formatMessage({ defaultMessage: "Access was successfully updated" }),
                            severity: "success",
                        });
                    }
                })
                .catch(handleError);
        },
        [http, handleError, onClose, refetchContracts, customPostSuccessSnackbar, postSnackbar, formatMessage]
    );

    return onSave;
}
