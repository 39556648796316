import { Checkbox } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { FragmentType, getFragmentData, graphql } from "@/generated";

const Boolean_ContractCustomFieldBooleanFragment = graphql(`
    fragment Boolean_ContractCustomFieldBooleanFragment on ContractCustomFieldBoolean {
        id
        name
        boolean
    }
`);

interface BooleanProps {
    data: FragmentType<typeof Boolean_ContractCustomFieldBooleanFragment>;
    edit?: boolean;
}

export const Boolean: React.FC<BooleanProps> = (props) => {
    const data = getFragmentData(Boolean_ContractCustomFieldBooleanFragment, props.data);
    const { control } = useFormContext();

    if (props.edit) {
        return (
            <Controller
                name={`customfields/${data.id}`}
                control={control}
                defaultValue={{ id: data.id, boolean: data.boolean }}
                render={({ field }) => (
                    <Checkbox
                        checked={field.value.boolean}
                        onChange={(ev) => {
                            field.onChange({ id: data.id, boolean: ev.target.checked });
                        }}
                    />
                )}
            />
        );
    }

    return (
        <Checkbox
            size="large"
            sx={{
                cursor: "default",
            }}
            checked={!!data.boolean}
            inputProps={{ readOnly: true }}
            disableRipple
        />
    );
};
