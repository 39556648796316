import { z } from "zod";

export const tokenClaimsSchema = z.object({
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    tenant: z.string(),
    sub: z.string(),
});

export type TokenClaims = z.infer<typeof tokenClaimsSchema>;

export const userSchema = z.object({
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    tenant: z.string(),
    userId: z.string(),
});

export type User = z.infer<typeof userSchema>;
