import { IncludeFilter } from "@ignite-analytics/filters";
import { track } from "@ignite-analytics/track";
import { Button, Paper, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";

import { useContractRelationField } from "@/pages/list/FilterWrappedContractList";
import { detailDataListModalSubRoute, detailRoute } from "@/routes/detail/$id";
import { SpendDevelopmentChart } from "./components/SpendDevelopmentChart";

type Interval = "MONTH" | "YEAR" | "QUARTER";
export const SpendDevelopmentCard: React.FC = () => {
    const { id: contractId } = detailRoute.useParams();
    const [interval, setInterval] = useState<Interval>("MONTH");
    const { formatMessage } = useIntl();
    const contractRelationField = useContractRelationField();

    const handleSeeTransactions = useCallback(() => {
        if (!contractRelationField) return;
        const contractFilter: IncludeFilter = {
            filterType: "includefilter",
            field: contractRelationField.field,
            fieldId: contractRelationField.fieldId,
            include: [contractId],
            type: "keyword",
        };
        track("Contracts: Spend development > See transactions");
        const path = `${window.location.pathname.replace(/\/$/, "")}/${detailDataListModalSubRoute.path}`;
        window.postMessage(
            {
                type: "route-change",
                path,
                from: window.location.pathname,
                state: { filters: [contractFilter] },
            },
            window.location.origin
        );
    }, [contractRelationField, contractId]);

    return (
        <Paper component={Stack} p={2} height="100%" justifyContent="space-between" gap={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="textLg" fontWeight={500}>
                    {formatMessage({ defaultMessage: "Spend development" })}
                </Typography>
                <Stack direction="row" alignItems="center" gap={1}>
                    {contractRelationField && (
                        <Button variant="text" color="ghostPrimary" onClick={handleSeeTransactions}>
                            {formatMessage({ defaultMessage: "See transactions" })}
                        </Button>
                    )}
                    <Tooltip
                        title={formatMessage({
                            defaultMessage: "This chart shows the last 5 years.",
                        })}
                    >
                        <ToggleButtonGroup
                            sx={{ padding: 0 }}
                            size="2xsmall"
                            value={interval}
                            exclusive
                            onChange={(e, value) => {
                                track("Contracts: Spend development interval changed", { interval: value });
                                if (value === interval || value === null) return;
                                setInterval(value);
                            }}
                        >
                            <ToggleButton value="MONTH">{formatMessage({ defaultMessage: "Monthly" })}</ToggleButton>
                            <ToggleButton value="YEAR">{formatMessage({ defaultMessage: "Yearly" })}</ToggleButton>
                        </ToggleButtonGroup>
                    </Tooltip>
                </Stack>
            </Stack>
            <SpendDevelopmentChart interval={interval} />
        </Paper>
    );
};
