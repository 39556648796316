import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
// eslint-disable-next-line import/first
import { useMemo, useCallback } from "react"
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AddDataTableConnectionCriterionInput = {
  dataTableConnectionId: Scalars['String']['input'];
  ignoreOnBlankTarget?: InputMaybe<Scalars['Boolean']['input']>;
  sourceField: Scalars['String']['input'];
  targetField: Scalars['String']['input'];
  type: DataTableConnectionCriterionTypeInput;
};

export type AddDataTableConnectionCriterionResponse = {
  __typename: 'AddDataTableConnectionCriterionResponse';
  dataTableConnection: DataTableConnection;
};

export type AddOrRemoveFieldsFromDataCubeConfigurationInput = {
  addOrRemove: AddOrRemoveInput;
  dataTableId: Scalars['String']['input'];
  fields: Array<Scalars['String']['input']>;
};

export type AddOrRemoveFieldsFromDataCubeConfigurationResponse = {
  __typename: 'AddOrRemoveFieldsFromDataCubeConfigurationResponse';
  DataCubeConfiguration: DataCubeConfiguration;
};

export type AddOrRemoveInput =
  | 'ADD'
  | 'REMOVE';

export type AddTagValueToColumnInput = {
  dataColumnId: Scalars['String']['input'];
  tagValue: Scalars['String']['input'];
};

export type AddTagValueToColumnResponse = {
  __typename: 'AddTagValueToColumnResponse';
  tag: TagConfiguration;
};

export type AggregatedView = {
  __typename: 'AggregatedView';
  aggregationConfigurations: Array<AggregationConfiguration>;
  filters: Array<AnalysisFilter>;
  groupingConfigurations: Array<GroupingConfiguration>;
  id: Scalars['ID']['output'];
  resultDataTableId: Scalars['String']['output'];
  sourceDataTableId: Scalars['String']['output'];
};

export type AggregationConfiguration = {
  __typename: 'AggregationConfiguration';
  field: Scalars['String']['output'];
  filters: Array<AnalysisFilter>;
  id: Scalars['ID']['output'];
  type: AggregationType;
};

export type AggregationType =
  | 'AGGREGATION_AVERAGE'
  | 'AGGREGATION_COUNT'
  | 'AGGREGATION_COUNT_UNIQUE'
  | 'AGGREGATION_MAXIMUM'
  | 'AGGREGATION_MINIMUM'
  | 'AGGREGATION_SUM';

export type AggregationTypeInput =
  | 'AGGREGATION_AVERAGE'
  | 'AGGREGATION_COUNT'
  | 'AGGREGATION_COUNT_UNIQUE'
  | 'AGGREGATION_MAXIMUM'
  | 'AGGREGATION_MINIMUM'
  | 'AGGREGATION_SUM';

export type AnalysisFilter = {
  __typename: 'AnalysisFilter';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  end?: Maybe<Scalars['String']['output']>;
  exclude: Array<Scalars['String']['output']>;
  excludeBlanks?: Maybe<Scalars['Boolean']['output']>;
  exists?: Maybe<Scalars['Boolean']['output']>;
  field: Scalars['String']['output'];
  filterType: Scalars['String']['output'];
  include: Array<Scalars['String']['output']>;
  includeBlanks?: Maybe<Scalars['Boolean']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  periodLength?: Maybe<Scalars['Int']['output']>;
  periodUnit?: Maybe<Scalars['String']['output']>;
  searchTerm?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
};

export type AnalysisFilterInput = {
  boolean?: InputMaybe<Scalars['Boolean']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
  exclude: Array<Scalars['String']['input']>;
  excludeBlanks?: InputMaybe<Scalars['Boolean']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  field: Scalars['String']['input'];
  filterType: Scalars['String']['input'];
  include: Array<Scalars['String']['input']>;
  includeBlanks?: InputMaybe<Scalars['Boolean']['input']>;
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  periodLength?: InputMaybe<Scalars['Int']['input']>;
  periodUnit?: InputMaybe<Scalars['String']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
};

export type ApplyDataTableConnectionChangesInput = {
  changedConnectionColumns: Array<Scalars['String']['input']>;
  columnsWithChangedCubeConfiguration: Array<Scalars['String']['input']>;
  createdConnectionColumns: Array<Scalars['String']['input']>;
};

export type ApplyDataTableConnectionChangesResponse = {
  __typename: 'ApplyDataTableConnectionChangesResponse';
  notificationId: Scalars['String']['output'];
};

export type AsyncDataStatus =
  | 'FINISHED'
  | 'IN_PROGRESS';

export type AutofillGroupStructureFieldInputInput = {
  fieldKey: Scalars['String']['input'];
};

export type ChangeOperationTypeInput =
  | 'CREATE'
  | 'DELETE'
  | 'UPDATE';

export type CheckNewDataColumnRulesInput = {
  rules: Array<CheckRulePayloadInput>;
  targetDataColumnId?: InputMaybe<Scalars['String']['input']>;
};

export type CheckNewDataColumnRulesResponse = {
  __typename: 'CheckNewDataColumnRulesResponse';
  match: Array<DataColumnRule>;
};

export type CheckRulePayloadInput = {
  dataColumnRuleSetId?: InputMaybe<Scalars['String']['input']>;
  filters: Array<FilterInput>;
  value: Scalars['String']['input'];
};

export type ClassificationDataColumn = {
  __typename: 'ClassificationDataColumn';
  dataTableId: Scalars['String']['output'];
  dataType: DataColumnType;
  externalSourceKey?: Maybe<Scalars['String']['output']>;
  globalTypeKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isList: Scalars['Boolean']['output'];
  isProtected: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  referencedId?: Maybe<Scalars['String']['output']>;
};

export type ClassificationFieldSamples = {
  __typename: 'ClassificationFieldSamples';
  fieldKey: Scalars['String']['output'];
  samples: Array<Scalars['String']['output']>;
};

export type ClassificationModeInput =
  | 'AFTER_EXISTING_RULES'
  | 'BEFORE_EXISTING_RULES';

export type ConflictingRule = {
  __typename: 'ConflictingRule';
  filters: Array<Filter>;
  groupInFilePath: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  oldGroupPath: Array<Scalars['String']['output']>;
};

export type ConflictingRuleFromFile = {
  __typename: 'ConflictingRuleFromFile';
  filters: Array<Filter>;
  groupInFilePath: Array<Scalars['String']['output']>;
  index: Scalars['Int']['output'];
};

export type Contact = {
  __typename: 'Contact';
  companyId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone: Phone;
  position?: Maybe<Scalars['String']['output']>;
};

export type ContactFieldsInput =
  | 'companyId'
  | 'description'
  | 'email'
  | 'firstName'
  | 'id'
  | 'lastName'
  | 'phone'
  | 'position';

export type ContactInput = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone: PhoneInput;
  position?: InputMaybe<Scalars['String']['input']>;
};

export type ContactTermsInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAggregatedDataColumnInput = {
  aggregationField: Scalars['String']['input'];
  aggregationType: AggregationTypeInput;
  dataTableId: Scalars['String']['input'];
  externalSourceKey?: InputMaybe<Scalars['String']['input']>;
  filters: Array<AnalysisFilterInput>;
  groupByFields: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sourceDataTable: Scalars['String']['input'];
};

export type CreateAggregatedDataColumnResponse = {
  __typename: 'CreateAggregatedDataColumnResponse';
  dataColumn: DataColumn;
};

export type CreateAggregatedViewInput = {
  aggregations: Array<CreateAggregationInput>;
  baseDataTableId: Scalars['String']['input'];
  filters: Array<AnalysisFilterInput>;
  groupByFields: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateAggregatedViewResponse = {
  __typename: 'CreateAggregatedViewResponse';
  entity: AggregatedView;
};

export type CreateAggregationInput = {
  field: Scalars['String']['input'];
  filters: Array<AnalysisFilterInput>;
  name: Scalars['String']['input'];
  type: AggregationTypeInput;
};

export type CreateDataColumnInput = {
  dataTableId: Scalars['String']['input'];
  dataType: DataColumnTypeInput;
  externalSourceKey?: InputMaybe<Scalars['String']['input']>;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  isList?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  referencedId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataColumnPayloadInput = {
  dataType: DataColumnTypeInput;
  externalSourceKey?: InputMaybe<Scalars['String']['input']>;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  isList?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  referencedId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataColumnResponse = {
  __typename: 'CreateDataColumnResponse';
  dataColumn: DataColumn;
};

export type CreateDataColumnRulePayloadInput = {
  dataColumnRuleSetId?: InputMaybe<Scalars['String']['input']>;
  filters: Array<FilterInput>;
  groupName: Scalars['String']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};

export type CreateDataColumnRuleSetFromGroupStructureInput = {
  classificationMode: ClassificationModeInput;
  fieldsWithLevel: Array<FieldWithLevelInput>;
  groupStructureId: Scalars['String']['input'];
  targetDataColumnId: Scalars['String']['input'];
};

export type CreateDataColumnRuleSetFromGroupStructureResponse = {
  __typename: 'CreateDataColumnRuleSetFromGroupStructureResponse';
  notificationId: Scalars['String']['output'];
};

export type CreateDataColumnsInput = {
  dataColumns: Array<CreateDataColumnPayloadInput>;
  dataTableId: Scalars['String']['input'];
};

export type CreateDataColumnsResponse = {
  __typename: 'CreateDataColumnsResponse';
  dataColumns: Array<DataColumn>;
};

export type CreateDataRowInput = {
  dataJson: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  metaJson?: InputMaybe<Scalars['String']['input']>;
  rowId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataRowResponse = {
  __typename: 'CreateDataRowResponse';
  dataTableRow: DataTableRow;
};

export type CreateDataTableCollectionInput = {
  DataTableCollectionId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateDataTableCollectionResponse = {
  __typename: 'CreateDataTableCollectionResponse';
  dataTableCollection: DataTableCollection;
};

export type CreateDataTableConnectionCriterionInputInput = {
  ignoreOnBlankTarget: Scalars['Boolean']['input'];
  sourceField: Scalars['String']['input'];
  targetField: Scalars['String']['input'];
  type: DataTableConnectionCriterionTypeInput;
};

export type CreateDataTableConnectionInput = {
  criteria: Array<CreateDataTableConnectionCriterionInputInput>;
  dataColumnId: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  targetDataSourceIdentifier: Scalars['String']['input'];
  targetDataSourceType: DataTableConnectionSourceTypeInput;
};

export type CreateDataTableConnectionResponse = {
  __typename: 'CreateDataTableConnectionResponse';
  dataTableConnection: DataTableConnection;
};

export type CreateDataTableInput = {
  name: Scalars['String']['input'];
};

export type CreateDataTableResponse = {
  __typename: 'CreateDataTableResponse';
  entity: DataTable;
};

export type CreateGroupInput = {
  groupStructureId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGroupResponse = {
  __typename: 'CreateGroupResponse';
  group: Group;
};

export type CreateGroupStructureFromUploadFileInput = {
  dataTableId: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
  groupStructureName: Scalars['String']['input'];
  path?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGroupStructureFromUploadFileResponse = {
  __typename: 'CreateGroupStructureFromUploadFileResponse';
  dataColumnGroupStructure: DataColumnGroupStructure;
};

export type CreateGroupStructureInput = {
  dataTableId: Scalars['String']['input'];
  externallyManaged?: InputMaybe<Scalars['Boolean']['input']>;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  predefinedType?: InputMaybe<GroupStructurePredefinedTypeInput>;
};

export type CreateGroupStructureResponse = {
  __typename: 'CreateGroupStructureResponse';
  dataColumnGroupStructure: DataColumnGroupStructure;
};

export type CreateRulesInput = {
  classificationMode: ClassificationModeInput;
  dataColumnId: Scalars['String']['input'];
  rules: Array<CreateDataColumnRulePayloadInput>;
};

export type CreateRulesResponse = {
  __typename: 'CreateRulesResponse';
  notificationId: Scalars['String']['output'];
};

export type CreateRulesV2Input = {
  classificationMode: ClassificationModeInput;
  dataColumnId: Scalars['String']['input'];
  rules: Array<CreateDataColumnRulePayloadInput>;
};

export type CreateRulesV2Response = {
  __typename: 'CreateRulesV2Response';
  insertedIds: Array<IdKeyPair>;
  notificationId: Scalars['String']['output'];
};

export type CreateTagColumnInput = {
  dataTableId: Scalars['String']['input'];
  externalSourceKey?: InputMaybe<Scalars['String']['input']>;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  isList?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  tagStringValues: Array<Scalars['String']['input']>;
};

export type CreateTagColumnResponse = {
  __typename: 'CreateTagColumnResponse';
  columnId: Scalars['String']['output'];
  tag: TagConfiguration;
};

export type DataColumn = {
  __typename: 'DataColumn';
  dataTableId: Scalars['String']['output'];
  dataType: DataColumnType;
  externalSourceKey?: Maybe<Scalars['String']['output']>;
  globalTypeKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isList: Scalars['Boolean']['output'];
  isProtected: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  referencedId?: Maybe<Scalars['String']['output']>;
};

export type DataColumnElasticFieldMap = {
  __typename: 'DataColumnElasticFieldMap';
  dataColumn: DataColumn;
  fields: Array<Scalars['String']['output']>;
};

export type DataColumnGroupStructure = {
  __typename: 'DataColumnGroupStructure';
  dataColumn: ClassificationDataColumn;
  groupStructure: GroupStructure;
};

export type DataColumnRule = {
  __typename: 'DataColumnRule';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  dataColumnRuleSetId: Scalars['String']['output'];
  departmentId: Scalars['String']['output'];
  filters: Array<Filter>;
  groupLevel?: Maybe<Scalars['Int']['output']>;
  groupName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  precedence?: Maybe<Scalars['Int']['output']>;
  value: Scalars['String']['output'];
};

export type DataColumnRuleSet = {
  __typename: 'DataColumnRuleSet';
  fieldsWithFilterType: Array<FieldWithFilterType>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  precedence: Scalars['Int']['output'];
  targetDataColumnId: Scalars['String']['output'];
};

export type DataColumnSelectionInput =
  | 'INCLUDE_RELATED'
  | 'ONLY_INDEXED';

export type DataColumnType =
  | 'AGGREGATION'
  | 'BOOLEAN'
  | 'COMPANY'
  | 'CONTACT'
  | 'DATE'
  | 'DATE_AGGREGATION'
  | 'EMISSIONS'
  | 'FILE'
  | 'GROUP_STRUCTURE'
  | 'MONETARY_AMOUNT'
  | 'NUMBER'
  | 'TABLE_RELATION'
  | 'TAG'
  | 'TEXT'
  | 'USER';

export type DataColumnTypeInput =
  | 'AGGREGATION'
  | 'BOOLEAN'
  | 'COMPANY'
  | 'CONTACT'
  | 'DATE'
  | 'DATE_AGGREGATION'
  | 'EMISSIONS'
  | 'FILE'
  | 'GROUP_STRUCTURE'
  | 'MONETARY_AMOUNT'
  | 'NUMBER'
  | 'TABLE_RELATION'
  | 'TAG'
  | 'TEXT'
  | 'USER';

export type DataCubeConfiguration = {
  __typename: 'DataCubeConfiguration';
  dataTableId: Scalars['String']['output'];
  fieldsToInclude: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type DataTable = {
  __typename: 'DataTable';
  DataTableCollectionId?: Maybe<Scalars['String']['output']>;
  elasticIndex: Scalars['String']['output'];
  globalTypeKey?: Maybe<Scalars['String']['output']>;
  hasPendingConnectionChanges?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nameColumnId?: Maybe<Scalars['String']['output']>;
  relativeDateColumnId?: Maybe<Scalars['String']['output']>;
  relativeDateSetting?: Maybe<RelativeDateSetting>;
  tableType?: Maybe<Scalars['String']['output']>;
};

export type DataTableCollection = {
  __typename: 'DataTableCollection';
  id: Scalars['ID']['output'];
  index?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
};

export type DataTableCollectionOrderItem = {
  __typename: 'DataTableCollectionOrderItem';
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
};

export type DataTableCollectionOrderItemInput = {
  id: Scalars['ID']['input'];
  index: Scalars['Int']['input'];
};

export type DataTableConnection = {
  __typename: 'DataTableConnection';
  criteria: Array<DataTableConnectionCriterion>;
  dataColumnId: Scalars['String']['output'];
  dataTableId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  targetDataSource: DataTableConnectionSource;
};

export type DataTableConnectionCriterion = {
  __typename: 'DataTableConnectionCriterion';
  id: Scalars['ID']['output'];
  ignoreOnBlankTarget: Scalars['Boolean']['output'];
  sourceField: Scalars['String']['output'];
  targetField: Scalars['String']['output'];
  type: DataTableConnectionCriterionType;
};

export type DataTableConnectionCriterionInput = {
  id: Scalars['ID']['input'];
  ignoreOnBlankTarget: Scalars['Boolean']['input'];
  sourceField: Scalars['String']['input'];
  targetField: Scalars['String']['input'];
  type: DataTableConnectionCriterionTypeInput;
};

export type DataTableConnectionCriterionType =
  | 'EQUAL'
  | 'GREATER_THAN'
  | 'GREATER_THAN_OR_EQUAL'
  | 'LESS_THAN'
  | 'LESS_THAN_OR_EQUAL';

export type DataTableConnectionCriterionTypeInput =
  | 'EQUAL'
  | 'GREATER_THAN'
  | 'GREATER_THAN_OR_EQUAL'
  | 'LESS_THAN'
  | 'LESS_THAN_OR_EQUAL';

export type DataTableConnectionInput = {
  criteria: Array<DataTableConnectionCriterionInput>;
  dataColumnId: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  targetDataSource: DataTableConnectionSourceInput;
};

export type DataTableConnectionSource = {
  __typename: 'DataTableConnectionSource';
  identifier: Scalars['String']['output'];
  type: DataTableConnectionSourceType;
};

export type DataTableConnectionSourceInput = {
  identifier: Scalars['String']['input'];
  type: DataTableConnectionSourceTypeInput;
};

export type DataTableConnectionSourceType =
  | 'DATA_TABLE';

export type DataTableConnectionSourceTypeInput =
  | 'DATA_TABLE';

export type DataTableExport = {
  __typename: 'DataTableExport';
  exportDate: Scalars['String']['output'];
  exportFormat: ExportFormats;
  id: Scalars['ID']['output'];
};

export type DataTableRow = {
  __typename: 'DataTableRow';
  createdAt: Scalars['String']['output'];
  dataJson: Scalars['String']['output'];
  departmentId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['String']['output']>;
};

export type DataTableRowWithLabel = {
  __typename: 'DataTableRowWithLabel';
  dataJsonWithLabel: Scalars['String']['output'];
  departmentId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type DeleteContactsInput = {
  ids: Array<Scalars['String']['input']>;
};

export type DeleteContactsResponse = {
  __typename: 'DeleteContactsResponse';
  count: Scalars['Int']['output'];
  ids: Array<Scalars['String']['output']>;
};

export type DeleteDataColumnRuleSetsInput = {
  dataColumnRuleSetIds: Array<Scalars['String']['input']>;
  isDeletingDataColumn?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteDataColumnRuleSetsResponse = {
  __typename: 'DeleteDataColumnRuleSetsResponse';
  notificationId: Scalars['String']['output'];
};

export type DeleteDataColumnRulesInput = {
  dataColumnRuleIds: Array<Scalars['String']['input']>;
  targetDataColumnId: Scalars['String']['input'];
};

export type DeleteDataColumnRulesResponse = {
  __typename: 'DeleteDataColumnRulesResponse';
  notificationId: Scalars['String']['output'];
};

export type DeleteDataColumnsInput = {
  dataColumnIds: Array<Scalars['String']['input']>;
  dataTableId: Scalars['String']['input'];
};

export type DeleteDataColumnsResponse = {
  __typename: 'DeleteDataColumnsResponse';
  dataTable: DataTable;
  notificationId: Scalars['String']['output'];
};

export type DeleteDataRowInput = {
  dataTableId: Scalars['String']['input'];
  metaJson?: InputMaybe<Scalars['String']['input']>;
  rowId: Scalars['String']['input'];
};

export type DeleteDataRowResponse = {
  __typename: 'DeleteDataRowResponse';
  deleteId: Scalars['String']['output'];
};

export type DeleteDataTableCollectionInput = {
  id: Scalars['String']['input'];
};

export type DeleteDataTableCollectionResponse = {
  __typename: 'DeleteDataTableCollectionResponse';
  deleteId: Scalars['String']['output'];
};

export type DeleteDataTableConnectionInput = {
  dataTableConnectionId: Scalars['String']['input'];
};

export type DeleteDataTableConnectionResponse = {
  __typename: 'DeleteDataTableConnectionResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteDataTableInput = {
  id: Scalars['String']['input'];
};

export type DeleteDataTableResponse = {
  __typename: 'DeleteDataTableResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteGroupInput = {
  id: Scalars['String']['input'];
};

export type DeleteGroupResponse = {
  __typename: 'DeleteGroupResponse';
  notificationId: Scalars['String']['output'];
};

export type DeleteTagValueByValueInput = {
  dataColumnId: Scalars['String']['input'];
  tagValue: Scalars['String']['input'];
};

export type DeleteTagValueByValueResponse = {
  __typename: 'DeleteTagValueByValueResponse';
  tag: TagConfiguration;
};

export type DirectionsInput =
  | 'asc'
  | 'desc';

export type ElasticFieldItem = {
  __typename: 'ElasticFieldItem';
  elasticIndex: Scalars['String']['output'];
  field: Scalars['String']['output'];
  globalTypeKey?: Maybe<Scalars['String']['output']>;
  globalTypeSubKey?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  labelField?: Maybe<Scalars['String']['output']>;
  labelFieldType?: Maybe<Scalars['String']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  maxDateForRelativeFilters?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
};

export type ElasticFieldWithOptionalRelationalFieldInput = {
  field: Scalars['String']['input'];
  relationalLabelField?: InputMaybe<Scalars['String']['input']>;
  relationalLabelType?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type EnumElement = {
  __typename: 'EnumElement';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type ExportDataTableToFileInput = {
  dataTableId: Scalars['String']['input'];
  exportFormat: ExportFormatsInput;
  fields: Array<Scalars['String']['input']>;
  filters: Array<AnalysisFilterInput>;
  notificationTitle?: InputMaybe<Scalars['String']['input']>;
};

export type ExportDataTableToFileResponse = {
  __typename: 'ExportDataTableToFileResponse';
  notificationId: Scalars['String']['output'];
};

export type ExportFormats =
  | 'CSV'
  | 'XLSX';

export type ExportFormatsInput =
  | 'CSV'
  | 'XLSX';

export type FieldWithFilterType = {
  __typename: 'FieldWithFilterType';
  field: Scalars['String']['output'];
  filterType: RuleFilterType;
};

export type FieldWithLevelInput = {
  field: Scalars['String']['input'];
  level: Scalars['Int']['input'];
};

export type Filter = {
  __typename: 'Filter';
  field: Scalars['String']['output'];
  filterType: RuleFilterType;
  labelValue?: Maybe<Scalars['String']['output']>;
  numericValue?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FilterInput = {
  field: Scalars['String']['input'];
  filterType: RuleFilterTypeInput;
  labelValue?: InputMaybe<Scalars['String']['input']>;
  numericValue?: InputMaybe<Scalars['Float']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type GetAllDataColumnRuleSetsInput = {
  targetDataColumnId: Scalars['String']['input'];
};

export type GetAllDataColumnRuleSetsResponse = {
  __typename: 'GetAllDataColumnRuleSetsResponse';
  dataColumnRuleSets: Array<DataColumnRuleSet>;
};

export type GetAllDataColumnsResponse = {
  __typename: 'GetAllDataColumnsResponse';
  dataColumns: Array<DataColumn>;
};

export type GetAllDataTableCollectionsResponse = {
  __typename: 'GetAllDataTableCollectionsResponse';
  dataTableCollections: Array<DataTableCollection>;
};

export type GetAllDataTableConnectionsResponse = {
  __typename: 'GetAllDataTableConnectionsResponse';
  dataTableConnections: Array<DataTableConnection>;
};

export type GetAllElasticFieldsResponse = {
  __typename: 'GetAllElasticFieldsResponse';
  elasticFields: Array<ElasticFieldItem>;
};

export type GetAllGroupStructuresResponse = {
  __typename: 'GetAllGroupStructuresResponse';
  groupStructures: Array<GroupStructure>;
};

export type GetAllRunningRulesResponse = {
  __typename: 'GetAllRunningRulesResponse';
  runningRules: Array<RunningRules>;
};

export type GetCompleteStructureByPredefinedTypeInput = {
  predefinedType: GroupStructurePredefinedTypeInput;
};

export type GetCompleteStructureByPredefinedTypeResponse = {
  __typename: 'GetCompleteStructureByPredefinedTypeResponse';
  groups: Array<GroupNode>;
};

export type GetContactsBySupplierIdInput = {
  supplierId: Scalars['String']['input'];
};

export type GetContactsBySupplierIdResponse = {
  __typename: 'GetContactsBySupplierIdResponse';
  result: SupplierContacts;
};

export type GetContactsBySupplierIdsInput = {
  supplierIds: Array<Scalars['String']['input']>;
};

export type GetContactsBySupplierIdsResponse = {
  __typename: 'GetContactsBySupplierIdsResponse';
  result: Array<SupplierContacts>;
};

export type GetContactsInput = {
  contacts: Array<ContactInput>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort: SortInput;
};

export type GetContactsResponse = {
  __typename: 'GetContactsResponse';
  count: Scalars['Int']['output'];
  next: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  prev: Scalars['Int']['output'];
  result: Array<Contact>;
  total: Scalars['Int']['output'];
};

export type GetDataColumnDependenciesInput = {
  dataColumnId: Scalars['String']['input'];
};

export type GetDataColumnDependenciesResponse = {
  __typename: 'GetDataColumnDependenciesResponse';
  hasDeps: Scalars['Boolean']['output'];
  messageToUser: Scalars['String']['output'];
};

export type GetDataColumnElasticFieldMapInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataColumnElasticFieldMapResponse = {
  __typename: 'GetDataColumnElasticFieldMapResponse';
  dataColumnElasticFieldMaps: Array<DataColumnElasticFieldMap>;
};

export type GetDataColumnRulesByRuleSetInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetDataColumnRulesByRuleSetInput;
};

export type GetDataColumnRulesByRuleSetResponse = {
  __typename: 'GetDataColumnRulesByRuleSetResponse';
  count: Scalars['Int']['output'];
  dataColumnRules: Array<DataColumnRule>;
};

export type GetDataColumnsInput = {
  columnSelection?: InputMaybe<DataColumnSelectionInput>;
  dataTableId: Scalars['String']['input'];
};

export type GetDataColumnsResponse = {
  __typename: 'GetDataColumnsResponse';
  dataColumns: Array<DataColumn>;
};

export type GetDataCubeConfigurationInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataCubeConfigurationResponse = {
  __typename: 'GetDataCubeConfigurationResponse';
  DataCubeConfiguration: DataCubeConfiguration;
};

export type GetDataTableByGlobalTypeInput = {
  globalTypeKey: Scalars['String']['input'];
};

export type GetDataTableByGlobalTypeResponse = {
  __typename: 'GetDataTableByGlobalTypeResponse';
  dataTable?: Maybe<DataTable>;
};

export type GetDataTableElasticFieldsInput = {
  dataTableId: Scalars['String']['input'];
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  includeLabelFields?: InputMaybe<Scalars['Boolean']['input']>;
  withEnrichments: Scalars['Boolean']['input'];
};

export type GetDataTableElasticFieldsResponse = {
  __typename: 'GetDataTableElasticFieldsResponse';
  elasticFields: Array<ElasticFieldItem>;
};

export type GetDataTableFieldLabelsInput = {
  dataTableId: Scalars['String']['input'];
  includePostfix: Scalars['Boolean']['input'];
};

export type GetDataTableFieldLabelsResponse = {
  __typename: 'GetDataTableFieldLabelsResponse';
  fieldLabelsJson: Scalars['String']['output'];
};

export type GetDataTableInput = {
  id: Scalars['String']['input'];
};

export type GetDataTableResponse = {
  __typename: 'GetDataTableResponse';
  entity: DataTable;
};

export type GetDataTableRowCountInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataTableRowCountResponse = {
  __typename: 'GetDataTableRowCountResponse';
  count: Scalars['Int']['output'];
};

export type GetDataTableRowInput = {
  dataTableId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type GetDataTableRowResponse = {
  __typename: 'GetDataTableRowResponse';
  dataTableRow: DataTableRow;
};

export type GetDataTableRowsInput = {
  dataTableId: Scalars['String']['input'];
  rowIds: Array<Scalars['String']['input']>;
};

export type GetDataTableRowsResponse = {
  __typename: 'GetDataTableRowsResponse';
  rows: Array<DataTableRow>;
};

export type GetDataTableRowsSearchInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetDataTableRowsSearchInput;
};

export type GetDataTableRowsSearchResponse = {
  __typename: 'GetDataTableRowsSearchResponse';
  dataTableRows: Array<DataTableRow>;
  total: Scalars['Int']['output'];
};

export type GetDataTableValidationSummaryInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataTableValidationSummaryResponse = {
  __typename: 'GetDataTableValidationSummaryResponse';
  cubeConfigurationCount: Scalars['Int']['output'];
  indexFieldCount: Scalars['Int']['output'];
};

export type GetDependantColumnsInput = {
  dataColumnId: Scalars['String']['input'];
};

export type GetDependantColumnsResponse = {
  __typename: 'GetDependantColumnsResponse';
  dataColumns: Array<DataColumn>;
};

export type GetDependentRulesByGroupIdInput = {
  groupId: Scalars['String']['input'];
};

export type GetDependentRulesByGroupIdResponse = {
  __typename: 'GetDependentRulesByGroupIdResponse';
  dependentRules: Array<DataColumnRule>;
};

export type GetExportDownloadUrlsInput = {
  exportId: Scalars['String']['input'];
};

export type GetExportDownloadUrlsResponse = {
  __typename: 'GetExportDownloadUrlsResponse';
  downloadUrls: Array<Scalars['String']['output']>;
};

export type GetExportsByTableIdInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetExportsByTableIdResponse = {
  __typename: 'GetExportsByTableIdResponse';
  exports: Array<DataTableExport>;
};

export type GetGroupHasChildrenInput = {
  groupId: Scalars['String']['input'];
};

export type GetGroupHasChildrenResponse = {
  __typename: 'GetGroupHasChildrenResponse';
  hasChildren: Scalars['Boolean']['output'];
};

export type GetGroupInput = {
  id: Scalars['String']['input'];
};

export type GetGroupPredictionInput = {
  groupStructureId: Scalars['String']['input'];
  keyValuePairs: Array<KeyValuePairInput>;
};

export type GetGroupPredictionResponse = {
  __typename: 'GetGroupPredictionResponse';
  group: Group;
};

export type GetGroupPredictionsInput = {
  groupStructureId: Scalars['String']['input'];
  predictionInputs: Array<GroupPredictionInputInput>;
};

export type GetGroupPredictionsResponse = {
  __typename: 'GetGroupPredictionsResponse';
  predictions: Array<GroupPredictionOutput>;
};

export type GetGroupResponse = {
  __typename: 'GetGroupResponse';
  group: Group;
};

export type GetGroupStructureInput = {
  id: Scalars['String']['input'];
};

export type GetGroupStructureResponse = {
  __typename: 'GetGroupStructureResponse';
  groupStructure: GroupStructure;
};

export type GetGroupStructuresInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetGroupStructuresResponse = {
  __typename: 'GetGroupStructuresResponse';
  dataColumnGroupStructures: Array<DataColumnGroupStructure>;
};

export type GetGroupsByGroupStructureInput = {
  groupStructureId: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type GetGroupsByGroupStructureResponse = {
  __typename: 'GetGroupsByGroupStructureResponse';
  groups: Array<GroupWithHasChildren>;
};

export type GetGroupsInput = {
  ids: Array<Scalars['String']['input']>;
};

export type GetGroupsResponse = {
  __typename: 'GetGroupsResponse';
  groups: Array<Group>;
};

export type GetManyAggregatedViewsResponse = {
  __typename: 'GetManyAggregatedViewsResponse';
  entities: Array<AggregatedView>;
};

export type GetManyDataTablesResponse = {
  __typename: 'GetManyDataTablesResponse';
  entities: Array<DataTable>;
};

export type GetOrCreateDataTableCollectionOrderResponse = {
  __typename: 'GetOrCreateDataTableCollectionOrderResponse';
  collectionOrder: Array<DataTableCollectionOrderItem>;
  id: Scalars['String']['output'];
};

export type GetPaginatedConflictingRulesForUploadInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetPaginatedConflictingRulesForUploadInput;
};

export type GetPaginatedConflictingRulesForUploadResponse = {
  __typename: 'GetPaginatedConflictingRulesForUploadResponse';
  conflictingRules: Array<ConflictingRule>;
  total: Scalars['Int']['output'];
};

export type GetPaginatedDanglingRulesInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetPaginatedDanglingRulesInput;
};

export type GetPaginatedDanglingRulesResponse = {
  __typename: 'GetPaginatedDanglingRulesResponse';
  rules: Array<DataColumnRule>;
  total: Scalars['Int']['output'];
};

export type GetPaginatedDuplicateRulesInFileInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetPaginatedDuplicateRulesInFileInput;
};

export type GetPaginatedDuplicateRulesInFileResponse = {
  __typename: 'GetPaginatedDuplicateRulesInFileResponse';
  duplicateRulesInFile: Array<ConflictingRuleFromFile>;
  total: Scalars['Int']['output'];
};

export type GetParentIdsBySearchTermInput = {
  groupStructureId: Scalars['String']['input'];
  searchTerm: Scalars['String']['input'];
};

export type GetParentIdsBySearchTermResponse = {
  __typename: 'GetParentIdsBySearchTermResponse';
  ids: Array<Scalars['String']['output']>;
};

export type GetParentNamesByChildIdInput = {
  childId: Scalars['String']['input'];
};

export type GetParentNamesByChildIdResponse = {
  __typename: 'GetParentNamesByChildIdResponse';
  names: Array<Scalars['String']['output']>;
};

export type GetParentsByChildIdInput = {
  childId: Scalars['String']['input'];
};

export type GetParentsByChildIdResponse = {
  __typename: 'GetParentsByChildIdResponse';
  groups: Array<Group>;
};

export type GetPreviewFileInput = {
  fileId: Scalars['String']['input'];
  path?: InputMaybe<Scalars['String']['input']>;
};

export type GetPreviewFileResponse = {
  __typename: 'GetPreviewFileResponse';
  fields: Array<ClassificationFieldSamples>;
  fileId: Scalars['String']['output'];
};

export type GetRelatedDataColumnsByDataTableInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetRelatedDataColumnsByDataTableResponse = {
  __typename: 'GetRelatedDataColumnsByDataTableResponse';
  dataColumns: Array<DataColumn>;
};

export type GetRowsSearchWithLabelsInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetRowsSearchWithLabelsInput;
};

export type GetRowsSearchWithLabelsResponse = {
  __typename: 'GetRowsSearchWithLabelsResponse';
  dataTableRowsWithLabel: Array<DataTableRowWithLabel>;
  total: Scalars['Int']['output'];
};

export type GetRuleCountPerRuleSetByDataColumnInput = {
  dataColumnId: Scalars['String']['input'];
  groupId?: InputMaybe<Scalars['String']['input']>;
};

export type GetRuleCountPerRuleSetByDataColumnResponse = {
  __typename: 'GetRuleCountPerRuleSetByDataColumnResponse';
  ruleSetIdsWithRuleCounts: Array<RuleSetIdWithRuleCount>;
};

export type GetSavedCategoriesResponse = {
  __typename: 'GetSavedCategoriesResponse';
  categories: Array<SpendCategory>;
};

export type GetSpendCategoriesInput = {
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type GetSpendCategoriesResponse = {
  __typename: 'GetSpendCategoriesResponse';
  categories: Array<SpendCategory>;
};

export type GetSpendCategoryInput = {
  id: Scalars['String']['input'];
};

export type GetSpendCategoryResponse = {
  __typename: 'GetSpendCategoryResponse';
  category: SpendCategory;
};

export type GetTagValuesByColumnIdInput = {
  dataColumnId: Scalars['String']['input'];
};

export type GetTagValuesByColumnIdResponse = {
  __typename: 'GetTagValuesByColumnIdResponse';
  tagValues: Array<EnumElement>;
};

export type Group = {
  __typename: 'Group';
  groupStructureId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
};

export type GroupNode = {
  __typename: 'GroupNode';
  children: Array<GroupNode>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GroupPredictionInputInput = {
  fieldValuePair: Array<KeyValuePairInput>;
};

export type GroupPredictionOutput = {
  __typename: 'GroupPredictionOutput';
  fieldValuePairs: Array<KeyValuePair>;
  predictedGroup?: Maybe<Group>;
  predictedGroupPath: Array<Scalars['String']['output']>;
};

export type GroupStructure = {
  __typename: 'GroupStructure';
  depth: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  predefinedType?: Maybe<GroupStructurePredefinedType>;
};

export type GroupStructurePredefinedType =
  | 'DEPARTMENT'
  | 'SPEND_CATEGORY';

export type GroupStructurePredefinedTypeInput =
  | 'DEPARTMENT'
  | 'SPEND_CATEGORY';

export type GroupWithHasChildren = {
  __typename: 'GroupWithHasChildren';
  groupStructureId: Scalars['String']['output'];
  hasChildren: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  numChildren?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
};

export type GroupingConfiguration = {
  __typename: 'GroupingConfiguration';
  dataColumnId: Scalars['String']['output'];
  field: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type IdKeyPair = {
  __typename: 'IdKeyPair';
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
};

export type IndexDataTableToElasticInput = {
  dataTableId: Scalars['String']['input'];
};

export type IndexDataTableToElasticResponse = {
  __typename: 'IndexDataTableToElasticResponse';
  notificationId: Scalars['String']['output'];
};

export type KeyValuePair = {
  __typename: 'KeyValuePair';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type KeyValuePairInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type ListAllDataTableRowsInput = {
  batchSize: Scalars['Int']['input'];
  dataTableId: Scalars['String']['input'];
  offset: Scalars['Int']['input'];
};

export type ListAllDataTableRowsResponse = {
  __typename: 'ListAllDataTableRowsResponse';
  rows: Array<DataTableRow>;
  total: Scalars['Int']['output'];
};

export type MergeGroupInput = {
  groupStructureId: Scalars['String']['input'];
  mergeFromGroupId: Scalars['String']['input'];
  mergeIntoGroupId: Scalars['String']['input'];
};

export type MergeGroupResponse = {
  __typename: 'MergeGroupResponse';
  notificationId: Scalars['String']['output'];
};

export type MoveDataColumnRulesInput = {
  dataColumnRuleIds: Array<Scalars['String']['input']>;
  skipApplyClassification?: InputMaybe<Scalars['Boolean']['input']>;
  targetRuleSetId: Scalars['String']['input'];
};

export type MoveDataColumnRulesResponse = {
  __typename: 'MoveDataColumnRulesResponse';
  notificationId: Scalars['String']['output'];
};

export type MoveGroupInput = {
  groupStructureId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type MoveGroupResponse = {
  __typename: 'MoveGroupResponse';
  notificationId: Scalars['String']['output'];
};

export type Mutation = {
  __typename: 'Mutation';
  addDataTableConnectionCriterion: AddDataTableConnectionCriterionResponse;
  addOrRemoveFieldsFromDataCubeConfiguration: AddOrRemoveFieldsFromDataCubeConfigurationResponse;
  addTagValueToColumn: AddTagValueToColumnResponse;
  applyDataTableConnectionChanges: ApplyDataTableConnectionChangesResponse;
  checkNewDataColumnRules: CheckNewDataColumnRulesResponse;
  createAggregatedDataColumn: CreateAggregatedDataColumnResponse;
  createAggregatedView: CreateAggregatedViewResponse;
  createDataColumn: CreateDataColumnResponse;
  createDataColumnRuleSetFromGroupStructure: CreateDataColumnRuleSetFromGroupStructureResponse;
  createDataColumns: CreateDataColumnsResponse;
  createDataRow: CreateDataRowResponse;
  createDataTable: CreateDataTableResponse;
  createDataTableCollection: CreateDataTableCollectionResponse;
  createDataTableConnection: CreateDataTableConnectionResponse;
  createGroup: CreateGroupResponse;
  createGroupStructure: CreateGroupStructureResponse;
  createGroupStructureFromUploadFile: CreateGroupStructureFromUploadFileResponse;
  createRules: CreateRulesResponse;
  createRulesV2: CreateRulesV2Response;
  createTagColumn: CreateTagColumnResponse;
  deleteContacts: DeleteContactsResponse;
  deleteDataColumnRuleSets: DeleteDataColumnRuleSetsResponse;
  deleteDataColumnRules: DeleteDataColumnRulesResponse;
  deleteDataColumns: DeleteDataColumnsResponse;
  deleteDataRow: DeleteDataRowResponse;
  deleteDataTable: DeleteDataTableResponse;
  deleteDataTableCollection: DeleteDataTableCollectionResponse;
  deleteDataTableConnection: DeleteDataTableConnectionResponse;
  deleteGroup: DeleteGroupResponse;
  deleteTagValueByValue: DeleteTagValueByValueResponse;
  exportDataTableToFile: ExportDataTableToFileResponse;
  indexDataTableToElastic: IndexDataTableToElasticResponse;
  listAllDataTableRows: ListAllDataTableRowsResponse;
  mergeGroup: MergeGroupResponse;
  moveDataColumnRules: MoveDataColumnRulesResponse;
  moveGroup: MoveGroupResponse;
  optimizeDataTable: OptimizeDataTableResponse;
  reapplyDataColumnRules: ReapplyDataColumnRulesResponse;
  reapplyDataTableConnections: ReapplyDataTableConnectionsResponse;
  removeDataTableConnectionCriterion: RemoveDataTableConnectionCriterionResponse;
  removeGhostRows: RemoveGhostRowsResponse;
  reorderDataColumnRuleSets: ReorderDataColumnRuleSetsResponse;
  replaceDataColumnWithNewDataType: ReplaceDataColumnWithNewDataTypeResponse;
  saveCategory: SaveCategoryResponse;
  startAggregatedView: StartAggregatedViewResponse;
  startAutofillGroupStructure: StartAutofillGroupStructureResponse;
  startDefaultSetup: StartDefaultSetupResponse;
  startFindConflictingRulesForUpload: StartFindConflictingRulesForUploadResponse;
  startFindDanglingRulesTask: StartFindDanglingRulesTaskResponse;
  testDataTableConnection: TestDataTableConnectionResponse;
  unsaveCategory: UnsaveCategoryResponse;
  updateAggregatedView: UpdateAggregatedViewResponse;
  updateDataColumn: UpdateDataColumnResponse;
  updateDataColumnRuleSet: UpdateDataColumnRuleSetResponse;
  updateDataColumnRules: UpdateDataColumnRulesResponse;
  updateDataTable: UpdateDataTableResponse;
  updateDataTableCollection: UpdateDataTableCollectionResponse;
  updateDataTableCollectionOrder: UpdateDataTableCollectionOrderResponse;
  updateDataTableRow: UpdateDataTableRowResponse;
  updateGroup: UpdateGroupResponse;
  updateGroupStructure: UpdateGroupStructureResponse;
  uploadRules: UploadRulesResponse;
  upsertContacts: UpsertContactsResponse;
};


export type MutationAddDataTableConnectionCriterionArgs = {
  input: AddDataTableConnectionCriterionInput;
};


export type MutationAddOrRemoveFieldsFromDataCubeConfigurationArgs = {
  input: AddOrRemoveFieldsFromDataCubeConfigurationInput;
};


export type MutationAddTagValueToColumnArgs = {
  input: AddTagValueToColumnInput;
};


export type MutationApplyDataTableConnectionChangesArgs = {
  input: ApplyDataTableConnectionChangesInput;
};


export type MutationCheckNewDataColumnRulesArgs = {
  input: CheckNewDataColumnRulesInput;
};


export type MutationCreateAggregatedDataColumnArgs = {
  input: CreateAggregatedDataColumnInput;
};


export type MutationCreateAggregatedViewArgs = {
  input: CreateAggregatedViewInput;
};


export type MutationCreateDataColumnArgs = {
  input: CreateDataColumnInput;
};


export type MutationCreateDataColumnRuleSetFromGroupStructureArgs = {
  input: CreateDataColumnRuleSetFromGroupStructureInput;
};


export type MutationCreateDataColumnsArgs = {
  input: CreateDataColumnsInput;
};


export type MutationCreateDataRowArgs = {
  input: CreateDataRowInput;
};


export type MutationCreateDataTableArgs = {
  input: CreateDataTableInput;
};


export type MutationCreateDataTableCollectionArgs = {
  input: CreateDataTableCollectionInput;
};


export type MutationCreateDataTableConnectionArgs = {
  input: CreateDataTableConnectionInput;
};


export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


export type MutationCreateGroupStructureArgs = {
  input: CreateGroupStructureInput;
};


export type MutationCreateGroupStructureFromUploadFileArgs = {
  input: CreateGroupStructureFromUploadFileInput;
};


export type MutationCreateRulesArgs = {
  input: CreateRulesInput;
};


export type MutationCreateRulesV2Args = {
  input: CreateRulesV2Input;
};


export type MutationCreateTagColumnArgs = {
  input: CreateTagColumnInput;
};


export type MutationDeleteContactsArgs = {
  input: DeleteContactsInput;
};


export type MutationDeleteDataColumnRuleSetsArgs = {
  input: DeleteDataColumnRuleSetsInput;
};


export type MutationDeleteDataColumnRulesArgs = {
  input: DeleteDataColumnRulesInput;
};


export type MutationDeleteDataColumnsArgs = {
  input: DeleteDataColumnsInput;
};


export type MutationDeleteDataRowArgs = {
  input: DeleteDataRowInput;
};


export type MutationDeleteDataTableArgs = {
  input: DeleteDataTableInput;
};


export type MutationDeleteDataTableCollectionArgs = {
  input: DeleteDataTableCollectionInput;
};


export type MutationDeleteDataTableConnectionArgs = {
  input: DeleteDataTableConnectionInput;
};


export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};


export type MutationDeleteTagValueByValueArgs = {
  input: DeleteTagValueByValueInput;
};


export type MutationExportDataTableToFileArgs = {
  input: ExportDataTableToFileInput;
};


export type MutationIndexDataTableToElasticArgs = {
  input: IndexDataTableToElasticInput;
};


export type MutationListAllDataTableRowsArgs = {
  input: ListAllDataTableRowsInput;
};


export type MutationMergeGroupArgs = {
  input: MergeGroupInput;
};


export type MutationMoveDataColumnRulesArgs = {
  input: MoveDataColumnRulesInput;
};


export type MutationMoveGroupArgs = {
  input: MoveGroupInput;
};


export type MutationOptimizeDataTableArgs = {
  input: OptimizeDataTableInput;
};


export type MutationReapplyDataColumnRulesArgs = {
  input: ReapplyDataColumnRulesInput;
};


export type MutationReapplyDataTableConnectionsArgs = {
  input: ReapplyDataTableConnectionsInput;
};


export type MutationRemoveDataTableConnectionCriterionArgs = {
  input: RemoveDataTableConnectionCriterionInput;
};


export type MutationRemoveGhostRowsArgs = {
  input: RemoveGhostRowsInput;
};


export type MutationReorderDataColumnRuleSetsArgs = {
  input: ReorderDataColumnRuleSetsInput;
};


export type MutationReplaceDataColumnWithNewDataTypeArgs = {
  input: ReplaceDataColumnWithNewDataTypeInput;
};


export type MutationSaveCategoryArgs = {
  input: SaveCategoryInput;
};


export type MutationStartAggregatedViewArgs = {
  input: StartAggregatedViewInput;
};


export type MutationStartAutofillGroupStructureArgs = {
  input: StartAutofillGroupStructureInput;
};


export type MutationStartDefaultSetupArgs = {
  input: StartDefaultSetupInput;
};


export type MutationStartFindConflictingRulesForUploadArgs = {
  input: StartFindConflictingRulesForUploadInput;
};


export type MutationStartFindDanglingRulesTaskArgs = {
  input: StartFindDanglingRulesTaskInput;
};


export type MutationTestDataTableConnectionArgs = {
  input: TestDataTableConnectionInput;
};


export type MutationUnsaveCategoryArgs = {
  input: UnsaveCategoryInput;
};


export type MutationUpdateAggregatedViewArgs = {
  input: UpdateAggregatedViewInput;
};


export type MutationUpdateDataColumnArgs = {
  input: UpdateDataColumnInput;
};


export type MutationUpdateDataColumnRuleSetArgs = {
  input: UpdateDataColumnRuleSetInput;
};


export type MutationUpdateDataColumnRulesArgs = {
  input: UpdateDataColumnRulesInput;
};


export type MutationUpdateDataTableArgs = {
  input: UpdateDataTableInput;
};


export type MutationUpdateDataTableCollectionArgs = {
  input: UpdateDataTableCollectionInput;
};


export type MutationUpdateDataTableCollectionOrderArgs = {
  input: UpdateDataTableCollectionOrderInput;
};


export type MutationUpdateDataTableRowArgs = {
  input: UpdateDataTableRowInput;
};


export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


export type MutationUpdateGroupStructureArgs = {
  input: UpdateGroupStructureInput;
};


export type MutationUploadRulesArgs = {
  input: UploadRulesInput;
};


export type MutationUpsertContactsArgs = {
  input: UpsertContactsInput;
};

export type OptimizeDataTableInput = {
  dataTableId: Scalars['String']['input'];
};

export type OptimizeDataTableResponse = {
  __typename: 'OptimizeDataTableResponse';
  notificationId: Scalars['String']['output'];
};

export type OverwriteExistingRulesInput =
  | 'CONTINUE_WITH_CONFLICTS'
  | 'KEEP_EXISTING'
  | 'OVERWRITE_EXISTING';

export type PaginatedGetDataColumnRulesByRuleSetInput = {
  dataColumnRuleSetId: Scalars['String']['input'];
  groupId?: InputMaybe<Scalars['String']['input']>;
  igniteFiltersJSON?: InputMaybe<Scalars['String']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type PaginatedGetDataTableRowsSearchInput = {
  dataTableId: Scalars['String']['input'];
  filterJSON?: InputMaybe<Scalars['String']['input']>;
  sortingArray: Array<SortingArrayInput>;
};

export type PaginatedGetPaginatedConflictingRulesForUploadInput = {
  fieldCombinations: Array<ElasticFieldWithOptionalRelationalFieldInput>;
  fileId: Scalars['String']['input'];
  levelDepth: Scalars['Int']['input'];
  nonIncludedGroupLevels: Array<Scalars['String']['input']>;
};

export type PaginatedGetPaginatedDanglingRulesInput = {
  dataColumnRuleSetId: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type PaginatedGetPaginatedDuplicateRulesInFileInput = {
  fieldCombinations: Array<ElasticFieldWithOptionalRelationalFieldInput>;
  fileId: Scalars['String']['input'];
  levelDepth: Scalars['Int']['input'];
  nonIncludedGroupLevels: Array<Scalars['String']['input']>;
};

export type PaginatedGetRowsSearchWithLabelsInput = {
  dataTableId: Scalars['String']['input'];
  filterJSON?: InputMaybe<Scalars['String']['input']>;
  sortingArray: Array<SortingArrayInput>;
};

export type Phone = {
  __typename: 'Phone';
  countryCode?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['String']['output']>;
};

export type PhoneInput = {
  countryCode?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename: 'Query';
  getAllDataColumnRuleSets: GetAllDataColumnRuleSetsResponse;
  getAllDataColumns: GetAllDataColumnsResponse;
  getAllDataTableCollections: GetAllDataTableCollectionsResponse;
  getAllDataTableConnections: GetAllDataTableConnectionsResponse;
  getAllElasticFields: GetAllElasticFieldsResponse;
  getAllGroupStructures: GetAllGroupStructuresResponse;
  getAllRunningRules: GetAllRunningRulesResponse;
  getCompleteStructureByPredefinedType: GetCompleteStructureByPredefinedTypeResponse;
  getContacts: GetContactsResponse;
  getContactsBySupplierId: GetContactsBySupplierIdResponse;
  getContactsBySupplierIds: GetContactsBySupplierIdsResponse;
  getDataColumnDependencies: GetDataColumnDependenciesResponse;
  getDataColumnElasticFieldMap: GetDataColumnElasticFieldMapResponse;
  getDataColumnRulesByRuleSet: GetDataColumnRulesByRuleSetResponse;
  getDataColumns: GetDataColumnsResponse;
  getDataCubeConfiguration: GetDataCubeConfigurationResponse;
  getDataTable: GetDataTableResponse;
  getDataTableByGlobalType: GetDataTableByGlobalTypeResponse;
  getDataTableElasticFields: GetDataTableElasticFieldsResponse;
  getDataTableFieldLabels: GetDataTableFieldLabelsResponse;
  getDataTableRow: GetDataTableRowResponse;
  getDataTableRowCount: GetDataTableRowCountResponse;
  getDataTableRows: GetDataTableRowsResponse;
  getDataTableRowsSearch: GetDataTableRowsSearchResponse;
  getDataTableValidationSummary: GetDataTableValidationSummaryResponse;
  getDependantColumns: GetDependantColumnsResponse;
  getDependentRulesByGroupId: GetDependentRulesByGroupIdResponse;
  getExportDownloadUrls: GetExportDownloadUrlsResponse;
  getExportsByTableId: GetExportsByTableIdResponse;
  getGroup: GetGroupResponse;
  getGroupHasChildren: GetGroupHasChildrenResponse;
  getGroupPrediction: GetGroupPredictionResponse;
  getGroupPredictions: GetGroupPredictionsResponse;
  getGroupStructure: GetGroupStructureResponse;
  getGroupStructures: GetGroupStructuresResponse;
  getGroups: GetGroupsResponse;
  getGroupsByGroupStructure: GetGroupsByGroupStructureResponse;
  getManyAggregatedViews: GetManyAggregatedViewsResponse;
  getManyDataTables: GetManyDataTablesResponse;
  getOrCreateDataTableCollectionOrder: GetOrCreateDataTableCollectionOrderResponse;
  getPaginatedConflictingRulesForUpload: GetPaginatedConflictingRulesForUploadResponse;
  getPaginatedDanglingRules: GetPaginatedDanglingRulesResponse;
  getPaginatedDuplicateRulesInFile: GetPaginatedDuplicateRulesInFileResponse;
  getParentIdsBySearchTerm: GetParentIdsBySearchTermResponse;
  getParentNamesByChildId: GetParentNamesByChildIdResponse;
  getParentsByChildId: GetParentsByChildIdResponse;
  getPreviewFile: GetPreviewFileResponse;
  getRelatedDataColumnsByDataTable: GetRelatedDataColumnsByDataTableResponse;
  getRowsSearchWithLabels: GetRowsSearchWithLabelsResponse;
  getRuleCountPerRuleSetByDataColumn: GetRuleCountPerRuleSetByDataColumnResponse;
  getSavedCategories: GetSavedCategoriesResponse;
  getSpendCategories: GetSpendCategoriesResponse;
  getSpendCategory: GetSpendCategoryResponse;
  getTagValuesByColumnId: GetTagValuesByColumnIdResponse;
  searchContacts: SearchContactsResponse;
};


export type QueryGetAllDataColumnRuleSetsArgs = {
  input: GetAllDataColumnRuleSetsInput;
};


export type QueryGetCompleteStructureByPredefinedTypeArgs = {
  input: GetCompleteStructureByPredefinedTypeInput;
};


export type QueryGetContactsArgs = {
  input: GetContactsInput;
};


export type QueryGetContactsBySupplierIdArgs = {
  input: GetContactsBySupplierIdInput;
};


export type QueryGetContactsBySupplierIdsArgs = {
  input: GetContactsBySupplierIdsInput;
};


export type QueryGetDataColumnDependenciesArgs = {
  input: GetDataColumnDependenciesInput;
};


export type QueryGetDataColumnElasticFieldMapArgs = {
  input: GetDataColumnElasticFieldMapInput;
};


export type QueryGetDataColumnRulesByRuleSetArgs = {
  input: GetDataColumnRulesByRuleSetInput;
};


export type QueryGetDataColumnsArgs = {
  input: GetDataColumnsInput;
};


export type QueryGetDataCubeConfigurationArgs = {
  input: GetDataCubeConfigurationInput;
};


export type QueryGetDataTableArgs = {
  input: GetDataTableInput;
};


export type QueryGetDataTableByGlobalTypeArgs = {
  input: GetDataTableByGlobalTypeInput;
};


export type QueryGetDataTableElasticFieldsArgs = {
  input: GetDataTableElasticFieldsInput;
};


export type QueryGetDataTableFieldLabelsArgs = {
  input: GetDataTableFieldLabelsInput;
};


export type QueryGetDataTableRowArgs = {
  input: GetDataTableRowInput;
};


export type QueryGetDataTableRowCountArgs = {
  input: GetDataTableRowCountInput;
};


export type QueryGetDataTableRowsArgs = {
  input: GetDataTableRowsInput;
};


export type QueryGetDataTableRowsSearchArgs = {
  input: GetDataTableRowsSearchInput;
};


export type QueryGetDataTableValidationSummaryArgs = {
  input: GetDataTableValidationSummaryInput;
};


export type QueryGetDependantColumnsArgs = {
  input: GetDependantColumnsInput;
};


export type QueryGetDependentRulesByGroupIdArgs = {
  input: GetDependentRulesByGroupIdInput;
};


export type QueryGetExportDownloadUrlsArgs = {
  input: GetExportDownloadUrlsInput;
};


export type QueryGetExportsByTableIdArgs = {
  input: GetExportsByTableIdInput;
};


export type QueryGetGroupArgs = {
  input: GetGroupInput;
};


export type QueryGetGroupHasChildrenArgs = {
  input: GetGroupHasChildrenInput;
};


export type QueryGetGroupPredictionArgs = {
  input: GetGroupPredictionInput;
};


export type QueryGetGroupPredictionsArgs = {
  input: GetGroupPredictionsInput;
};


export type QueryGetGroupStructureArgs = {
  input: GetGroupStructureInput;
};


export type QueryGetGroupStructuresArgs = {
  input: GetGroupStructuresInput;
};


export type QueryGetGroupsArgs = {
  input: GetGroupsInput;
};


export type QueryGetGroupsByGroupStructureArgs = {
  input: GetGroupsByGroupStructureInput;
};


export type QueryGetPaginatedConflictingRulesForUploadArgs = {
  input: GetPaginatedConflictingRulesForUploadInput;
};


export type QueryGetPaginatedDanglingRulesArgs = {
  input: GetPaginatedDanglingRulesInput;
};


export type QueryGetPaginatedDuplicateRulesInFileArgs = {
  input: GetPaginatedDuplicateRulesInFileInput;
};


export type QueryGetParentIdsBySearchTermArgs = {
  input: GetParentIdsBySearchTermInput;
};


export type QueryGetParentNamesByChildIdArgs = {
  input: GetParentNamesByChildIdInput;
};


export type QueryGetParentsByChildIdArgs = {
  input: GetParentsByChildIdInput;
};


export type QueryGetPreviewFileArgs = {
  input: GetPreviewFileInput;
};


export type QueryGetRelatedDataColumnsByDataTableArgs = {
  input: GetRelatedDataColumnsByDataTableInput;
};


export type QueryGetRowsSearchWithLabelsArgs = {
  input: GetRowsSearchWithLabelsInput;
};


export type QueryGetRuleCountPerRuleSetByDataColumnArgs = {
  input: GetRuleCountPerRuleSetByDataColumnInput;
};


export type QueryGetSpendCategoriesArgs = {
  input: GetSpendCategoriesInput;
};


export type QueryGetSpendCategoryArgs = {
  input: GetSpendCategoryInput;
};


export type QueryGetTagValuesByColumnIdArgs = {
  input: GetTagValuesByColumnIdInput;
};


export type QuerySearchContactsArgs = {
  input: SearchContactsInput;
};

export type ReapplyDataColumnRulesInput = {
  targetDataColumnId: Scalars['String']['input'];
  whatGroupStructures?: InputMaybe<Scalars['String']['input']>;
  whatRows?: InputMaybe<Scalars['String']['input']>;
};

export type ReapplyDataColumnRulesResponse = {
  __typename: 'ReapplyDataColumnRulesResponse';
  notificationId: Scalars['String']['output'];
};

export type ReapplyDataTableConnectionsInput = {
  dataColumns: Array<Scalars['String']['input']>;
  dataTableId: Scalars['String']['input'];
};

export type ReapplyDataTableConnectionsResponse = {
  __typename: 'ReapplyDataTableConnectionsResponse';
  notificationId: Scalars['String']['output'];
};

export type RelativeDateSetting =
  | 'MAX_DATE_BEFORE_TODAY_SPECIFIC_COLUMN'
  | 'MAX_DATE_COLUMN'
  | 'MAX_DATE_ON_SPECIFIC_COLUMN'
  | 'TODAY';

export type RelativeDateSettingInput =
  | 'MAX_DATE_BEFORE_TODAY_SPECIFIC_COLUMN'
  | 'MAX_DATE_COLUMN'
  | 'MAX_DATE_ON_SPECIFIC_COLUMN'
  | 'TODAY';

export type RemoveDataTableConnectionCriterionInput = {
  dataTableConnectionCriterionId: Scalars['String']['input'];
  dataTableConnectionId: Scalars['String']['input'];
};

export type RemoveDataTableConnectionCriterionResponse = {
  __typename: 'RemoveDataTableConnectionCriterionResponse';
  dataTableConnection: DataTableConnection;
};

export type RemoveGhostRowsInput = {
  dataTableId: Scalars['String']['input'];
};

export type RemoveGhostRowsResponse = {
  __typename: 'RemoveGhostRowsResponse';
  notificationId: Scalars['String']['output'];
};

export type ReorderDataColumnRuleSetsInput = {
  dataColumnId: Scalars['String']['input'];
  ruleSetChanges: Array<RuleSetChangeInput>;
};

export type ReorderDataColumnRuleSetsResponse = {
  __typename: 'ReorderDataColumnRuleSetsResponse';
  notificationId: Scalars['String']['output'];
};

export type ReplaceDataColumnWithNewDataTypeInput = {
  dataColumnId: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  newDataType: DataColumnTypeInput;
  toList: Scalars['Boolean']['input'];
};

export type ReplaceDataColumnWithNewDataTypeResponse = {
  __typename: 'ReplaceDataColumnWithNewDataTypeResponse';
  newDataColumn: DataColumn;
  notificationIds: Array<Scalars['String']['output']>;
};

export type RuleFilterType =
  | 'CONTAINS'
  | 'EQUALS'
  | 'GREATER_THAN'
  | 'LESS_THAN'
  | 'MIGRATION_RULE'
  | 'STARTS_WITH';

export type RuleFilterTypeInput =
  | 'CONTAINS'
  | 'EQUALS'
  | 'GREATER_THAN'
  | 'LESS_THAN'
  | 'MIGRATION_RULE'
  | 'STARTS_WITH';

export type RuleSetChangeInput = {
  newPrecedence: Scalars['Int']['input'];
  ruleSetId: Scalars['String']['input'];
};

export type RuleSetIdWithRuleCount = {
  __typename: 'RuleSetIdWithRuleCount';
  ruleCount: Scalars['Int']['output'];
  ruleSetId: Scalars['String']['output'];
};

export type RunningRules = {
  __typename: 'RunningRules';
  dataColumnId: Scalars['String']['output'];
  selection: Array<Filter>;
};

export type SaveCategoryInput = {
  categoryId: Scalars['String']['input'];
};

export type SaveCategoryResponse = {
  __typename: 'SaveCategoryResponse';
  ack: Scalars['Boolean']['output'];
};

export type SearchContactsInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort: SortInput;
  terms: ContactTermsInput;
};

export type SearchContactsResponse = {
  __typename: 'SearchContactsResponse';
  count: Scalars['Int']['output'];
  next: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  prev: Scalars['Int']['output'];
  result: Array<Contact>;
  total: Scalars['Int']['output'];
};

export type SortDirectionsInput =
  | 'asc'
  | 'desc';

export type SortInput = {
  by: ContactFieldsInput;
  dir: DirectionsInput;
};

export type SortingArrayInput = {
  dataColumnId: Scalars['String']['input'];
  order: SortDirectionsInput;
};

export type SpecificationInput =
  | 'ACCENTURE';

export type SpendCategory = {
  __typename: 'SpendCategory';
  hasChildren: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  numChildren?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
};

export type StartAggregatedViewInput = {
  id: Scalars['String']['input'];
};

export type StartAggregatedViewResponse = {
  __typename: 'StartAggregatedViewResponse';
  notificationId: Scalars['String']['output'];
};

export type StartAutofillGroupStructureInput = {
  dataTableId: Scalars['String']['input'];
  fields: Array<AutofillGroupStructureFieldInputInput>;
  groupStructureId: Scalars['String']['input'];
};

export type StartAutofillGroupStructureResponse = {
  __typename: 'StartAutofillGroupStructureResponse';
  notificationId: Scalars['String']['output'];
};

export type StartDefaultSetupInput = {
  relate: Scalars['Boolean']['input'];
  specification: SpecificationInput;
};

export type StartDefaultSetupResponse = {
  __typename: 'StartDefaultSetupResponse';
  success: Scalars['Boolean']['output'];
};

export type StartFindConflictingRulesForUploadInput = {
  fieldCombinations: Array<ElasticFieldWithOptionalRelationalFieldInput>;
  fileId: Scalars['String']['input'];
  levelDepth: Scalars['Int']['input'];
  nonIncludedGroupLevels: Array<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  targetDataColumnId: Scalars['String']['input'];
};

export type StartFindConflictingRulesForUploadResponse = {
  __typename: 'StartFindConflictingRulesForUploadResponse';
  conflictingRulesStatus: AsyncDataStatus;
  duplicateRulesStatus: AsyncDataStatus;
};

export type StartFindDanglingRulesTaskInput = {
  dataTableId: Scalars['String']['input'];
  targetDataColumnId: Scalars['String']['input'];
};

export type StartFindDanglingRulesTaskResponse = {
  __typename: 'StartFindDanglingRulesTaskResponse';
  status: AsyncDataStatus;
};

export type SupplierContacts = {
  __typename: 'SupplierContacts';
  contacts: Array<Contact>;
  supplierId: Scalars['String']['output'];
};

export type TagConfiguration = {
  __typename: 'TagConfiguration';
  enumValues: Array<EnumElement>;
  id: Scalars['ID']['output'];
};

export type TestDataTableConnectionInput = {
  dataTableConnection: DataTableConnectionInput;
  filtersJSON: Scalars['String']['input'];
};

export type TestDataTableConnectionResponse = {
  __typename: 'TestDataTableConnectionResponse';
  baseTableRowsWithMatch: Scalars['Int']['output'];
  matchesJSON: Scalars['String']['output'];
  numberOfTargetRowsTested: Scalars['Int']['output'];
  targetRowsWithNoMatch: Scalars['Int']['output'];
};

export type UnsaveCategoryInput = {
  categoryId: Scalars['String']['input'];
};

export type UnsaveCategoryResponse = {
  __typename: 'UnsaveCategoryResponse';
  ack: Scalars['Boolean']['output'];
};

export type UpdatableAggregatedViewFieldsInput = {
  aggregationsToAdd: Array<CreateAggregationInput>;
  aggregationsToDelete: Array<Scalars['String']['input']>;
  filters: Array<AnalysisFilterInput>;
  groupByFieldsToAdd: Array<Scalars['String']['input']>;
  groupByFieldsToDelete: Array<Scalars['String']['input']>;
};

export type UpdatableDataTableFieldsInput = {
  dataTableCollectionId?: InputMaybe<Scalars['String']['input']>;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameColumnId?: InputMaybe<Scalars['String']['input']>;
  relativeDateColumnId?: InputMaybe<Scalars['String']['input']>;
  relativeDateSetting?: InputMaybe<RelativeDateSettingInput>;
};

export type UpdateAggregatedViewInput = {
  id: Scalars['String']['input'];
  mask: Array<Scalars['String']['input']>;
  update: UpdatableAggregatedViewFieldsInput;
};

export type UpdateAggregatedViewResponse = {
  __typename: 'UpdateAggregatedViewResponse';
  entity: AggregatedView;
};

export type UpdateDataColumnInput = {
  dataTableId: Scalars['String']['input'];
  dataType?: InputMaybe<DataColumnTypeInput>;
  externalSourceKey?: InputMaybe<Scalars['String']['input']>;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  referencedId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDataColumnResponse = {
  __typename: 'UpdateDataColumnResponse';
  dataColumn: DataColumn;
};

export type UpdateDataColumnRuleSetInput = {
  dataColumnRuleSetId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDataColumnRuleSetResponse = {
  __typename: 'UpdateDataColumnRuleSetResponse';
  dataColumnRuleSet: DataColumnRuleSet;
};

export type UpdateDataColumnRulesInput = {
  dataColumnRuleIds: Array<Scalars['String']['input']>;
  newGroupId: Scalars['String']['input'];
  targetDataColumnId: Scalars['String']['input'];
};

export type UpdateDataColumnRulesResponse = {
  __typename: 'UpdateDataColumnRulesResponse';
  notificationId: Scalars['String']['output'];
};

export type UpdateDataTableCollectionInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateDataTableCollectionOrderInput = {
  collectionOrder: Array<DataTableCollectionOrderItemInput>;
};

export type UpdateDataTableCollectionOrderResponse = {
  __typename: 'UpdateDataTableCollectionOrderResponse';
  collectionOrder: Array<DataTableCollectionOrderItem>;
  id: Scalars['String']['output'];
};

export type UpdateDataTableCollectionResponse = {
  __typename: 'UpdateDataTableCollectionResponse';
  dataTableCollection: DataTableCollection;
};

export type UpdateDataTableInput = {
  id: Scalars['String']['input'];
  mask: Array<Scalars['String']['input']>;
  update: UpdatableDataTableFieldsInput;
};

export type UpdateDataTableResponse = {
  __typename: 'UpdateDataTableResponse';
  entity: DataTable;
};

export type UpdateDataTableRowInput = {
  data: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  rowId?: InputMaybe<Scalars['String']['input']>;
  type: ChangeOperationTypeInput;
};

export type UpdateDataTableRowResponse = {
  __typename: 'UpdateDataTableRowResponse';
  id: Scalars['String']['output'];
};

export type UpdateGroupInput = {
  groupStructureId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGroupResponse = {
  __typename: 'UpdateGroupResponse';
  notificationId: Scalars['String']['output'];
};

export type UpdateGroupStructureInput = {
  depth?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  predefinedType?: InputMaybe<GroupStructurePredefinedTypeInput>;
};

export type UpdateGroupStructureResponse = {
  __typename: 'UpdateGroupStructureResponse';
  groupStructure: GroupStructure;
};

export type UploadRulesInput = {
  caseSensitiveGroupMatch: Scalars['Boolean']['input'];
  classificationMode: ClassificationModeInput;
  createUnmatchedGroups: Scalars['Boolean']['input'];
  fieldCombinations: Array<ElasticFieldWithOptionalRelationalFieldInput>;
  fileId: Scalars['String']['input'];
  groupStructureId: Scalars['String']['input'];
  levelDepth: Scalars['Int']['input'];
  nonIncludedGroupLevels: Array<Scalars['String']['input']>;
  overwriteExistingRules: OverwriteExistingRulesInput;
  path?: InputMaybe<Scalars['String']['input']>;
  targetDataColumnId: Scalars['String']['input'];
};

export type UploadRulesResponse = {
  __typename: 'UploadRulesResponse';
  notificationId: Scalars['String']['output'];
};

export type UpsertContactsInput = {
  contacts: Array<ContactInput>;
};

export type UpsertContactsResponse = {
  __typename: 'UpsertContactsResponse';
  result: Array<Contact>;
};

export type ContractDataTableFieldsFragment = { __typename: 'DataTable', id: string, name: string, globalTypeKey?: string | null, elasticIndex: string };

export type DataColumnFieldsFragment = { __typename: 'DataColumn', id: string, name: string, dataType: DataColumnType, dataTableId: string, globalTypeKey?: string | null, referencedId?: string | null, isList: boolean };

export type ContractGroupFieldsFragment = { __typename: 'Group', id: string, name: string, parentId?: string | null, groupStructureId: string };

export type ContractGroupStructureFieldsFragment = { __typename: 'GroupStructure', id: string, depth: number };

export type FilterFieldsFragment = { __typename: 'Filter', field: string, value?: string | null };

export type ContractElasticFieldItemFieldsFragment = { __typename: 'ElasticFieldItem', field: string, label: string, labelField?: string | null, type: string, globalTypeKey?: string | null, min?: number | null, max?: number | null };

export type GetManyDataTablesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManyDataTablesQuery = { __typename: 'Query', getManyDataTables: { __typename: 'GetManyDataTablesResponse', entities: Array<{ __typename: 'DataTable', id: string, name: string, globalTypeKey?: string | null, elasticIndex: string }> } };

export type GetDataTableRowQueryVariables = Exact<{
  input: GetDataTableRowInput;
}>;


export type GetDataTableRowQuery = { __typename: 'Query', getDataTableRow: { __typename: 'GetDataTableRowResponse', dataTableRow: { __typename: 'DataTableRow', id: string, departmentId: string, dataJson: string } } };

export type GetDataTableRowsSearchQueryVariables = Exact<{
  input: GetDataTableRowsSearchInput;
}>;


export type GetDataTableRowsSearchQuery = { __typename: 'Query', getDataTableRowsSearch: { __typename: 'GetDataTableRowsSearchResponse', total: number, dataTableRows: Array<{ __typename: 'DataTableRow', id: string, departmentId: string, dataJson: string }> } };

export type UpdateDataTableRowMutationVariables = Exact<{
  input: UpdateDataTableRowInput;
}>;


export type UpdateDataTableRowMutation = { __typename: 'Mutation', updateDataTableRow: { __typename: 'UpdateDataTableRowResponse', id: string } };

export type DeleteDataRowMutationVariables = Exact<{
  input: DeleteDataRowInput;
}>;


export type DeleteDataRowMutation = { __typename: 'Mutation', deleteDataRow: { __typename: 'DeleteDataRowResponse', deleteId: string } };

export type CreateDataRowMutationVariables = Exact<{
  input: CreateDataRowInput;
}>;


export type CreateDataRowMutation = { __typename: 'Mutation', createDataRow: { __typename: 'CreateDataRowResponse', dataTableRow: { __typename: 'DataTableRow', id: string, departmentId: string, dataJson: string } } };

export type GetAllDataColumnsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDataColumnsQuery = { __typename: 'Query', getAllDataColumns: { __typename: 'GetAllDataColumnsResponse', dataColumns: Array<{ __typename: 'DataColumn', id: string, name: string, dataType: DataColumnType, dataTableId: string, globalTypeKey?: string | null, referencedId?: string | null, isList: boolean }> } };

export type GetGroupQueryVariables = Exact<{
  input: GetGroupInput;
}>;


export type GetGroupQuery = { __typename: 'Query', getGroup: { __typename: 'GetGroupResponse', group: { __typename: 'Group', id: string, name: string, parentId?: string | null, groupStructureId: string } } };

export const ContractDataTableFieldsFragmentDoc = gql`
    fragment ContractDataTableFields on DataTable {
  id
  name
  globalTypeKey
  elasticIndex
}
    `;
export const DataColumnFieldsFragmentDoc = gql`
    fragment DataColumnFields on DataColumn {
  id
  name
  dataType
  dataTableId
  globalTypeKey
  referencedId
  isList
}
    `;
export const ContractGroupFieldsFragmentDoc = gql`
    fragment ContractGroupFields on Group {
  id
  name
  parentId
  groupStructureId
}
    `;
export const ContractGroupStructureFieldsFragmentDoc = gql`
    fragment ContractGroupStructureFields on GroupStructure {
  id
  depth
}
    `;
export const FilterFieldsFragmentDoc = gql`
    fragment FilterFields on Filter {
  field
  value
}
    `;
export const ContractElasticFieldItemFieldsFragmentDoc = gql`
    fragment ContractElasticFieldItemFields on ElasticFieldItem {
  field
  label
  labelField
  type
  globalTypeKey
  min
  max
}
    `;
export const GetManyDataTablesDocument = gql`
    query getManyDataTables {
  getManyDataTables {
    entities {
      ...ContractDataTableFields
    }
  }
}
    ${ContractDataTableFieldsFragmentDoc}`;
export type GetManyDataTablesQueryResult = Apollo.QueryResult<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>;
export const GetDataTableRowDocument = gql`
    query getDataTableRow($input: GetDataTableRowInput!) {
  getDataTableRow(input: $input) {
    dataTableRow {
      id
      departmentId
      dataJson
    }
  }
}
    `;
export type GetDataTableRowQueryResult = Apollo.QueryResult<GetDataTableRowQuery, GetDataTableRowQueryVariables>;
export const GetDataTableRowsSearchDocument = gql`
    query getDataTableRowsSearch($input: GetDataTableRowsSearchInput!) {
  getDataTableRowsSearch(input: $input) {
    total
    dataTableRows {
      id
      departmentId
      dataJson
    }
  }
}
    `;
export type GetDataTableRowsSearchQueryResult = Apollo.QueryResult<GetDataTableRowsSearchQuery, GetDataTableRowsSearchQueryVariables>;
export const UpdateDataTableRowDocument = gql`
    mutation updateDataTableRow($input: UpdateDataTableRowInput!) {
  updateDataTableRow(input: $input) {
    id
  }
}
    `;
export type UpdateDataTableRowMutationFn = Apollo.MutationFunction<UpdateDataTableRowMutation, UpdateDataTableRowMutationVariables>;
export type UpdateDataTableRowMutationResult = Apollo.MutationResult<UpdateDataTableRowMutation>;
export type UpdateDataTableRowMutationOptions = Apollo.BaseMutationOptions<UpdateDataTableRowMutation, UpdateDataTableRowMutationVariables>;
export const DeleteDataRowDocument = gql`
    mutation deleteDataRow($input: DeleteDataRowInput!) {
  deleteDataRow(input: $input) {
    deleteId
  }
}
    `;
export type DeleteDataRowMutationFn = Apollo.MutationFunction<DeleteDataRowMutation, DeleteDataRowMutationVariables>;
export type DeleteDataRowMutationResult = Apollo.MutationResult<DeleteDataRowMutation>;
export type DeleteDataRowMutationOptions = Apollo.BaseMutationOptions<DeleteDataRowMutation, DeleteDataRowMutationVariables>;
export const CreateDataRowDocument = gql`
    mutation createDataRow($input: CreateDataRowInput!) {
  createDataRow(input: $input) {
    dataTableRow {
      id
      departmentId
      dataJson
    }
  }
}
    `;
export type CreateDataRowMutationFn = Apollo.MutationFunction<CreateDataRowMutation, CreateDataRowMutationVariables>;
export type CreateDataRowMutationResult = Apollo.MutationResult<CreateDataRowMutation>;
export type CreateDataRowMutationOptions = Apollo.BaseMutationOptions<CreateDataRowMutation, CreateDataRowMutationVariables>;
export const GetAllDataColumnsDocument = gql`
    query getAllDataColumns {
  getAllDataColumns {
    dataColumns {
      ...DataColumnFields
    }
  }
}
    ${DataColumnFieldsFragmentDoc}`;
export type GetAllDataColumnsQueryResult = Apollo.QueryResult<GetAllDataColumnsQuery, GetAllDataColumnsQueryVariables>;
export const GetGroupDocument = gql`
    query getGroup($input: GetGroupInput!) {
  getGroup(input: $input) {
    group {
      ...ContractGroupFields
    }
  }
}
    ${ContractGroupFieldsFragmentDoc}`;
export type GetGroupQueryResult = Apollo.QueryResult<GetGroupQuery, GetGroupQueryVariables>;

export type GraphqlAsyncData<ResultType> =
    | { type: "not-asked" }
    | { type: "success"; data: ResultType }
    | { type: "error", error: Apollo.ApolloError }
    | { type: "loading" };
function queryResultToGraphqlAsyncData<
    ResultType,
    Key extends keyof ResultType
>(queryKey: Key, loading: boolean, data?: ResultType | null, error?: Apollo.ApolloError): GraphqlAsyncData<ResultType[Key]> {
    if (loading) {
        return { type: "loading" };
    }
    if (error) {
        return { type: "error", error };
    }
    if (data) {
        return { type: "success", data: data[queryKey] };
    }
    return { type: "not-asked" };
}

type GetManyDataTablesQueryHookReturnValue = {
    result: GraphqlAsyncData<GetManyDataTablesQuery["getManyDataTables"]>;
    refetch: (variables?: Partial<GetManyDataTablesQueryVariables>) => void;
}
export function useGetManyDataTablesQuery(
    variables: GetManyDataTablesQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>, 'variables'> = {}
): GetManyDataTablesQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>(GetManyDataTablesDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getManyDataTables", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetManyDataTablesLazyQueryHookReturnValue = [
    (variables: GetManyDataTablesQueryVariables) => Promise<Apollo.QueryResult<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>>,
    { result: GraphqlAsyncData<GetManyDataTablesQuery["getManyDataTables"]>, refetch: (variables: GetManyDataTablesQueryVariables) => Promise<Apollo.QueryResult<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetManyDataTablesQuery>> }
]
export function useGetManyDataTablesLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>, 'variables'> = {}
): GetManyDataTablesLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>(GetManyDataTablesDocument, options);
    return [
        useCallback((variables: GetManyDataTablesQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getManyDataTables", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetManyDataTablesQueryVariables) => query.refetch(variables)
                : (variables: GetManyDataTablesQueryVariables) => action({ variables })
        }
    ];
}
type GetDataTableRowQueryHookReturnValue = {
    result: GraphqlAsyncData<GetDataTableRowQuery["getDataTableRow"]>;
    refetch: (variables?: Partial<GetDataTableRowQueryVariables>) => void;
}
export function useGetDataTableRowQuery(
    variables: GetDataTableRowQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetDataTableRowQuery, GetDataTableRowQueryVariables>, 'variables'> = {}
): GetDataTableRowQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetDataTableRowQuery, GetDataTableRowQueryVariables>(GetDataTableRowDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getDataTableRow", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetDataTableRowLazyQueryHookReturnValue = [
    (variables: GetDataTableRowQueryVariables) => Promise<Apollo.QueryResult<GetDataTableRowQuery, GetDataTableRowQueryVariables>>,
    { result: GraphqlAsyncData<GetDataTableRowQuery["getDataTableRow"]>, refetch: (variables: GetDataTableRowQueryVariables) => Promise<Apollo.QueryResult<GetDataTableRowQuery, GetDataTableRowQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetDataTableRowQuery>> }
]
export function useGetDataTableRowLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetDataTableRowQuery, GetDataTableRowQueryVariables>, 'variables'> = {}
): GetDataTableRowLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetDataTableRowQuery, GetDataTableRowQueryVariables>(GetDataTableRowDocument, options);
    return [
        useCallback((variables: GetDataTableRowQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getDataTableRow", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetDataTableRowQueryVariables) => query.refetch(variables)
                : (variables: GetDataTableRowQueryVariables) => action({ variables })
        }
    ];
}
type GetDataTableRowsSearchQueryHookReturnValue = {
    result: GraphqlAsyncData<GetDataTableRowsSearchQuery["getDataTableRowsSearch"]>;
    refetch: (variables?: Partial<GetDataTableRowsSearchQueryVariables>) => void;
}
export function useGetDataTableRowsSearchQuery(
    variables: GetDataTableRowsSearchQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetDataTableRowsSearchQuery, GetDataTableRowsSearchQueryVariables>, 'variables'> = {}
): GetDataTableRowsSearchQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetDataTableRowsSearchQuery, GetDataTableRowsSearchQueryVariables>(GetDataTableRowsSearchDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getDataTableRowsSearch", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetDataTableRowsSearchLazyQueryHookReturnValue = [
    (variables: GetDataTableRowsSearchQueryVariables) => Promise<Apollo.QueryResult<GetDataTableRowsSearchQuery, GetDataTableRowsSearchQueryVariables>>,
    { result: GraphqlAsyncData<GetDataTableRowsSearchQuery["getDataTableRowsSearch"]>, refetch: (variables: GetDataTableRowsSearchQueryVariables) => Promise<Apollo.QueryResult<GetDataTableRowsSearchQuery, GetDataTableRowsSearchQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetDataTableRowsSearchQuery>> }
]
export function useGetDataTableRowsSearchLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetDataTableRowsSearchQuery, GetDataTableRowsSearchQueryVariables>, 'variables'> = {}
): GetDataTableRowsSearchLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetDataTableRowsSearchQuery, GetDataTableRowsSearchQueryVariables>(GetDataTableRowsSearchDocument, options);
    return [
        useCallback((variables: GetDataTableRowsSearchQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getDataTableRowsSearch", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetDataTableRowsSearchQueryVariables) => query.refetch(variables)
                : (variables: GetDataTableRowsSearchQueryVariables) => action({ variables })
        }
    ];
}
type UpdateDataTableRowMutationHookReturnValue = [
    (variables: UpdateDataTableRowMutationVariables) => Promise<Apollo.FetchResult<UpdateDataTableRowMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<UpdateDataTableRowMutation["updateDataTableRow"]> }
]
export function useUpdateDataTableRowMutation(
    options: Omit<Apollo.MutationHookOptions<UpdateDataTableRowMutation, UpdateDataTableRowMutationVariables>, 'variables'> = {}
): UpdateDataTableRowMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<UpdateDataTableRowMutation, UpdateDataTableRowMutationVariables>(UpdateDataTableRowDocument, options)
    return [
        useMemo(() => (variables: UpdateDataTableRowMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<UpdateDataTableRowMutation, UpdateDataTableRowMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("updateDataTableRow", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type DeleteDataRowMutationHookReturnValue = [
    (variables: DeleteDataRowMutationVariables) => Promise<Apollo.FetchResult<DeleteDataRowMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<DeleteDataRowMutation["deleteDataRow"]> }
]
export function useDeleteDataRowMutation(
    options: Omit<Apollo.MutationHookOptions<DeleteDataRowMutation, DeleteDataRowMutationVariables>, 'variables'> = {}
): DeleteDataRowMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<DeleteDataRowMutation, DeleteDataRowMutationVariables>(DeleteDataRowDocument, options)
    return [
        useMemo(() => (variables: DeleteDataRowMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<DeleteDataRowMutation, DeleteDataRowMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("deleteDataRow", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type CreateDataRowMutationHookReturnValue = [
    (variables: CreateDataRowMutationVariables) => Promise<Apollo.FetchResult<CreateDataRowMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<CreateDataRowMutation["createDataRow"]> }
]
export function useCreateDataRowMutation(
    options: Omit<Apollo.MutationHookOptions<CreateDataRowMutation, CreateDataRowMutationVariables>, 'variables'> = {}
): CreateDataRowMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<CreateDataRowMutation, CreateDataRowMutationVariables>(CreateDataRowDocument, options)
    return [
        useMemo(() => (variables: CreateDataRowMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<CreateDataRowMutation, CreateDataRowMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("createDataRow", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type GetAllDataColumnsQueryHookReturnValue = {
    result: GraphqlAsyncData<GetAllDataColumnsQuery["getAllDataColumns"]>;
    refetch: (variables?: Partial<GetAllDataColumnsQueryVariables>) => void;
}
export function useGetAllDataColumnsQuery(
    variables: GetAllDataColumnsQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetAllDataColumnsQuery, GetAllDataColumnsQueryVariables>, 'variables'> = {}
): GetAllDataColumnsQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetAllDataColumnsQuery, GetAllDataColumnsQueryVariables>(GetAllDataColumnsDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getAllDataColumns", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetAllDataColumnsLazyQueryHookReturnValue = [
    (variables: GetAllDataColumnsQueryVariables) => Promise<Apollo.QueryResult<GetAllDataColumnsQuery, GetAllDataColumnsQueryVariables>>,
    { result: GraphqlAsyncData<GetAllDataColumnsQuery["getAllDataColumns"]>, refetch: (variables: GetAllDataColumnsQueryVariables) => Promise<Apollo.QueryResult<GetAllDataColumnsQuery, GetAllDataColumnsQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetAllDataColumnsQuery>> }
]
export function useGetAllDataColumnsLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetAllDataColumnsQuery, GetAllDataColumnsQueryVariables>, 'variables'> = {}
): GetAllDataColumnsLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetAllDataColumnsQuery, GetAllDataColumnsQueryVariables>(GetAllDataColumnsDocument, options);
    return [
        useCallback((variables: GetAllDataColumnsQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getAllDataColumns", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetAllDataColumnsQueryVariables) => query.refetch(variables)
                : (variables: GetAllDataColumnsQueryVariables) => action({ variables })
        }
    ];
}
type GetGroupQueryHookReturnValue = {
    result: GraphqlAsyncData<GetGroupQuery["getGroup"]>;
    refetch: (variables?: Partial<GetGroupQueryVariables>) => void;
}
export function useGetGroupQuery(
    variables: GetGroupQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetGroupQuery, GetGroupQueryVariables>, 'variables'> = {}
): GetGroupQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetGroupQuery, GetGroupQueryVariables>(GetGroupDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getGroup", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetGroupLazyQueryHookReturnValue = [
    (variables: GetGroupQueryVariables) => Promise<Apollo.QueryResult<GetGroupQuery, GetGroupQueryVariables>>,
    { result: GraphqlAsyncData<GetGroupQuery["getGroup"]>, refetch: (variables: GetGroupQueryVariables) => Promise<Apollo.QueryResult<GetGroupQuery, GetGroupQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetGroupQuery>> }
]
export function useGetGroupLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetGroupQuery, GetGroupQueryVariables>, 'variables'> = {}
): GetGroupLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetGroupQuery, GetGroupQueryVariables>(GetGroupDocument, options);
    return [
        useCallback((variables: GetGroupQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getGroup", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetGroupQueryVariables) => query.refetch(variables)
                : (variables: GetGroupQueryVariables) => action({ variables })
        }
    ];
}