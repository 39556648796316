import { Plus } from "@ignite-analytics/icons";
import { Stack } from "@mui/material";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { track } from "@ignite-analytics/track";
import { Controller, useFormContext } from "react-hook-form";
import { EmtpyCardContent } from "@/components/EmptyCardContent";
import { RiddleMenu } from "@/components/RiddleMenu";
import { ContactPerson, EditContactPersonDialog } from "@/components/UserDisplay";
import { useUserOrThrow } from "@/contexts/useUser";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { addContact, editContact, getContactsBySupplierId } from "@/hooks/supplierContacts";
import { UpdateContractFormParams } from "@/types";
import { SidebarCard } from "./SidebarCard";

const ContractContactCard_ContractFragment = graphql(`
    fragment ContractContactCard_ContractFragment on Contract {
        id
        supplier {
            id
        }
        contactPersons {
            id
        }
    }
`);

interface ContractContactCardProps {
    contract: FragmentType<typeof ContractContactCard_ContractFragment>;
    editMode: boolean;
}

export const ContractContactCard: React.FC<ContractContactCardProps> = (props) => {
    const [addContactDialogOpen, setAddContactDialogOpen] = useState(false);
    const [selectExistingContactAnchorEl, setSelectExistingContactAnchorEl] = useState<HTMLElement | null>(null);
    const contract = getFragmentData(ContractContactCard_ContractFragment, props.contract);

    const { formatMessage } = useIntl();
    const { tenant } = useUserOrThrow();

    const supplierId = contract.supplier?.id ?? null; // for key
    const supplierContacts = useQuery({
        placeholderData: keepPreviousData,
        enabled: true,
        queryKey: [tenant, "contracts", contract.id, "contacts", supplierId, "t"],
        queryFn: () => getContactsBySupplierId(supplierId),
        staleTime: 1000 * 1,
    });
    const form = useFormContext<UpdateContractFormParams>();
    const contactPersonIds =
        contract.contactPersons?.filter((contactPerson) => !!contactPerson).map((contactPerson) => contactPerson.id) ??
        [];
    return (
        <SidebarCard
            label={formatMessage({ defaultMessage: "Contact persons" })}
            editLabel={formatMessage({ defaultMessage: "Add new" })}
            edit={props.editMode}
            editDisabled={!contract.supplier}
            editDisabledTooltip={
                <FormattedMessage defaultMessage="You must set a supplier for the contract to add a contact person" />
            }
            onClickEdit={(e) => {
                if (supplierContacts.data?.length === 0) {
                    // no point in showing the menu of contacts if there are none
                    // --> shortcut to add contact dialog
                    setAddContactDialogOpen(true);
                    return;
                }
                setSelectExistingContactAnchorEl(e.currentTarget);
            }}
        >
            <Controller
                name="contactPersonIds"
                control={form.control}
                defaultValue={contactPersonIds}
                render={({ field }) => (
                    <Stack gap={2}>
                        {(supplierContacts.data ?? [])
                            .filter((c) => field.value.includes(c.id))
                            .map((contact) => (
                                <ContactPerson
                                    firstName={contact.firstName}
                                    lastName={contact.lastName}
                                    edit={props.editMode}
                                    onRemove={async () => {
                                        field.onChange(field.value.filter((id) => id !== contact.id));
                                    }}
                                    onEdit={async (values) => {
                                        await editContact(contact.id, values);
                                        supplierContacts.refetch();
                                    }}
                                    key={contact.id}
                                    email={contact.email}
                                    phone={contact.phone}
                                />
                            ))}
                        {field.value.length === 0 && (
                            <EmtpyCardContent
                                title={formatMessage({ defaultMessage: "No contact persons" })}
                                subtitle={formatMessage({
                                    defaultMessage: "You can add contact persons by editing this contract.",
                                })}
                            />
                        )}
                        <RiddleMenu
                            entries={[
                                {
                                    label: formatMessage({ defaultMessage: "Create new contact" }),
                                    divider: true,
                                    icon: { component: <Plus />, pos: "start" },
                                    onClick: () => {
                                        setAddContactDialogOpen(true);
                                        setSelectExistingContactAnchorEl(null);
                                    },
                                },
                                ...(supplierContacts.data ?? []).map((contact) => ({
                                    label: `${contact.firstName} ${contact.lastName}`,
                                    disabled: field.value.includes(contact.id),
                                    onClick: () => {
                                        track("Contracts: Add supplier contact");
                                        field.onChange([...field.value, contact.id]);
                                        setSelectExistingContactAnchorEl(null);
                                    },
                                })),
                            ]}
                            sx={{ width: 240 }}
                            onClose={() => {
                                setSelectExistingContactAnchorEl(null);
                            }}
                            anchorEl={selectExistingContactAnchorEl}
                        />
                        <EditContactPersonDialog
                            open={addContactDialogOpen}
                            onClose={() => {
                                setAddContactDialogOpen(false);
                            }}
                            onEdit={async (values) => {
                                if (!contract.supplier) {
                                    return;
                                }
                                const id = await addContact(contract.supplier.id, values);
                                if (!id) {
                                    return;
                                }
                                await supplierContacts.refetch();
                                field.onChange([...field.value, id]);
                            }}
                        />
                    </Stack>
                )}
            />
        </SidebarCard>
    );
};
