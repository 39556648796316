import { apolloClient } from "@/contexts";
import { captureMessage } from "@/errorHandling/errors";
import { graphql } from "@/generated";

const getGroupQuery = graphql(`
    query getGroupQuery($input: GetGroupInput!) {
        getGroup(input: $input) {
            group {
                id
                name
            }
        }
    }
`);

const getGroupPathQuery = graphql(`
    query getGroupPath($input: GetParentsByChildIdInput!) {
        getParentsByChildId(input: $input) {
            groups {
                id
                name
            }
        }
    }
`);

// finds all ancestor until a given group. Does not include itself, and the
// first element is the root
async function getParentChain(groupID: string): Promise<{ name: string; id: string }[]> {
    const { data, error } = await apolloClient.query({
        query: getGroupPathQuery,
        variables: { input: { childId: groupID } },
    });
    if (error) {
        captureMessage("Failed to get ancestors", { extra: { error } });
    }
    const got = data.getParentsByChildId.groups.map((g) => ({
        name: g.name,
        id: g.id,
    }));
    return got;
}

type Group = { name: string; id: string };
export type GroupAncestors = { elem: { name: string; id: string | null }; parents: Group[]; loading?: boolean };

export function getGroupAncestors(ids: string[]): Promise<GroupAncestors[]> {
    const promises = ids.map(async (id) => {
        const res = await apolloClient.query({ query: getGroupQuery, variables: { input: { id } } });
        const chain = await getParentChain(id);
        if (res.error) {
            captureMessage("Failed to get group", { extra: { error: res.error } });
        }
        return { elem: { name: res.data.getGroup.group.name, id }, parents: chain };
    });
    return Promise.all(promises);
}
