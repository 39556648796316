import { Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { FragmentType, getFragmentData } from "@/generated/fragment-masking";
import { graphql } from "@/generated/gql";
import { AccessLevelActivity } from "./AccessLevelActivity";
import { CommentActivity } from "./CommentActivity";
import { DescriptionActivity } from "./DescriptionActivity";
import { DocumentAddedActivity } from "./DocumentAddedActivity";
import { DocumentDeletedActivity } from "./DocumentDeletedActivity";
import { EndDateActivity } from "./EndDateActivity";
import { RenewalDateActivity } from "./RenewalDateActivity";
import { ResponsibleActivity } from "./ResponsibleActivity";
import { StartDateActivity } from "./StartDateActivity";
import { SupplierActivity } from "./SupplierActivity";
import { TitleActivity } from "./TitleActivity";
import { TotalSpendActivity } from "./TotalSpendActivity";

const Activity_ContractActivity = graphql(`
    fragment Activity_ContractActivity on ContractActivity {
        ... on TitleContractActivity {
            ...TitleActivity_TitleContractActivity
        }
        ... on ResponsiblesContractActivity {
            ...ResponsibleActivity_Responsible
        }
        ... on AccessLevelContractActivity {
            ...AccessLevelActivity_AccessLevelContractActivity
        }
        ... on DescriptionContractActivity {
            ...DescriptionActivity_DescriptionContractActivity
        }
        ... on EndDateContractActivity {
            ...EndDateActivity_EndDateContractActivity
        }
        ... on SupplierContractActivity {
            ...SupplierActivity_SupplierContractActivity
        }
        ... on RenewalDateContractActivity {
            ...RenewalDateActivity_RenewalDateContractActivity
        }
        ... on StartDateContractActivity {
            ...StartDateActivity_StartDateContractActivity
        }
        ... on TotalSpendContractActivity {
            ...TotalSpendActivity_TotalSpendContractActivity
        }
        ... on CommentContractActivity {
            ...CommentActivity_CommentContractActivity
        }
        ... on DocumentAddedContractActivity {
            ...DocumentAddedActivity_DocumentAddedContractActivity
        }
        ... on DocumentDeletedContractActivity {
            ...DocumentDeletedActivity_DocumentDeletedContractActivity
        }
    }
`);

type Activity = FragmentType<typeof Activity_ContractActivity>;

interface ActivityProps {
    activity: Activity;
}

export const Activity: React.FC<ActivityProps> = (props) => {
    const activity = getFragmentData(Activity_ContractActivity, props.activity);

    switch (activity.__typename) {
        case "TitleContractActivity":
            return <TitleActivity activity={activity} />;
        case "ResponsiblesContractActivity":
            return <ResponsibleActivity activity={activity} />;
        case "AccessLevelContractActivity":
            return <AccessLevelActivity activity={activity} />;
        case "DescriptionContractActivity":
            return <DescriptionActivity activity={activity} />;
        case "EndDateContractActivity":
            return <EndDateActivity activity={activity} />;
        case "SupplierContractActivity":
            return <SupplierActivity activity={activity} />;
        case "RenewalDateContractActivity":
            return <RenewalDateActivity activity={activity} />;
        case "StartDateContractActivity":
            return <StartDateActivity activity={activity} />;
        case "TotalSpendContractActivity":
            return <TotalSpendActivity activity={activity} />;
        case "CommentContractActivity":
            return <CommentActivity activity={activity} />;
        case "DocumentAddedContractActivity":
            return <DocumentAddedActivity activity={activity} />;
        case "DocumentDeletedContractActivity":
            return <DocumentDeletedActivity activity={activity} />;
        default:
            return (
                <Stack>
                    <Typography variant="body2" color="text.secondary">
                        {activity.__typename} <FormattedMessage defaultMessage="not implemented" />
                    </Typography>
                </Stack>
            );
    }
};
