import { ChevronDown, Filter, Trash } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Button, Divider, Popover, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { AsyncMultiSelect } from "./AsyncMultiSelect";
import { DateRange } from "./DateRange";
import { useGetAppliedValueLabel } from "./getAppliedFilterValue";
import { NumberRange } from "./NumberRange";
import { PresetSelect } from "./PresetSelect";
import { TableFilter } from "./types";

const FilterContent: React.FC<{
    filter: TableFilter;
    setFilter: (filter: TableFilter) => void;
}> = ({ filter, setFilter }) => {
    switch (filter.type) {
        case "presetMultiSelect":
            return <PresetSelect onChange={setFilter} filter={filter} />;
        case "asyncMultiSelect":
            return <AsyncMultiSelect onChange={setFilter} filter={filter} />;
        case "dateRange":
            return <DateRange onChange={setFilter} filter={filter} />;
        case "numberRange":
            return <NumberRange onChange={setFilter} filter={filter} />;
        default:
            return null;
    }
};

// New component for active filter buttons
export const ActiveFilter: React.FC<{
    activeFilter: TableFilter;
    onChange: (changedFilters: TableFilter[]) => void;
    onRemove: () => void;
    onCloseEmptyFilter: (filter: TableFilter) => void;
}> = ({ activeFilter, onChange, onRemove, onCloseEmptyFilter }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState<TableFilter>(activeFilter);
    const { getAppliedFilterValueLabel } = useGetAppliedValueLabel();

    const handleOpen = () => {
        setOpen(true);
        setFilter(activeFilter);
    };

    const handleClose = () => {
        if (!activeFilter.value || (Array.isArray(activeFilter.value) && activeFilter.value.length === 0)) {
            onCloseEmptyFilter(activeFilter);
            return;
        }
        setFilter(activeFilter);
        setOpen(false);
    };

    const handleApply = () => {
        track("Contracts > Contract monitoring > Apply filter", { filter: filter.id, activeFilter: true });
        onChange([filter]);
        setOpen(false);
    };

    useEffect(() => {
        if (ref.current && activeFilter.openFilterOnAdd) {
            setOpen(true);
        }
    }, [activeFilter.openFilterOnAdd]);

    // Update the active filter when show only my contracts is toggled
    useEffect(() => {
        setFilter(activeFilter);
    }, [activeFilter]);

    return (
        <Stack ref={ref}>
            <Button
                onClick={handleOpen}
                variant="outlined"
                size="small"
                color="quarternary"
                // TODO: Get the color from the theme
                sx={{ backgroundColor: "#EFF8FE" }}
                startIcon={<Filter />}
                endIcon={
                    <ChevronDown
                        sx={{
                            transform: open ? "rotate(180deg)" : "rotate(0)",
                            transition: "transform 200ms ease-in-out",
                        }}
                    />
                }
            >
                <Typography variant="textSm" color="textTextSecondary" noWrap>
                    {getAppliedFilterValueLabel(filter)}
                </Typography>
            </Button>
            <Popover
                open={open}
                anchorEl={ref.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: -4,
                    horizontal: "left",
                }}
            >
                <Stack spacing={2} sx={{ minWidth: 300 }}>
                    <Stack paddingTop={2}>
                        <FilterContent filter={filter} setFilter={setFilter} />
                    </Stack>
                    <Stack>
                        <Divider />
                        <Stack direction="row" spacing={1} justifyContent="space-between" padding={2}>
                            <Button onClick={onRemove} color="error" variant="text" startIcon={<Trash />} size="small">
                                <FormattedMessage defaultMessage="Remove filter" />
                            </Button>
                            <Button onClick={handleApply} size="small" disabled={!filter.value}>
                                <FormattedMessage defaultMessage="Apply" />
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Popover>
        </Stack>
    );
};
