import { Chip, MenuItem, Stack } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { UserItem } from "../../UserAvatars";

import { ContractPermissions } from "@/hooks/useContractPermissions/types";
import { UserRoleChip } from "@/pages/detail/components/ContractSidebar/AccessCard/UserRoleChip";

interface EditPermissionsSectionProps {
    permissions: ContractPermissions[];
}

export const EditPermissionsSection: React.FC<EditPermissionsSectionProps> = ({ permissions }) => {
    if (permissions.length === 0) {
        return null;
    }

    return (
        <Stack>
            {permissions.map((permission) => (
                <MenuItem key={permission.user.id}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                        <UserItem user={permission.user} />
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {/* Only show contract responsible chip for single contract edit */}
                            {permission.writes.length === 1 && permission.writes[0].isResponsible && (
                                <Chip label={<FormattedMessage defaultMessage="Contract responsible" />} />
                            )}
                            <UserRoleChip role={permission.roleId} />
                        </Stack>
                    </Stack>
                </MenuItem>
            ))}
        </Stack>
    );
};
