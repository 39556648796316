import { FormattedMessage } from "react-intl";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { RenderActivity } from "./RenderActivity";

const DescriptionActivity_DescriptionContractActivity = graphql(`
    fragment DescriptionActivity_DescriptionContractActivity on DescriptionContractActivity {
        description
        prevDescription
    }
`);

interface DescriptionActivityProps {
    activity: FragmentType<typeof DescriptionActivity_DescriptionContractActivity>;
}

export const DescriptionActivity: React.FC<DescriptionActivityProps> = (props) => {
    const activity = getFragmentData(DescriptionActivity_DescriptionContractActivity, props.activity);
    return (
        <RenderActivity
            title={<FormattedMessage defaultMessage="Description" />}
            prev={activity.prevDescription}
            value={activity.description}
        />
    );
};
