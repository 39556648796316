import { useMutation } from "@apollo/client";
import { Button, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { track } from "@ignite-analytics/track";
import { useErrorHandler } from "@/errorHandling/ErrorHandlerContext";
import { graphql } from "@/generated/gql";
import { detailRoute } from "@/routes/detail/$id";
import { ActivityLog_ContractActivities } from "..";

export const CreateCommentMutation = graphql(`
    mutation CreateComment($input: CreateCommentRequest!) {
        createComment(input: $input) {
            id
        }
    }
`);

export const CommentSection = () => {
    const { id } = detailRoute.useParams();
    const [comment, setComment] = useState("");
    const { formatMessage } = useIntl();
    const { handleError } = useErrorHandler();
    const [createComment] = useMutation(CreateCommentMutation, {
        refetchQueries: [
            {
                query: ActivityLog_ContractActivities,
                variables: {
                    input: {
                        contractId: id,
                    },
                },
            },
        ],
    });

    const handleSubmit = async () => {
        track("Contracts: Add comment");
        createComment({
            variables: {
                input: {
                    comment,
                    contractID: id,
                },
            },
            onCompleted: () => {
                setComment("");
            },
            onError: handleError,
        });
    };

    return (
        <Stack direction="row" spacing={2} pb={2}>
            <TextField
                fullWidth
                multiline
                rows={1}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder={formatMessage({ defaultMessage: "Add a comment..." })}
                variant="outlined"
            />
            <Button variant="contained" onClick={handleSubmit} disabled={!comment.trim()}>
                <FormattedMessage defaultMessage="Comment" />
            </Button>
        </Stack>
    );
};
