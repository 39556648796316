import { Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Contract } from "@/types";
import { AccessChip } from "./AccessChip";

import { InformationTooltip } from "@/components/InformationTooltip";
import { ManageSingleContractAccess } from "@/components/ManageAccessPopup";
import { getContractById } from "@/pages/detail/loaders";
import { detailRoute } from "@/routes/detail/$id/index";
import { ViewAllUsersPopover } from "./ViewAllUsersPopover";

import { UserAvatarWithRole } from "@/components/UserAvatars/UserAvatarWithRole";
import { useContractPermissions } from "@/hooks/useContractPermissions/index";
import { FragmentType, getFragmentData, graphql } from "@/generated";

const AccessCard_ContractFragment = graphql(`
    fragment AccessCard_ContractFragment on Contract {
        ...ManageSingleContractAccess_ContractFragment
        id
        isPrivate
    }
`);

interface AccessCardProps {
    contract: FragmentType<typeof AccessCard_ContractFragment>;
    canEdit: boolean;
    editMode: boolean;
    onContractChange: (contract: Contract) => void;
}

export const AccessCard: React.FC<AccessCardProps> = ({ canEdit, editMode, onContractChange, ...props }) => {
    const { id } = detailRoute.useParams();
    const [manageAccessPopupOpen, setManageAccessPopupOpen] = useState(false);
    const contract = getFragmentData(AccessCard_ContractFragment, props.contract);

    const MAX_AVATARS = 5;

    const { editPermissions, readPermissions, refetch } = useContractPermissions([contract.id]);

    const handleRefetchContract = async () => {
        onContractChange(await getContractById(id));
        await refetch();
    };

    return (
        <Stack p={2.5} gap={2.5} component={Paper} elevation={1} alignItems="stretch">
            <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" gap={1}>
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage defaultMessage="Access control" />
                    </Typography>
                    <InformationTooltip
                        content={
                            <Stack spacing={1}>
                                <Stack>
                                    <Typography variant="textMd" fontWeight={600}>
                                        <FormattedMessage defaultMessage="Viewer role" />
                                    </Typography>
                                    <Typography variant="textSm">
                                        <FormattedMessage defaultMessage="Users with viewer role can view public contracts, and can be granted read access to restricted contracts." />
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <Typography variant="textMd" fontWeight={600}>
                                        <FormattedMessage defaultMessage="Editor role" />
                                    </Typography>
                                    <Typography variant="textSm">
                                        <FormattedMessage defaultMessage="Users with editor role can view all public contracts, and can be granted read acceess to restricted contracts and can get edit access to contracts they are responsible for." />
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <Typography variant="textMd" fontWeight={600}>
                                        <FormattedMessage defaultMessage="Admin role" />
                                    </Typography>
                                    <Typography variant="textSm">
                                        <FormattedMessage defaultMessage="Users with admin role can view, edit and delete any contract." />
                                    </Typography>
                                </Stack>
                            </Stack>
                        }
                        placement="bottom"
                    />
                </Stack>
                <AccessChip isPrivate={contract.isPrivate} />
            </Stack>

            <Stack spacing={1}>
                {/* View access */}
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Stack spacing={1} direction="row" alignItems="center">
                        <Typography variant="textMd" fontWeight={500}>
                            <FormattedMessage defaultMessage="View access" />
                        </Typography>
                    </Stack>

                    {editMode && (
                        <Button
                            color="secondary"
                            size="small"
                            disabled={!canEdit}
                            onClick={() => setManageAccessPopupOpen(true)}
                        >
                            <FormattedMessage defaultMessage="Edit" />
                        </Button>
                    )}
                </Stack>
                {contract.isPrivate && (
                    <>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <UserAvatarWithRole
                                permissions={readPermissions}
                                maxAvatars={MAX_AVATARS}
                                avatarSize={32}
                                showAllInSurplus
                            />
                            <ViewAllUsersPopover permissions={readPermissions} />
                        </Stack>

                        <Stack paddingY={1.25}>
                            <Divider />
                        </Stack>

                        {/* Edit access */}
                        <Stack spacing={1} direction="row" alignItems="center">
                            <Typography variant="textMd" fontWeight={500} color="textTextSecondary">
                                <FormattedMessage defaultMessage="Edit access" />
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <UserAvatarWithRole
                                permissions={editPermissions}
                                maxAvatars={MAX_AVATARS}
                                avatarSize={32}
                                showAllInSurplus
                            />
                            <ViewAllUsersPopover permissions={editPermissions} />
                        </Stack>
                    </>
                )}
                {!contract.isPrivate && (
                    <Typography variant="textSm" fontWeight={500} color="textTextHelper">
                        <FormattedMessage defaultMessage="Everyone in the organization." />
                    </Typography>
                )}
            </Stack>
            <ManageSingleContractAccess
                isOpen={manageAccessPopupOpen}
                onClose={() => setManageAccessPopupOpen(false)}
                refetchContracts={handleRefetchContract}
                contract={contract}
            />
        </Stack>
    );
};
