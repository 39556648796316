import { Button, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Controller, useFormContext } from "react-hook-form";
import { ContactsDropdown } from "@/components/ContactDropdown";

import { InformationTooltip } from "@/components/InformationTooltip";
import { UserAvatarWithRole } from "@/components/UserAvatars/UserAvatarWithRole";
import { useContractPermissions } from "@/hooks/useContractPermissions/index";
import { ViewAllUsersPopover } from "./AccessCard/ViewAllUsersPopover";
import { FragmentType, getFragmentData, graphql } from "@/generated";

const ContractResponsibleCard_ContractFragment = graphql(`
    fragment ContractResponsibleCard_ContractFragment on Contract {
        id
        responsibles {
            id
            firstName
            lastName
            fullName
            email
        }
    }
`);

interface ContractResponsibleCardProps {
    contract: FragmentType<typeof ContractResponsibleCard_ContractFragment>;
    editMode: boolean;
}

export const ContractResponsibleCard: React.FC<ContractResponsibleCardProps> = ({ editMode, ...props }) => {
    const [editOpen, setEditOpen] = useState(false);
    const contract = getFragmentData(ContractResponsibleCard_ContractFragment, props.contract);
    const form = useFormContext();

    // Only show users with admin, editor or contract-editor role
    // can be set as contract responsible
    const { permissions, refetch } = useContractPermissions([contract.id]);

    const contractResponsibleWithPermissions = permissions.filter(
        (permission) =>
            permission.writes.some((write) => write.isResponsible) ||
            permission.reads.some((read) => read.isResponsible)
    );
    const contractResponsible = contractResponsibleWithPermissions.map((permission) => permission.user);
    const options = permissions.map((permission) => permission.user);

    useEffect(() => {
        if (!editMode) {
            setEditOpen(false);
            refetch();
        }
    }, [editMode, refetch]);

    return (
        <Stack p={2.5} gap={2.5} component={Paper} elevation={1} alignItems="stretch">
            <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" gap={1}>
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage defaultMessage="Contract responsible" />
                    </Typography>

                    <InformationTooltip
                        content={
                            <FormattedMessage defaultMessage="Contract responsible can edit or delete contract. They are also the one who will get alerts that are added on this contract" />
                        }
                    />
                </Stack>
                {!editOpen && editMode && (
                    <Button color="secondary" size="small" onClick={() => setEditOpen(true)}>
                        <FormattedMessage defaultMessage="Edit" />
                    </Button>
                )}
            </Stack>
            <Stack>
                {editOpen && editMode ? (
                    <Controller
                        name="responsibles"
                        control={form.control}
                        defaultValue={contractResponsible}
                        render={({ field }) => (
                            <ContactsDropdown
                                multiple
                                defaultValue={field.value}
                                options={options}
                                onChange={(users) => {
                                    field.onChange(users);
                                }}
                            />
                        )}
                    />
                ) : (
                    <Stack direction="row" alignItems="center" gap={1}>
                        <UserAvatarWithRole
                            permissions={contractResponsibleWithPermissions}
                            maxAvatars={5}
                            avatarSize={32}
                        />
                        <ViewAllUsersPopover permissions={contractResponsibleWithPermissions} />
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};
