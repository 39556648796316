import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { FilterModal } from "@ignite-analytics/filters";
import { Divider, Stack } from "@mui/material";
import { GridDensity, GridToolbarContainer } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { SearchField } from "@/pages/list/components/SearchField";
import { TableActionBar } from "@/pages/list/ActionBar/TableActionBar";
import ContractStatusFilters from "@/components/ContractStatusFilters";
import { BulkEditMenu } from "./BulkEditMenu";

import { Row } from "../../contractListItem";
import { GridToolbarTotalItemsContainer } from "./GridToolbarTotalItemsContainer";

export interface ContractsTableToolbarProps {
    density: GridDensity;
    onDensityChange: (density: GridDensity) => void;

    searchTerm: string | undefined;
    onSearch: (value: string) => void;
    setOpenLayoutPopup: () => void;
    hasRenewalDate: boolean;
    selectedRows: Row[];
    selectedRowIds: string[];
    setSelectedRowIds: (rowIds: string[]) => void;
    onOpenColumnsPreferences: () => void;
    totalNumberOfRows: number;
    visibleRowCount: number;
}

export const ContractsTableToolbar: React.FC<ContractsTableToolbarProps> = ({
    density,
    onDensityChange,
    searchTerm,
    onSearch,
    setOpenLayoutPopup,
    hasRenewalDate,
    selectedRows,
    selectedRowIds,
    setSelectedRowIds,
    onOpenColumnsPreferences,
    totalNumberOfRows,
    visibleRowCount,
}) => {
    const showBulkActions = useFeatureToggle("contracts-bulk-actions");

    // The toolbar is memoized to prevent unnecessary re-renders of this complex component tree.
    // Since the toolbar contains many child components and is rendered for every row in the data grid,
    // memoizing it improves performance by only re-rendering when its dependencies change.
    // The dependencies include all the props that could trigger a re-render like density, searchTerm,
    // selectedRowIds etc.
    // Ref: https://mui.com/x/react-data-grid/faq/#how-to-pass-props-to-the-data-grid-for-better-performance
    const toolbar = useMemo(() => {
        return (
            <GridToolbarContainer>
                <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center" gap={1}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Stack direction="row" spacing={2} alignItems="center" pr={1}>
                            <GridToolbarTotalItemsContainer
                                visibleRowCount={visibleRowCount}
                                rowCount={totalNumberOfRows}
                                selectedSuppliersCount={selectedRowIds.length}
                            />
                            <Divider orientation="vertical" flexItem />
                        </Stack>

                        <SearchField initialValue={searchTerm} onSearch={onSearch} />
                        <Stack>
                            {/* wrap in a Stack, otherwise the filter will be too wide */}
                            {/* hasEditPermission is used to check if user can pin filters. We store pinned filters in localStorage */}
                            <FilterModal hideFilterOptions />
                        </Stack>
                    </Stack>
                    <Stack direction="row" ml="auto" spacing={1}>
                        {showBulkActions && (
                            <Stack direction="row" spacing={2} paddingRight={1}>
                                <BulkEditMenu
                                    selectedRows={selectedRows}
                                    selectedRowIds={selectedRowIds}
                                    setSelectedRowIds={setSelectedRowIds}
                                />
                                <Divider orientation="vertical" flexItem />
                            </Stack>
                        )}
                        <ContractStatusFilters hasRenewalDate={hasRenewalDate} />
                        <TableActionBar
                            density={density}
                            onDensityChange={onDensityChange}
                            onOpenColumnsPreferences={onOpenColumnsPreferences}
                            setOpenLayoutPopup={setOpenLayoutPopup}
                        />
                    </Stack>
                </Stack>
            </GridToolbarContainer>
        );
    }, [
        totalNumberOfRows,
        visibleRowCount,
        selectedRowIds,
        searchTerm,
        onSearch,
        showBulkActions,
        selectedRows,
        setSelectedRowIds,
        hasRenewalDate,
        density,
        onDensityChange,
        onOpenColumnsPreferences,
        setOpenLayoutPopup,
    ]);

    return toolbar;
};
