import {
    Box,
    Checkbox,
    CircularProgress,
    Divider,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    TextField,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Search } from "@ignite-analytics/icons";
import debounce from "lodash/debounce";
import { useIntl } from "react-intl";
import { ClearFilterButton } from "./ClearFilterButton";
import { AsyncMultiSelectFilter } from "./types";

interface AsyncMultiSelectProps {
    filter: AsyncMultiSelectFilter;
    onChange: (value: AsyncMultiSelectFilter) => void;
}

export const AsyncMultiSelect: React.FC<AsyncMultiSelectProps> = ({ filter, onChange }) => {
    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);
    const { formatMessage } = useIntl();

    const debouncedFetchOptions = useCallback(
        (search: string) => {
            const fetchData = async () => {
                setLoading(true);
                const newOptions = await filter.fetchOptions(search);
                onChange({ ...filter, options: newOptions });
                setLoading(false);
            };
            fetchData();
        },
        [filter, onChange]
    );

    const debouncedSearch = useMemo(() => debounce(debouncedFetchOptions, 300), [debouncedFetchOptions]);

    useEffect(() => {
        debouncedSearch(inputValue);
        return () => {
            debouncedSearch.cancel();
        };
    }, [inputValue, debouncedSearch]);

    const unselectedOptions = filter.options.filter((option) => !filter.value.some((v) => v.id === option.id));

    const handleClearFilter = () => {
        onChange({ ...filter, value: [] });
    };

    return (
        <Box sx={{ width: "100%", maxHeight: 400 }}>
            <Box sx={{ position: "sticky", top: 0, bgcolor: "background.paper", zIndex: 1, pb: 1, px: 2 }}>
                <TextField
                    fullWidth
                    size="small"
                    placeholder={formatMessage({ defaultMessage: "Search" })}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    {loading && <CircularProgress size={20} />}
                                </InputAdornment>
                            ),
                        },
                    }}
                />
            </Box>

            <Divider />

            <List sx={{ width: "100%", maxHeight: 250, overflow: "auto", px: 2 }}>
                {filter.includeBlanks !== undefined && (
                    <ListItem dense disablePadding>
                        <ListItemButton onClick={() => onChange({ ...filter, includeBlanks: !filter.includeBlanks })}>
                            <ListItemIcon sx={{ minWidth: "12px" }}>
                                <Checkbox checked={filter.includeBlanks} edge="start" />
                            </ListItemIcon>
                            <ListItemText primary={filter.includeBlanksLabel} />
                        </ListItemButton>
                    </ListItem>
                )}

                {/* Selected items */}
                {filter.value.map((option) => (
                    <ListItem key={option.id} dense disablePadding>
                        <ListItemButton
                            onClick={() => {
                                const newValue = filter.value.filter((v) => v.id !== option.id);
                                onChange({ ...filter, value: newValue });
                            }}
                        >
                            <ListItemIcon sx={{ minWidth: "12px" }}>
                                <Checkbox checked edge="start" />
                            </ListItemIcon>
                            <ListItemText primary={option.label} />
                        </ListItemButton>
                    </ListItem>
                ))}

                {filter.value.length > 0 && (
                    <Stack>
                        <ClearFilterButton onClear={handleClearFilter} />
                        {filter.options.length > filter.value.length && <Divider sx={{ my: 1 }} />}
                    </Stack>
                )}

                {/* Unselected items */}
                {unselectedOptions.map((option) => (
                    <ListItem key={option.id} dense disablePadding>
                        <ListItemButton
                            onClick={() => {
                                const newValue = [...filter.value, option];
                                onChange({ ...filter, value: newValue });
                            }}
                        >
                            <ListItemIcon sx={{ minWidth: "12px" }}>
                                <Checkbox checked={false} edge="start" />
                            </ListItemIcon>
                            <ListItemText primary={option.label} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};
