import { FormattedMessage } from "react-intl";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { RenderActivity } from "./RenderActivity";

const DocumentAddedActivity_DocumentAddedContractActivity = graphql(`
    fragment DocumentAddedActivity_DocumentAddedContractActivity on DocumentAddedContractActivity {
        filename
    }
`);

export interface DocumentAddedActivityProps {
    activity: FragmentType<typeof DocumentAddedActivity_DocumentAddedContractActivity>;
}

export const DocumentAddedActivity: React.FC<DocumentAddedActivityProps> = (props) => {
    const activity = getFragmentData(DocumentAddedActivity_DocumentAddedContractActivity, props.activity);
    return (
        <RenderActivity
            action="uploaded"
            title={<FormattedMessage defaultMessage="Document" />}
            value={activity.filename}
        />
    );
};
