import { FormattedMessage } from "react-intl";
import { RenderMonetaryAmountCell } from "@/components/ContractsTablePro/components/RenderCustomField";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { RenderActivity } from "./RenderActivity";

const TotalSpendActivity_TotalSpendContractActivity = graphql(`
    fragment TotalSpendActivity_TotalSpendContractActivity on TotalSpendContractActivity {
        totalSpend
        prevTotalSpend
    }
`);

interface TotalSpendActivityProps {
    activity: FragmentType<typeof TotalSpendActivity_TotalSpendContractActivity>;
}

export const TotalSpendActivity: React.FC<TotalSpendActivityProps> = (props) => {
    const activity = getFragmentData(TotalSpendActivity_TotalSpendContractActivity, props.activity);
    return (
        <RenderActivity
            title={<FormattedMessage defaultMessage="Total Spend" />}
            prev={<RenderMonetaryAmountCell amount={activity.prevTotalSpend} />}
            value={<RenderMonetaryAmountCell amount={activity.totalSpend} />}
        />
    );
};
