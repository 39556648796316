import { TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { Blank } from "../Blank";
import { UpdateContractFormParams } from "@/types";

const Number_ContractCustomFieldNumberFragment = graphql(`
    fragment Number_ContractCustomFieldNumberFragment on ContractCustomFieldNumber {
        id
        name
        number
    }
`);

interface NumberProps {
    data: FragmentType<typeof Number_ContractCustomFieldNumberFragment>;
    edit?: boolean;
}

export const Number: React.FC<NumberProps> = (props) => {
    const data = getFragmentData(Number_ContractCustomFieldNumberFragment, props.data);
    const { control } = useFormContext<UpdateContractFormParams>();

    if (props.edit) {
        return (
            <Controller
                name={`customfields/${data.id}`}
                control={control}
                defaultValue={{ id: data.id, number: data.number }}
                render={({ field }) => (
                    <TextField
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={field.value.number}
                        onChange={(ev) => field.onChange({ id: data.id, number: parseInt(ev.target.value, 10) })}
                    />
                )}
            />
        );
    }

    if (!data.number) {
        return <Blank />;
    }
    return (
        <Typography variant="textMd">{data.number.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Typography>
    );
};
