import { Filter } from "@ignite-analytics/filters";
import { z } from "zod";

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
type Literal = z.infer<typeof literalSchema>;
type Json = Literal | { [key: string]: Json } | Json[];
const jsonSchema: z.ZodType<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]));

export const contractListValidateSearchSchema = z.object({
    status: z
        .union([
            z.literal("active"),
            z.literal("renewing-soon"),
            z.literal("expiring-soon"),
            z.literal("all"),
            z.literal("upcoming"),
            z.literal("expired"),
        ])
        .catch("all"),
    newContract: z
        .object({
            open: z.boolean(),
            supplier: z
                .object({
                    id: z.string(),
                    label: z.string(),
                })
                .optional(),
        })
        .optional(),
    dmsFilter: jsonSchema.optional().transform((e) => {
        if (!e) return undefined;
        return e as any as Filter[];
    }),
    page: z.number().int().positive().catch(1),
    perPage: z.number().int().positive().catch(25),
    searchTerm: z
        .preprocess((val) => String(val), z.string())
        .optional()
        .transform((val) => (val === "" ? undefined : val))
        .catch(""),
    sortBy: z.preprocess((val) => String(val), z.string()).optional(),
    sortOrder: z
        .union([z.literal("asc"), z.literal("desc"), z.literal(null)])
        .optional()
        .catch(undefined),
    group: z.union([z.literal("currentUser"), z.literal("all")]).catch("all"),
});

// we'll create a separate type for the input to validateSearch. Everything is optional, and we'll
// fall back to a default value if not provided.
export type ContractListSearchInput = Partial<z.infer<typeof contractListValidateSearchSchema>>;
