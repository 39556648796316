import {
    FilterContextProvider,
    useCompanyFavouriteFilters,
    usePersonalFavouriteFilters,
    useUpsertPersonalFavouriteFilters,
} from "@ignite-analytics/filters";
import { COMPANY_GT, CONTRACT_RELATION_GT, CONTRACT_TABLE_GT, TRANSACTIONS_GT } from "@ignite-analytics/global-types";

import { ElasticField, useElasticFields } from "@ignite-analytics/elastic-fields";
import { useEffect, useMemo } from "react";
import { useBreadcrumbs } from "src/hooks/useBreadcrumbs";
import { useV2DataTableWithType } from "src/hooks/useTableWithType";

import { useUserOrThrow } from "@/contexts/useUser";
import { env } from "@/env";
import ContractList from ".";

const fieldsToExclude = ["importId", "_id", "batchId", "lastModified"];

const isCompanyEnrichmentMetaField = (field: ElasticField) =>
    field.globalTypeKey === COMPANY_GT && field.globalTypeSubKey !== "ID";

const useElasticFieldsForFilterContextProvider = (indexNames: string[] | undefined): ElasticField[] | undefined => {
    const allElasticFields = useElasticFields(indexNames, true);
    return useMemo(() => {
        if (!allElasticFields) {
            return undefined;
        }

        return allElasticFields.filter(
            (field) => !fieldsToExclude.includes(field.field) && !isCompanyEnrichmentMetaField(field)
        );
    }, [allElasticFields]);
};

export const useContractRelationField = () => {
    const { table } = useV2DataTableWithType(TRANSACTIONS_GT);
    const fields = useElasticFields(table?.elasticIndex);
    const contractRelationField = useMemo(() => {
        return fields?.find((field) => field.globalTypeKey === CONTRACT_RELATION_GT);
    }, [fields]);
    return contractRelationField;
};

const FilterWrappedContractList = () => {
    const { userId } = useUserOrThrow();
    const { table: contractsTable } = useV2DataTableWithType(CONTRACT_TABLE_GT);
    const { updateBreadCrumbs } = useBreadcrumbs();
    const contractTableAsDataSource = useMemo(
        () =>
            contractsTable
                ? {
                      name: contractsTable.elasticIndex,
                      label: contractsTable.name,
                      globalTypeKey: contractsTable?.globalTypeKey ?? undefined,
                  }
                : undefined,
        [contractsTable]
    );

    const usePersonalFavouriteFiltersForContext = () =>
        usePersonalFavouriteFilters(userId, env.REACT_APP_GRAPHQL_ROUTER_URL);
    const useCompanyFavouriteFiltersForContext = () => useCompanyFavouriteFilters(env.REACT_APP_GRAPHQL_ROUTER_URL);

    const upsertPersonalFavouriteFilters = useUpsertPersonalFavouriteFilters(userId, env.REACT_APP_GRAPHQL_ROUTER_URL);

    useEffect(() => {
        updateBreadCrumbs({ page: "list" });
    }, [updateBreadCrumbs]);

    return (
        <FilterContextProvider
            dataSource={contractTableAsDataSource}
            useDataFields={useElasticFieldsForFilterContextProvider}
            inherit={false}
            initialFilters={[]}
            // hooks for favourite and company favourite filters
            usePersonalFavouriteFilters={usePersonalFavouriteFiltersForContext}
            upsertPersonalFavouriteFilters={upsertPersonalFavouriteFilters}
            useCompanyFavouriteFilters={useCompanyFavouriteFiltersForContext}
        >
            <ContractList />
        </FilterContextProvider>
    );
};

export default FilterWrappedContractList;
