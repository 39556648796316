import { Filter } from "@ignite-analytics/filters";
import { GridColumnVisibilityModel, GridDensity } from "@mui/x-data-grid-pro";
import {
    COLUMN_MODEL_VISIBILITY,
    CONTRACTS_COLUMN_WIDTHS,
    CONTRACTS_COLUMNS_ORDER,
    CONTRACTS_FILTERS,
    CONTRACTS_PINNED_FILTERS_FIELDS,
    CONTRACTS_TABLE_DENSITY,
} from "src/localStorageKeys";

const read = <T>(tenant: string, key: string) => {
    const storedValue = localStorage.getItem(`${tenant}_${key}`);
    return storedValue ? (JSON.parse(storedValue) as T) : null;
};
const save = (tenant: string, key: string, data: any) => localStorage.setItem(`${tenant}_${key}`, JSON.stringify(data));
const saveToSession = (tenant: string, key: string, data: any) =>
    sessionStorage.setItem(`${tenant}_${key}`, JSON.stringify(data));

export const readPinnedFiltersFieldsIds = (tenant: string) =>
    read<string[]>(tenant, CONTRACTS_PINNED_FILTERS_FIELDS) ?? [];

export const saveFilters = (tenant: string, filters: Filter[]) => save(tenant, CONTRACTS_FILTERS, filters);
export const saveFiltersToSession = (tenant: string, filters: Filter[]) =>
    saveToSession(tenant, CONTRACTS_FILTERS, filters);

export const readColumnWidths = (tenant: string) =>
    read<{ id: string; width: number }[]>(tenant, CONTRACTS_COLUMN_WIDTHS) ?? [];
export const saveColumnWidths = (tenant: string, columnWidths: { id: string; width: number }[]) =>
    save(tenant, CONTRACTS_COLUMN_WIDTHS, columnWidths);

export const readDensity = (tenant: string) => read<GridDensity>(tenant, CONTRACTS_TABLE_DENSITY) ?? "standard";
export const saveDensity = (tenant: string, density: GridDensity) => save(tenant, CONTRACTS_TABLE_DENSITY, density);

export const readColumnVisibility = (tenant: string) =>
    read<GridColumnVisibilityModel>(tenant, COLUMN_MODEL_VISIBILITY) ?? {};

export const saveColumnVisibility = (tenant: string, model: GridColumnVisibilityModel) =>
    save(tenant, COLUMN_MODEL_VISIBILITY, model);

export const saveColumnsOrder = (tenant: string, columnsIds: string[]) =>
    save(tenant, CONTRACTS_COLUMNS_ORDER, columnsIds);

export const readColumnsOrder = (tenant: string) => read<string[]>(tenant, CONTRACTS_COLUMNS_ORDER);
