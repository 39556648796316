import dayjs from "dayjs";

import { Avatar, Stack, Tooltip, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { graphql } from "@/generated/gql";
import { FragmentType, getFragmentData } from "@/generated/fragment-masking";

const UserTimeStamp_ContractActivityMetadata = graphql(`
    fragment UserTimeStamp_ContractActivityMetadata on ContractActivityMetadata {
        seqNumber
        createdAt
        createdBy {
            ... on RoleUser {
                id
                fullName
                initials
            }
            ... on ContractActivityActorSystem {
                id
            }
        }
    }
`);

interface UserTimeStampProps {
    activity: FragmentType<typeof UserTimeStamp_ContractActivityMetadata>;
}

export const UserTimestamp: React.FC<UserTimeStampProps> = (props) => {
    let actorName: React.ReactNode = "";
    let actorInitials = "";

    const activity = getFragmentData(UserTimeStamp_ContractActivityMetadata, props.activity);

    if (activity.createdBy.__typename === "RoleUser") {
        actorName = activity.createdBy.fullName;
        actorInitials = activity.createdBy.initials;
    } else {
        actorName = <FormattedMessage defaultMessage="System" />;
        actorInitials = "S";
    }

    return (
        <Stack direction="row" spacing={1}>
            <Avatar src="" sx={{ width: 24, height: 24 }}>
                <Typography color="textSecondary" variant="textXs">
                    {actorInitials}
                </Typography>
            </Avatar>
            <Stack gap={1}>
                <Typography color="textSecondary" variant="textSm" fontWeight="400" noWrap>
                    {actorName}
                </Typography>
                <Tooltip title={dayjs(activity.createdAt).format("DD MMM YYYY")}>
                    <Typography color="textSecondary" variant="textXs" noWrap>
                        {dayjs(activity.createdAt).fromNow()}
                    </Typography>
                </Tooltip>
            </Stack>
        </Stack>
    );
};
