import { Chip, Stack, Tooltip } from "@mui/material";
import { EllipticTypography } from "../../../EllipticTypography";

interface GroupChipProps {
    groups?: { name: string; id: string }[] | null;
}
export const GroupChip = ({ groups }: GroupChipProps) => {
    if (!groups || groups.length === 0) {
        return null;
    }

    const [biggestGroup, ...otherGroups] = groups;

    return (
        <Stack direction="row" spacing={1} maxWidth="100%">
            <Chip
                size="medium"
                label={<EllipticTypography>{biggestGroup.name}</EllipticTypography>}
                sx={{
                    overflow: "hidden",
                    textOverflow: "hidden",
                    maxWidth: "354px",
                }}
            />
            {otherGroups.length > 0 && (
                <Tooltip
                    title={
                        <Stack spacing={1} p={1}>
                            {otherGroups.map((cat) => (
                                <Chip key={cat.id} size="medium" label={cat.name} />
                            ))}
                        </Stack>
                    }
                >
                    <Chip size="medium" label={`+${otherGroups.length}`} />
                </Tooltip>
            )}
        </Stack>
    );
};
