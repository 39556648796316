import { FormattedMessage } from "react-intl";
import { RenderActivity } from "./RenderActivity";
import { SupplierChips } from "@/components/SuppliersChip";
import { graphql } from "@/generated/gql";
import { FragmentType, getFragmentData } from "@/generated/fragment-masking";

const SupplierActivity_Supplier = graphql(`
    fragment SupplierActivity_Supplier on Supplier {
        id
        name
    }
`);

const SupplierActivity_SupplierContractActivity = graphql(`
    fragment SupplierActivity_SupplierContractActivity on SupplierContractActivity {
        supplier {
            ...SupplierActivity_Supplier
        }
        prevSupplier {
            ...SupplierActivity_Supplier
        }
    }
`);

type SupplierActivityProps = {
    activity: FragmentType<typeof SupplierActivity_SupplierContractActivity>;
};

export const SupplierActivity: React.FC<SupplierActivityProps> = (props) => {
    const activity = getFragmentData(SupplierActivity_SupplierContractActivity, props.activity);
    const supplier = getFragmentData(SupplierActivity_Supplier, activity.supplier);
    const prevSupplier = getFragmentData(SupplierActivity_Supplier, activity.prevSupplier);

    return (
        <RenderActivity
            title={<FormattedMessage defaultMessage="Supplier" />}
            value={supplier ? <SupplierChips suppliers={[supplier]} /> : undefined}
            prev={prevSupplier ? <SupplierChips suppliers={[prevSupplier]} /> : undefined}
        />
    );
};
