import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { NumberRangeFilter } from "./types";
import { useCompanyCurrency } from "@/contexts/CompanyCurrencyContext";
import { ClearFilterButton } from "./ClearFilterButton";

interface NumberRangeFilterProps {
    filter: NumberRangeFilter;
    onChange: (filter: NumberRangeFilter) => void;
}

export const NumberRange: React.FC<NumberRangeFilterProps> = ({ filter, onChange }) => {
    const { code: currency } = useCompanyCurrency();
    const minLabel = (
        <Typography variant="body2">
            {filter.label} <FormattedMessage defaultMessage="Min" />
        </Typography>
    );
    const maxLabel = (
        <Typography variant="body2">
            {filter.label} <FormattedMessage defaultMessage="Max" />
        </Typography>
    );

    const handleClearFilter = () => {
        onChange({ ...filter, value: { min: undefined, max: undefined } });
    };

    return (
        <Stack spacing={2} paddingX={2}>
            <TextField
                type="number"
                label={minLabel}
                value={filter.value?.min ?? ""}
                slotProps={{
                    input: {
                        startAdornment: filter.isCurrency ? (
                            <InputAdornment position="end">{currency}</InputAdornment>
                        ) : null,
                    },
                }}
                onChange={(e) =>
                    onChange({
                        ...filter,
                        value: {
                            ...filter.value,
                            min: e.target.value ? Number(e.target.value) : undefined,
                        },
                    })
                }
            />
            <TextField
                type="number"
                label={maxLabel}
                value={filter.value?.max ?? ""}
                slotProps={{
                    input: {
                        startAdornment: filter.isCurrency ? (
                            <InputAdornment position="end">{currency}</InputAdornment>
                        ) : null,
                    },
                }}
                onChange={(e) =>
                    onChange({
                        ...filter,
                        value: {
                            ...filter.value,
                            max: e.target.value ? Number(e.target.value) : undefined,
                        },
                    })
                }
            />
            <ClearFilterButton onClear={handleClearFilter} disabled={!filter.value} />
        </Stack>
    );
};
