import { Controller, useFormContext } from "react-hook-form";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { normalizeDateString } from "@/helpers/dates";
import { Blank } from "../Blank";
import { UpdateContractFormParams } from "@/types";

const Date_ContractCustomFieldDateFragment = graphql(`
    fragment Date_ContractCustomFieldDateFragment on ContractCustomFieldDate {
        id
        date
        name
    }
`);

interface DateProps {
    data: FragmentType<typeof Date_ContractCustomFieldDateFragment>;
    edit?: boolean;
}

export const Date: React.FC<DateProps> = (props) => {
    const data = getFragmentData(Date_ContractCustomFieldDateFragment, props.data);
    const { control } = useFormContext<UpdateContractFormParams>();

    if (props.edit) {
        return (
            <Controller
                name={`customfields/${data.id}`}
                control={control}
                defaultValue={{ id: data.id, date: data.date }}
                render={({ field }) => (
                    <DesktopDatePicker
                        value={normalizeDateString(field.value.date)}
                        timezone="UTC"
                        onChange={(date: Dayjs | null) => {
                            field.onChange({ id: data.id, date: date?.toISOString() });
                        }}
                    />
                )}
            />
        );
    }
    if (!data.date) {
        return <Blank />;
    }

    return normalizeDateString(data.date)?.format("DD MMM YYYY");
};
