import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import { FormattedMessage } from "react-intl";
import { EllipticTypography } from "@/components/EllipticTypography";
import { GroupChip } from "@/components/TreePicker/components/GroupChip";
import { graphql } from "@/generated";
import { TreeSelect } from "../../../../components/TreePicker/components/TreeSelect";
import { LeafNode } from "../../../../components/TreePicker/types";

const BusinessUnitPicker_GetAllBusinessUnits = graphql(`
    query BusinessUnitPicker_GetAllBusinessUnits {
        getAllBusinessUnits {
            businessUnits {
                id
                level
                name
                parentId
            }
        }
    }
`);

interface Props {
    value: string[];
    onChange: (value: string[]) => void;
}

export const BusinessUnitPicker: React.FC<Props> = ({ value, onChange }) => {
    const { data } = useQuery(BusinessUnitPicker_GetAllBusinessUnits);

    const businessUnits: LeafNode[] = useMemo(() => {
        if (!data) {
            return [];
        }

        return data.getAllBusinessUnits.businessUnits.map((businessUnit) => {
            return {
                id: businessUnit.id,
                level: businessUnit.level,
                label: businessUnit.name,
                parentId: businessUnit.parentId,
                children: [],
            };
        });
    }, [data]);

    const buttonContent = useMemo(() => {
        if (!value.length) {
            return (
                <EllipticTypography variant="textMd" width="100%" textAlign="start">
                    <FormattedMessage defaultMessage="Select categories" />
                </EllipticTypography>
            );
        }

        const selectedCategories = data?.getAllBusinessUnits.businessUnits
            .filter((businessUnit) => value.includes(businessUnit.id))
            .map((businessUnit) => {
                return {
                    id: businessUnit.id,
                    name: businessUnit.name,
                };
            });

        return <GroupChip groups={selectedCategories} />;
    }, [data, value]);

    return (
        <TreeSelect
            multiSelect
            items={businessUnits}
            value={value}
            onChange={onChange}
            buttonContent={buttonContent}
        />
    );
};
