import { useIntl } from "react-intl";
import { ContractDocumentTag } from "@/generated/graphql";

export function useFormatDocumentTag() {
    const { formatMessage } = useIntl();

    const translations: Record<ContractDocumentTag, string> = {
        AMENDMENT: formatMessage({ defaultMessage: "Amendment" }),
        DATA_PROCESSING_AGREEMENT: formatMessage({ defaultMessage: "Data processing agreement" }),
        LETTER_OF_INTENT: formatMessage({ defaultMessage: "Letter of intent" }),
        MAIN_DOCUMENT: formatMessage({ defaultMessage: "Main document" }),
        NON_DISCLOSURE_AGREEMENT: formatMessage({ defaultMessage: "Non-disclosure agreement" }),
        OTHER: formatMessage({ defaultMessage: "Other" }),
        PRICE_INFORMATION: formatMessage({ defaultMessage: "Price information" }),
        SERVICE_LEVEL_AGREEMENT: formatMessage({ defaultMessage: "Service level agreement" }),
    };

    return function (tag: ContractDocumentTag) {
        return translations[tag];
    };
}
