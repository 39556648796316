import { Button, Stack } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { TableFilter, TableQuickFilter } from "./types";

import { ActiveFilter } from "./ActiveFilter";
import { Filter } from "./Filter";

interface FiltersProps {
    filters: TableFilter[];
    appliedFilters: TableFilter[];
    onApplyFilters: (filters: TableFilter[]) => void;
    quickFilters?: TableQuickFilter[];
}

export const Filters: React.FC<FiltersProps> = ({ filters, appliedFilters, onApplyFilters, quickFilters }) => {
    const handleFilterChange = (changedFilters: TableFilter[]) => {
        const newFilterIds = changedFilters.map((f) => f.id);
        const newFilters = appliedFilters.filter((f) => !newFilterIds.includes(f.id));

        changedFilters.forEach((filter) => {
            if (filter.value !== null && filter.value !== undefined) {
                newFilters.push(filter);
            }
        });
        onApplyFilters(newFilters);
    };

    const handleRemoveFilter = (filterId: string) => {
        onApplyFilters(appliedFilters.filter((f) => f.id !== filterId));
    };

    const handleResetAllFilters = () => {
        onApplyFilters([]);
    };

    // Removes the filter from active filters if the value is not set on close
    const handleCloseEmptyFilter = (filter: TableFilter) => {
        if (!filter.value || (Array.isArray(filter.value) && filter.value.length === 0)) {
            onApplyFilters(appliedFilters.filter((f) => f.id !== filter.id));
        }
    };

    return (
        <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap">
            <Filter
                filters={filters}
                quickFilters={quickFilters}
                appliedFilters={appliedFilters}
                onFilterChange={handleFilterChange}
            />
            {appliedFilters.map((af) => {
                const filterDef = filters.find((f) => f.id === af.id);
                if (!filterDef) return null;

                return (
                    <ActiveFilter
                        key={af.id}
                        activeFilter={af}
                        onChange={handleFilterChange}
                        onRemove={() => handleRemoveFilter(af.id)}
                        onCloseEmptyFilter={handleCloseEmptyFilter}
                    />
                );
            })}
            {appliedFilters.length > 0 && (
                <Button variant="outlined" color="ghostGray" onClick={handleResetAllFilters} size="small">
                    <FormattedMessage defaultMessage="Reset" />
                </Button>
            )}
        </Stack>
    );
};
