import { ArrowLeft, FileCheck, Pen, Trash } from "@ignite-analytics/icons";
import { LoadingButton } from "@mui/lab";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useRouter } from "@tanstack/react-router";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useMutation } from "@apollo/client";
import { RiddleMenuWithButton } from "@/components/RiddleMenu";
import { queryClient } from "@/contexts";
import DeletePrompt from "@/pages/detail/components/DeletePrompt";
import { contractListQueryKeyPredicate } from "@/querykeys";
import { usePrevious } from "../hooks/usePrevious";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { captureMessage } from "@/errorHandling/errors";
import { useSnackbar } from "@/contexts/useSnackbar";

export const ContractDetailsHeader_ContractFragment = graphql(`
    fragment ContractDetailsHeader_ContractFragment on Contract {
        id
        title
    }
`);

const ContractDetailsHeader_DeleteContractsMutation = graphql(`
    mutation ContractDetailsHeader_DeleteContractsMutation($ids: [ID!]!) {
        deleteContracts(ids: $ids) {
            deletedIds
        }
    }
`);

interface ContractDetailsHeaderProps {
    contract: FragmentType<typeof ContractDetailsHeader_ContractFragment>;
    editMode: boolean;
    canEdit: boolean;
    displayHeaderShadow: boolean;
    onDiscardChanges: () => void;
    isSaving: boolean;
    onSaveChangesClick: () => void;
    onActivateEditMode: () => void;
}

export const ContractDetailsHeader: React.FC<ContractDetailsHeaderProps> = ({
    editMode,
    canEdit,
    displayHeaderShadow,
    onDiscardChanges,
    onActivateEditMode,
    ...props
}) => {
    const [deleteContractDialogOpen, setDeleteContractDialogOpen] = useState<boolean>(false);
    const { history, invalidate } = useRouter();
    const { previous: previousPathname } = usePrevious(history.location.pathname);
    const { formatMessage } = useIntl();
    const { postSnackbar } = useSnackbar();

    const contract = getFragmentData(ContractDetailsHeader_ContractFragment, props.contract);

    const [deleteContracts] = useMutation(ContractDetailsHeader_DeleteContractsMutation, {
        onCompleted: () => {
            setDeleteContractDialogOpen(false);
            postSnackbar({
                message: formatMessage({ defaultMessage: "Contract deleted" }),
                severity: "success",
            });
            invalidate(); // deleted contract -> invalidate list cache
            queryClient.removeQueries({ predicate: contractListQueryKeyPredicate });
            history.back();
        },
        onError: (error) => {
            captureMessage("Graphql: Failed to delete contract", {
                tags: { contractId: contract.id },
                extra: { error },
            });
            postSnackbar({
                message: formatMessage({ defaultMessage: "Failed to delete contract" }),
                severity: "error",
            });
        },
    });

    const handleBack = () => {
        if (previousPathname?.includes("/data-list-modal/")) {
            // Entrypoint (-3) -> Detail page (-2) -> Data list modal (-1)
            history.go(-3);
        } else {
            history.back();
        }
    };

    return (
        <Stack
            direction="row"
            mx="auto"
            justifyContent="center"
            width="100%"
            position="sticky"
            sx={{
                zIndex: 1,
                background: (theme) => theme.palette.background.default,
                transition: "box-shadow 0.2s",
                top: "var(--appbar-height, 0px)",
            }}
            boxShadow={(theme) => (displayHeaderShadow ? theme.shadows : undefined)}
        >
            <Stack
                justifyContent="space-between"
                direction="row"
                px={4}
                width="100%"
                maxWidth={2500}
                py={1}
                gap={3}
                alignItems="center"
            >
                <Stack direction="row" gap={1.5} alignItems="center">
                    <IconButton sx={{ alignSelf: "start" }} size="small" color="ghostGray" onClick={handleBack}>
                        <ArrowLeft />
                    </IconButton>
                    <Typography fontWeight={700} variant="h6">
                        {contract.title}
                    </Typography>
                </Stack>
                {editMode ? (
                    <Stack direction="row" gap={1.5}>
                        <Button color="secondary" onClick={onDiscardChanges} size="small">
                            <FormattedMessage defaultMessage="Discard changes" />
                        </Button>
                        <LoadingButton
                            loading={props.isSaving}
                            startIcon={<FileCheck />}
                            onClick={() => {
                                props.onSaveChangesClick();
                            }}
                            size="small"
                        >
                            <FormattedMessage defaultMessage="Save changes" />
                        </LoadingButton>
                    </Stack>
                ) : (
                    <RiddleMenuWithButton
                        buttonLabel={formatMessage({ defaultMessage: "Options" })}
                        size="small"
                        entries={[
                            {
                                label: formatMessage({ defaultMessage: "Edit contract" }),
                                icon: { pos: "start", component: <Pen /> },
                                disabled: !canEdit,
                                disabledTooltipText: formatMessage({
                                    defaultMessage:
                                        "Only administrators or contract responsible with editor access can modify this contract.",
                                }),
                                onClick: onActivateEditMode,
                            },
                            {
                                label: formatMessage({ defaultMessage: "Delete contract" }),
                                danger: true,
                                disabled: !canEdit,
                                disabledTooltipText: formatMessage({
                                    defaultMessage:
                                        "Only administrators or contract responsible with editor access can modify this contract.",
                                }),
                                icon: { pos: "start", component: <Trash /> },
                                onClick: () => setDeleteContractDialogOpen(true),
                            },
                        ]}
                    />
                )}
                <DeletePrompt
                    open={deleteContractDialogOpen}
                    onDelete={async () => {
                        deleteContracts({ variables: { ids: [contract.id] } });
                    }}
                    onCancel={() => setDeleteContractDialogOpen(false)}
                />
            </Stack>
        </Stack>
    );
};
