import { Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useCompanyCurrency } from "@/contexts/CompanyCurrencyContext";
import { TableFilter } from "./types";

export const useGetAppliedValueLabel = () => {
    const { formatDate: formatDateIntl } = useIntl();
    const { code: currency } = useCompanyCurrency();

    const getAppliedFilterValueLabel = (filter: TableFilter) => {
        const formatDate = (date: string) => {
            return formatDateIntl(date, {
                day: "numeric",
                month: "short",
                year: "numeric",
            });
        };
        switch (filter.type) {
            case "asyncMultiSelect":
            case "presetMultiSelect": {
                const labels = filter.value.map((v) => v.label);
                if (filter.includeBlanks) {
                    labels.push(filter.includeBlanksLabel);
                }
                const numberOfOptions = labels.length;
                const firstOptionLabel = labels[0];
                const surplus = numberOfOptions - 1;
                const surplusLabel = surplus > 0 ? `+${surplus}` : "";
                return (
                    <Typography variant="textSm" color="textTextSecondary" noWrap>
                        {filter.label}: {firstOptionLabel} {surplusLabel}
                    </Typography>
                );
            }
            case "dateRange": {
                const value = filter.value;
                if (!value.from && !value.to) return filter.label;
                if (!value.from && value.to)
                    return (
                        <FormattedMessage
                            defaultMessage="{label} before {date}"
                            values={{ label: filter.label, date: formatDate(value.to) }}
                        />
                    );
                if (!value.to && value.from)
                    return (
                        <FormattedMessage
                            defaultMessage="{label} after {date}"
                            values={{ label: filter.label, date: formatDate(value.from) }}
                        />
                    );
                if (value.to && value.from)
                    return (
                        <FormattedMessage
                            defaultMessage="{label} between {dateFrom} and {dateTo}"
                            values={{
                                label: filter.label,
                                dateFrom: formatDate(value.from),
                                dateTo: formatDate(value.to),
                            }}
                        />
                    );
                return filter.label;
            }
            case "numberRange": {
                const value = filter.value;
                if (!value.min && !value.max) return filter.label;
                if (!value.min && value.max)
                    return (
                        <FormattedMessage
                            defaultMessage="{label} less than {max} {currency}"
                            values={{
                                label: filter.label,
                                max: value.max,
                                currency: filter.isCurrency ? currency : "",
                            }}
                        />
                    );
                if (value.min && !value.max)
                    return (
                        <FormattedMessage
                            defaultMessage="{label} greater than {min} {currency}"
                            values={{
                                label: filter.label,
                                min: value.min,
                                currency: filter.isCurrency ? currency : "",
                            }}
                        />
                    );
                if (value.min && value.max) {
                    return (
                        <FormattedMessage
                            defaultMessage="{label} between {min} and {max} {currency}"
                            values={{
                                label: filter.label,
                                min: value.min,
                                max: value.max,
                                currency: filter.isCurrency ? currency : "",
                            }}
                        />
                    );
                }
                return filter.label;
            }
            default:
                return <></>;
        }
    };

    return { getAppliedFilterValueLabel };
};
