import { useQuery } from "@apollo/client";
import { captureMessage } from "@/errorHandling/errors";
import { graphql } from "@/generated";

const getTagValuesQuery = graphql(`
    query getTagValuesByColumnId($input: GetTagValuesByColumnIdInput!) {
        getTagValuesByColumnId(input: $input) {
            tagValues {
                value
            }
        }
    }
`);

export function useSearchTagsV2(dataColumnId: string) {
    const { data, loading, error } = useQuery(getTagValuesQuery, {
        variables: {
            input: {
                dataColumnId,
            },
        },
        onError: (error) => {
            captureMessage("Failed to search tags", { extra: { error } });
        },
    });

    return {
        loading,
        options: data?.getTagValuesByColumnId.tagValues.map((tag) => tag.value) ?? [],
        error,
    };
}
