import { useRouter } from "@tanstack/react-router";
import { useCallback } from "react";
import { queryClient } from "@/contexts";
import { useUserOrThrow } from "@/contexts/useUser";
import { contractDetailQueryKey, contractListQueryKeyPredicate } from "@/querykeys";
import { AccessLevels } from "../types";
import { ManageAccessDialog } from "./ManageAccessDialog";
import { FragmentType, getFragmentData, graphql } from "@/generated";

const ManageSingleContractAccess_ContractFragment = graphql(`
    fragment ManageSingleContractAccess_ContractFragment on Contract {
        id
        isPrivate
    }
`);

interface ManageSingleContractAccessProps {
    contract: FragmentType<typeof ManageSingleContractAccess_ContractFragment>;
    isOpen: boolean;
    onClose: () => void;
    refetchContracts: () => void;
}

export const ManageSingleContractAccess = (props: ManageSingleContractAccessProps) => {
    const contract = getFragmentData(ManageSingleContractAccess_ContractFragment, props.contract);
    const permissionLevel = contract.isPrivate ? AccessLevels.RESTRICTED : AccessLevels.PUBLIC;
    const router = useRouter();
    const { tenant } = useUserOrThrow();

    const handleRefetchContractsList = useCallback(() => {
        router.invalidate(); // Invalidate list query
        queryClient.removeQueries({ predicate: contractListQueryKeyPredicate });

        const queryKey = contractDetailQueryKey(tenant, contract.id);
        queryClient.removeQueries({
            predicate: (query) =>
                query.queryKey[0] === queryKey[0] &&
                query.queryKey[1] === queryKey[1] &&
                query.queryKey[2] === queryKey[2],
        });
        props.refetchContracts();
    }, [props, contract, tenant, router]);

    return (
        <ManageAccessDialog
            isOpen={props.isOpen}
            onClose={props.onClose}
            refetchContracts={handleRefetchContractsList}
            contractIds={[contract.id]}
            defaultAccess={permissionLevel}
        />
    );
};
