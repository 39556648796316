import { Stack, TableCell, TableRow } from "@mui/material";

import { useEffect, useRef, useState } from "react";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { UserTimestamp } from "../components/UserTimeStamp";
import { Activity } from "./Activity";

const Activities_ContractActivityFragment = graphql(`
    fragment Activities_ContractActivity on ContractActivity {
        __typename
        ... on BaseContractActivity {
            meta {
                seqNumber
                createdAt
                ...UserTimeStamp_ContractActivityMetadata
            }
        }
        ...Activity_ContractActivity
    }
`);

interface ActivitiesProps {
    activities: FragmentType<typeof Activities_ContractActivityFragment>[];
}

export const Activities: React.FC<ActivitiesProps> = (props) => {
    const [showBorder, setShowBorder] = useState(false);

    const activities = getFragmentData(Activities_ContractActivityFragment, props.activities);

    const ref = useRef<HTMLDivElement>(null);
    const lineHeight = ref?.current?.clientHeight ?? 0;

    useEffect(() => {
        // if any of the activities in the list has seqNumber = 0,
        // we dont show the border as this is the first activity
        const hasFirstActivity = activities.some((activity) => {
            return activity.meta.seqNumber === 0;
        });

        setShowBorder(!hasFirstActivity);
    }, [activities]);

    // Get the first activity in the list
    const firstActivityInGroup = getFragmentData(Activities_ContractActivityFragment, props.activities?.[0]);

    return (
        <TableRow style={{ width: "100%", border: "none" }}>
            <TableCell style={{ display: "flex", justifyContent: "start" }}>
                <UserTimestamp activity={firstActivityInGroup.meta} />
                <div
                    style={{
                        borderLeft: "1px solid #e0e0e0",
                        height: `${lineHeight}px`,
                        position: "absolute",
                        transform: "translate(12px,24px)",
                        zIndex: 0,
                        display: showBorder ? "block" : "none",
                    }}
                />
            </TableCell>
            <TableCell width="100%" ref={ref} sx={{ verticalAlign: "top" }}>
                <Stack gap={1.5}>
                    {activities.map((activity) => (
                        <Activity key={activity.meta.seqNumber} activity={activity} />
                    ))}
                </Stack>
            </TableCell>
        </TableRow>
    );
};
