import DocViewer, { MSDocRenderer } from "@cyntler/react-doc-viewer";

interface MicrosoftDocumentViewerProps {
    url: string;
}

export const MicrosoftDocumentViewer: React.FC<MicrosoftDocumentViewerProps> = ({ url }) => {
    return (
        <DocViewer
            documents={[{ uri: url }]}
            pluginRenderers={[MSDocRenderer]}
            config={{ header: { disableHeader: true, disableFileName: true } }}
        />
    );
};
