import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";

import { ChevronDown, ChevronUp, X } from "@ignite-analytics/icons";
import { FormattedMessage } from "react-intl";

import { LeafNode } from "../types";
import { TreeSelectorPopper } from "./TreeSelectorPopper";

interface TreeSelectProps {
    items: LeafNode[];
    value: string[];
    buttonContent: ReactNode;
    onChange: (categories: string[]) => void;
    multiSelect: boolean;
}

export const TreeSelect: React.FC<TreeSelectProps> = ({ items, value, buttonContent, onChange, multiSelect }) => {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<string[]>([]);
    const anchorRef = useRef<HTMLDivElement>(null);
    // This ensures that the initial state is only set once
    const initialStateSet = useRef(false);

    const handleSetSelected = useCallback(
        (newSelected: string[], skipOnChange?: boolean) => {
            setSelected(newSelected);
            let newValues: string[] = [];

            // If a parent is selected, all children is implicitly selected
            // so we filter out all children where the parent is also selected
            newSelected.forEach((id) => {
                const item = items.find((item) => item.id === id);
                if (!item?.parentId || (item?.parentId && !newSelected.includes(item.parentId))) {
                    newValues = [...newValues, id];
                }
            });
            if (skipOnChange) return;
            onChange(newValues);
        },
        [items, onChange]
    );

    useEffect(() => {
        if (!initialStateSet.current && value.length) {
            handleSetSelected(value, true);
            initialStateSet.current = true;
        }
    }, [value, handleSetSelected]);

    return (
        <Stack direction="row" width="100%">
            <Stack ref={anchorRef} width="100%">
                <Button onClick={() => setOpen(true)} color="secondary" fullWidth size="small">
                    <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
                        <Stack width="100%" overflow="hidden">
                            {buttonContent}
                        </Stack>
                        <Stack direction="row" alignItems="center">
                            {!!value?.length && (
                                <Tooltip
                                    title={
                                        <Typography variant="textMd">
                                            <FormattedMessage defaultMessage="Reset selection" />
                                        </Typography>
                                    }
                                >
                                    <IconButton onClick={() => handleSetSelected([])} size="small">
                                        <X />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {open ? <ChevronUp /> : <ChevronDown />}
                        </Stack>
                    </Stack>
                </Button>
            </Stack>

            <TreeSelectorPopper
                multiSelect={multiSelect}
                open={open}
                anchorEl={anchorRef.current}
                items={items}
                value={selected}
                onChange={handleSetSelected}
                onClose={() => setOpen(false)}
            />
        </Stack>
    );
};
