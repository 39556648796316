import { ChevronDown, Filter as FilterIcon } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Button,
    buttonClasses,
    Chip,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Popover,
    Stack,
    Typography,
} from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { TableFilter, TableQuickFilter } from "./types";

interface FilterProps {
    filters: TableFilter[];
    appliedFilters: TableFilter[];
    onFilterChange: (changedFilters: TableFilter[]) => void;
    quickFilters?: TableQuickFilter[];
}

export const Filter: React.FC<FilterProps> = ({ filters, appliedFilters, onFilterChange, quickFilters }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFilterSelect = (filter: TableFilter) => {
        track("Contracts > Contract monitoring > Apply filter", { filter: filter.id, activeFilter: true });
        onFilterChange([{ ...filter, openFilterOnAdd: true }]);
        handleClose();
    };

    const handleOpenFilterMenu = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget as HTMLButtonElement);
    };

    const unappliedFilters = filters.filter((filter) => !appliedFilters.some((af) => af.id === filter.id));

    const hasUnappliedFilters = unappliedFilters.length > 0;

    const handleApplyQuickFilter = useCallback(
        (quickFilter: TableQuickFilter) => {
            track("Contracts > Contract monitoring > Apply filter", { filter: quickFilter.label, quickFilter: true });
            onFilterChange(quickFilter.apply());
            handleClose();
        },
        [onFilterChange]
    );

    return (
        <>
            <Button
                ref={buttonRef}
                onClick={handleOpenFilterMenu}
                startIcon={<FilterIcon />}
                endIcon={<ChevronDown />}
                sx={{
                    [`& .${buttonClasses.endIcon}`]: {
                        transform: anchorEl ? "rotate(-180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s",
                    },
                }}
                color="secondary"
                size="small"
            >
                <FormattedMessage defaultMessage="Filter by" />
            </Button>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: -4,
                    horizontal: "left",
                }}
                slotProps={{
                    paper: {
                        sx: { outline: 0 },
                    },
                }}
            >
                <List sx={{ minWidth: 200 }}>
                    {quickFilters && quickFilters.length > 0 && (
                        <>
                            <Stack direction="row" px={2} py={1} spacing={1} alignItems="center">
                                <Typography variant="textSm" fontWeight={500} sx={{ color: "text.secondary" }}>
                                    <FormattedMessage defaultMessage="Quick filters" />
                                </Typography>
                            </Stack>
                            {quickFilters.map((quickFilter, index) => (
                                <ListItem key={quickFilter.label} disablePadding>
                                    <ListItemButton
                                        onClick={() => handleApplyQuickFilter(quickFilter)}
                                        autoFocus={index === 0}
                                    >
                                        <Chip
                                            label={<ListItemText primary={quickFilter.label} />}
                                            variant="default"
                                            color="neutral"
                                            size="small"
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                            <Divider />
                        </>
                    )}
                    {hasUnappliedFilters &&
                        unappliedFilters.map((filter, index) => (
                            <ListItem key={filter.id} disablePadding>
                                <ListItemButton
                                    onClick={() => handleFilterSelect(filter)}
                                    autoFocus={!quickFilters && index === 0}
                                >
                                    <ListItemText primary={filter.label} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    {!hasUnappliedFilters && (
                        <ListItem>
                            <Typography variant="textSm" color="textTextHelper">
                                <FormattedMessage defaultMessage="No filters available" />
                            </Typography>
                        </ListItem>
                    )}
                </List>
            </Popover>
        </>
    );
};
