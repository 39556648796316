import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { track } from "@ignite-analytics/track";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useCallback, useMemo, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import FatalErrorFallback from "@/components/FatalErrorFallback";
import { FullWidthSpinner } from "@/components/FullWidthSpinner";
import { usePermissionHandler } from "@/contexts/PermissionsContext";
import { useSnackbar } from "@/contexts/useSnackbar";
import { useUserOrThrow } from "@/contexts/useUser";
import { getFragmentData, graphql } from "@/generated";
import { UpdateContractRequest } from "@/generated/graphql";
import { useBreadcrumbs } from "@/hooks/useBreadcrumbs";
import { detailRoute } from "@/routes/detail/$id";
import { CustomFieldKey, UpdateContractFormParams } from "@/types";
import { ActivityLog } from "./activityLog";
import { ContractDetailsHeader } from "./components/ContractDetailsHeader";
import { NotFound } from "./components/NotFound";
import { PermissionDenied } from "./components/PermissionDenied";
import { ContractDetailPage } from "./ContractDetailPage";
import { useDisplayHeaderShadow } from "./hooks/useHeaderShadow";

export const DetailPage_GetContractQuery = graphql(`
    query DetailPage_GetContractQuery($id: ID!) {
        getContract(id: $id) {
            ...DetailPage_ContractFragment
        }
    }
`);

const DetailPage_ContractFragment = graphql(`
    fragment DetailPage_ContractFragment on Contract {
        id
        title
        responsibles {
            id
            firstName
            lastName
            fullName
            email
        }
        customFields {
            ... on BaseContractCustomField {
                id
                name
            }
        }
        ...ContractDetailsHeader_ContractFragment
        ...ContractDetailPage_ContractFragment
    }
`);

const DetailPage_UpdateContract = graphql(`
    mutation ContractDetailsHeader_UpdateContract($input: UpdateContractRequest!) {
        updateContract(input: $input) {
            ...DetailPage_ContractFragment
        }
    }
`);

export const DetailPage = () => {
    const [editMode, setEditMode] = useState(false);
    const [tabValue, setTabValue] = useState("contract-details");

    const contractId = detailRoute.useParams().id;
    const { updateBreadCrumbs } = useBreadcrumbs();
    const { postSnackbar } = useSnackbar();
    const activityLogFeatureToggle = useFeatureToggle("contracts-activity-log");
    const form = useForm<UpdateContractFormParams>();

    const { data, refetch, loading, error } = useQuery(DetailPage_GetContractQuery, {
        variables: { id: contractId },
        errorPolicy: "all",
        onCompleted: (data) => {
            const title = getFragmentData(DetailPage_ContractFragment, data.getContract).title;
            updateBreadCrumbs({ page: "detail", title });
        },
    });
    const contract = getFragmentData(DetailPage_ContractFragment, data?.getContract);

    const [updateContract, { loading: isSaving }] = useMutation(DetailPage_UpdateContract, {
        onCompleted: () => {
            setEditMode(false);
            postSnackbar({ message: <FormattedMessage defaultMessage="Contract updated" />, severity: "success" });
            form.reset(undefined, { keepValues: true, keepDirty: false });
        },
        onError: (error) => {
            Sentry.captureMessage("Failed to update contract", { extra: { error } });
            postSnackbar({
                message: <FormattedMessage defaultMessage="Failed to update contract." />,
                severity: "error",
            });
        },
    });

    const dirty = form.formState.dirtyFields;

    const handleSaveChanges = useCallback(() => {
        const changes = form.getValues();

        const input: UpdateContractRequest = {
            id: contractId,
            title: dirty.title ? { title: changes.title } : undefined,
            description: dirty.description ? { description: changes.description } : undefined,
            supplier: dirty.supplier ? { supplierId: changes.supplier?.id } : undefined,
            startDate: dirty.startDate ? { startDate: changes.startDate } : undefined,
            endDate: dirty.endDate ? { endDate: changes.endDate } : undefined,
            renewalDate: dirty.renewalDate ? { renewalDate: changes.renewalDate } : undefined,
            responsibles: dirty.responsibles
                ? { responsibles: changes.responsibles.map((user) => user.id) }
                : undefined,
            contactPersons: dirty.contactPersonIds ? { contactPersonIds: changes.contactPersonIds } : undefined,
            customFields: Object.keys(dirty)
                .filter((id) => id.startsWith("customfields/"))
                .map((id) => {
                    const key = id as CustomFieldKey;
                    const update = changes[key];
                    return update;
                }),
            businessUnits: dirty.businessUnits ? { businessUnitIds: changes.businessUnits } : undefined,
            spendCategories: dirty.spendCategories ? { spendCategoryIds: changes.spendCategories } : undefined,
        };
        const customFieldNames = (input.customFields ?? [])
            .map((x) => (contract?.customFields ?? []).find((cf) => cf.id === x.id)?.name)
            .filter((x) => x !== undefined);
        track("Contracts: contract updated", {
            contractId,
            keys: Object.keys(dirty),
            customFieldNames,
        });

        updateContract({ variables: { input } });
    }, [contractId, form, updateContract, dirty, contract?.customFields]);

    const { checkPermissions } = usePermissionHandler();
    const hasGeneralWritePermission = checkPermissions("general", "write");
    const hasAdministratorPermission = checkPermissions("general", "create");
    const { userId } = useUserOrThrow();
    const { displayHeaderShadow, contentRef } = useDisplayHeaderShadow();

    const canEdit = useMemo(() => {
        if (!contract) {
            return false;
        }
        if ((contract.responsibles ?? []).map((user) => user?.id).includes(userId) && hasGeneralWritePermission) {
            return true;
        }
        return hasAdministratorPermission;
    }, [contract, hasGeneralWritePermission, hasAdministratorPermission, userId]);

    const handleDiscardChanges = () => {
        form.reset();
        setEditMode(false);
    };

    const handleActivateEditMode = () => {
        setEditMode(true);
    };

    const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
        track("Change contract detail tab", { tab: newValue });
        setTabValue(newValue);
    };

    if (loading) {
        return <FullWidthSpinner />;
    }
    if (!contract) {
        const hasCode = (code: string) =>
            error?.graphQLErrors?.some((e) => e.extensions?.code === code && e.extensions?.service === "contracts");

        if (hasCode("PERMISSION_DENIED")) {
            return <PermissionDenied />;
        }
        if (hasCode("NOT_FOUND")) {
            return <NotFound />;
        }
        return <FatalErrorFallback reset={() => {}} error={new Error("Test error")} />;
    }

    return (
        <Box mx="auto" maxWidth={1392} paddingBottom={10} width="100%">
            <FormProvider {...form}>
                <ContractDetailsHeader
                    contract={contract}
                    isSaving={isSaving}
                    onSaveChangesClick={handleSaveChanges}
                    editMode={editMode}
                    canEdit={canEdit}
                    onDiscardChanges={handleDiscardChanges}
                    onActivateEditMode={handleActivateEditMode}
                    displayHeaderShadow={displayHeaderShadow}
                />
                {/* This is used to calculate if the user has scrolled down, and then show the header shadow if true */}
                <Box ref={contentRef} position="absolute" top={0} />
                {activityLogFeatureToggle && (
                    <Stack direction="row" width="100%" justifyContent="center" pb={1}>
                        <Stack direction="row" justifyContent="start" px={4} width="100%" maxWidth={2500}>
                            <Tabs value={tabValue} onChange={handleTabChange}>
                                <Tab label="Overview" value="contract-details" />
                                <Tab label="Activity log" value="activity-log" />
                            </Tabs>
                        </Stack>
                    </Stack>
                )}
                {tabValue === "contract-details" && (
                    <ContractDetailPage contract={contract} editMode={editMode} canEdit={canEdit} refetch={refetch} />
                )}
                {tabValue === "activity-log" && <ActivityLog />}
            </FormProvider>
        </Box>
    );
};
