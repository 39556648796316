import { z } from "zod";

export const supplierSchema = z.object({
    id: z.string(),
    name: z.string(),
});

export const responsibleSchema = z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
});

export type Supplier = z.infer<typeof supplierSchema>;
export type Responsible = z.infer<typeof responsibleSchema>;

export type Row = {
    id: string;
    title: string;

    totalSpend: number | null | undefined;
    startDate: string | null | undefined;
    endDate: string | null | undefined;
    supplier: Supplier | undefined;
    responsible: Responsible[];
};
