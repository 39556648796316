import { FormattedMessage } from "react-intl";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { DateActivity } from "./DateActivity";

const EndDateActivity_EndDateContractActivity = graphql(`
    fragment EndDateActivity_EndDateContractActivity on EndDateContractActivity {
        endDate
        prevEndDate
    }
`);

interface EndDateActivityProps {
    activity: FragmentType<typeof EndDateActivity_EndDateContractActivity>;
}

export const EndDateActivity: React.FC<EndDateActivityProps> = (props) => {
    const activity = getFragmentData(EndDateActivity_EndDateContractActivity, props.activity);

    return (
        <DateActivity
            title={<FormattedMessage defaultMessage="End Date" />}
            date={activity.endDate}
            prevDate={activity.prevEndDate}
        />
    );
};
