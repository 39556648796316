import { ArrowTopRightOnSquare, Clone } from "@ignite-analytics/icons";
import { IconButton, Link, Stack, TextField, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { UpdateContractFormParams } from "@/types";
import { FragmentType, getFragmentData, graphql } from "@/generated";
import { useSnackbar } from "@/contexts/useSnackbar";
import { OverflowTooltip } from "@/components/OverflowTooltip";
import { Blank } from "../Blank";

// yoinked from src/components/ContractsTablePro/components/CustomField/TextCustomField.tsx
const TooltipContent: React.FC<{ text: string; isLink: boolean }> = ({ text, isLink }) => {
    const { postSnackbar } = useSnackbar();
    return (
        <Stack direction="row" gap={1} alignItems="center">
            <Typography noWrap variant="textSm" color="textTextBody">
                {text}
            </Typography>
            <IconButton
                aria-label="Copy to clipboard"
                onClick={() => {
                    navigator.clipboard.writeText(text);
                    postSnackbar({
                        message: <FormattedMessage defaultMessage="Copied to clipboard" />,
                        severity: "success",
                    });
                }}
                size="2xsmall"
            >
                <Clone color="inherit" />
            </IconButton>
            {isLink && (
                <IconButton
                    aria-label="Open in new tab"
                    onClick={() => {
                        window.open(text, "_blank");
                    }}
                    size="2xsmall"
                >
                    <ArrowTopRightOnSquare color="inherit" />
                </IconButton>
            )}
        </Stack>
    );
};

const Text_ContractCustomFieldTextFragment = graphql(`
    fragment Text_ContractCustomFieldTextFragment on ContractCustomFieldText {
        id
        name
        type
        text
    }
`);

interface TextCustomFieldProps {
    data: FragmentType<typeof Text_ContractCustomFieldTextFragment>;
    edit?: boolean;
}

export const Text: React.FC<TextCustomFieldProps> = (props) => {
    const data = getFragmentData(Text_ContractCustomFieldTextFragment, props.data);
    const text = data.text;
    const { control } = useFormContext<UpdateContractFormParams>();

    const isLink = useMemo(() => {
        try {
            if (!text) {
                return false;
            }
            // eslint-disable-next-line no-new
            new URL(text);
            return true;
        } catch {
            return false;
        }
    }, [text]);

    if (props.edit) {
        return (
            <Controller
                name={`customfields/${data.id}`}
                control={control}
                defaultValue={{ id: data.id, text: data.text }}
                render={({ field }) => (
                    <TextField
                        fullWidth
                        value={field.value.text}
                        onChange={(e) => {
                            field.onChange({ id: data.id, text: e.target.value });
                        }}
                    />
                )}
            />
        );
    }

    if (text == null) {
        return <Blank />;
    }

    return (
        <Stack maxWidth="240px" pr={2}>
            <OverflowTooltip leaveDelay={500} size="small" title={<TooltipContent isLink={isLink} text={text} />}>
                <Typography noWrap variant="textSm">
                    {isLink ? (
                        <Link href={text} target="_blank" rel="noreferrer">
                            {text}
                        </Link>
                    ) : (
                        text
                    )}
                </Typography>
            </OverflowTooltip>
        </Stack>
    );
};
