import { useMutation, useQuery } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import { useIntl } from "react-intl";
import { v4 } from "uuid";
import { useSnackbar } from "@/contexts/useSnackbar";
import { useErrorHandler } from "@/errorHandling/ErrorHandlerContext";
import { captureMessage } from "@/errorHandling/errors";
import { graphql } from "@/generated";
import type { CreateDocumentUploadUrLsRequest } from "@/generated/graphql";

export type Document = {
    id: string;
    filename: string;
    // url can be null if it's not available for the current user
    url?: string | null | undefined;
    createdAt: string;
    isPublic: boolean;
    metadata?: { [key: string]: string };
};

const UseDocuments_ContractQuery = graphql(`
    query UseDocuments_ContractQuery($id: ID!) {
        getContract(id: $id) {
            id
            documents {
                id
                filename
                url
                createdAt
                isPublic
            }
        }
    }
`);

const UseDocuments_UpdateDocumentMutation = graphql(`
    mutation UseDocuments_UpdateDocumentMutation($input: UpdateDocumentRequest!) {
        updateDocument(input: $input) {
            id
            filename
            url
            createdAt
            createdBy {
                id
                fullName
            }
            isPublic
        }
    }
`);

const UseDocuments_DeleteDocumentMutation = graphql(`
    mutation UseDocuments_DeleteDocumentMutation($input: DeleteDocumentRequest!) {
        deleteDocument(input: $input) {
            id
        }
    }
`);

const UseDocuments_CreateDocumentUploadURLsMutation = graphql(`
    mutation UseDocuments_CreateDocumentUploadURLsMutation($input: CreateDocumentUploadURLsRequest!) {
        createDocumentUploadURLs(input: $input) {
            urls {
                key
                url
            }
        }
    }
`);

export function useDocuments(id: string) {
    const { data, loading, refetch } = useQuery(UseDocuments_ContractQuery, {
        variables: { id },
        skip: id === "",
    });
    const { formatMessage } = useIntl();
    const { handleError } = useErrorHandler();
    const { postSnackbar } = useSnackbar();
    const [updateDocument] = useMutation(UseDocuments_UpdateDocumentMutation, {
        refetchQueries: [UseDocuments_ContractQuery],
    });
    const [deleteDocument] = useMutation(UseDocuments_DeleteDocumentMutation, {
        refetchQueries: [UseDocuments_ContractQuery],
    });
    const [createDocumentUploadURLs] = useMutation(UseDocuments_CreateDocumentUploadURLsMutation, {
        refetchQueries: [UseDocuments_ContractQuery],
    });

    return {
        data: data?.getContract.documents ?? [],
        loading,
        refetch,
        upload: async (files: File[]) => {
            const filesWithKey = files.map((file) => ({
                key: v4(),
                file,
            }));

            const input: CreateDocumentUploadUrLsRequest = {
                contractId: id,
                files: filesWithKey.map(({ file, key }) => ({
                    key,
                    filename: file.name,
                    contentType: file.type,
                })),
            };

            await createDocumentUploadURLs({
                variables: {
                    input,
                },
                onCompleted: ({ createDocumentUploadURLs: { urls } }) => {
                    urls.forEach(async (urlRes) => {
                        const { file } = filesWithKey.find(({ key }) => key === urlRes.key) ?? {};
                        if (!file) {
                            captureMessage("Failed to find file for upload", { tags: { contractId: id } });
                            return;
                        }
                        const res = await fetch(urlRes.url, {
                            method: "PUT",
                            body: file,
                            headers: {
                                "Content-Type": file.type,
                            },
                        });

                        if (res.status !== 200) {
                            captureMessage("Failed to upload file", {
                                tags: { contractId: id, url: urlRes.url, fileName: file.name, type: file.type },
                            });
                            handleError(formatMessage({ defaultMessage: "Failed to upload file" }));
                        }
                    });
                    postSnackbar({
                        message: formatMessage({ defaultMessage: "File uploaded successfully" }),
                        severity: "success",
                    });
                },
                onError: (error) => {
                    captureMessage("Failed to get signed URLs for file upload", {
                        tags: { contractId: id, numFiles: files.length },
                    });
                    handleError(error);
                },
            });

            track("Contract Details: document uploaded", {
                contractId: id,
                numFiles: files.length,
                fileTypes: files.map((file) => file.type).join(", "),
            });
        },
        toggleVisibility: async (fileId: string, isPublic: boolean) => {
            track("Contracts: document visibility updated", { contractId: id, isPublic });
            await updateDocument({
                variables: {
                    input: {
                        id: fileId,
                        contractId: id,
                        isPublic,
                    },
                },
                onCompleted: () => {
                    postSnackbar({
                        message: formatMessage({ defaultMessage: "File visibility updated" }),
                        severity: "success",
                    });
                },
                onError: handleError,
            });
        },
        delete: async (fileId: string) => {
            track("Contracts: Delete contract document", { fileId, contractId: id });
            await deleteDocument({
                variables: {
                    input: {
                        id: fileId,
                        contractId: id,
                    },
                },
                onCompleted: () => {
                    postSnackbar({
                        message: formatMessage({ defaultMessage: "File deleted successfully" }),
                        severity: "success",
                    });
                },
                onError: handleError,
            });
        },
    };
}
